import { Select } from 'antd'
import React, { useState } from 'react'
import { ability } from '../../../Authentication/permissions/ability'
import { SupportedCurrencyDetail } from '../../../store/currency/currency.reducer'

interface CurrencyCellProps {
    currencies: SupportedCurrencyDetail[]
    editing: boolean
    onSelect: (value: string) => void
    startEdit: () => void
    stopEdit: () => void
    value: string
    country: string
}

export const CurrencyCode: React.FC<CurrencyCellProps> = ({
    currencies,
    editing,
    onSelect,
    startEdit,
    value,
}) => {
    const [hovering, setHovering] = useState(false)
    const [selectValue, setSelectValue] = useState(value)

    const showSelect = () => {
        setHovering(true)
        setSelectValue(value)
    }

    const hideSelect = () => {
        setHovering(false)
    }

    const wrapperProps = !editing
        ? {
              onMouseEnter: showSelect,
              onMouseLeave: hideSelect,
          }
        : {}

    const showCurrencySelect =
        ability.can('manage', 'Currency') && (hovering || editing)

    return (
        <div {...wrapperProps}>
            {showCurrencySelect ? (
                <Select
                    value={selectValue}
                    options={currencies.map((c) => ({
                        label: c.displayName,
                        value: c.code,
                    }))}
                    onChange={(value) => {
                        setSelectValue(value)
                        onSelect(value)
                    }}
                    onClick={startEdit}
                    onBlur={() => {
                        setHovering(false)
                    }}
                    style={{ width: '100%', height: '28px' }}
                />
            ) : (
                value
            )}
        </div>
    )
}
