import { Col, Descriptions, Divider, Progress, Result, Row } from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import apiService from '../../../services/api.service'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductEnum } from '../../../store/typings/product.enum'
import { useAuditData } from '../../hooks/useAuditData'
import { Detail } from './Detail'

const ignoreByName = [
    'Advanced Video Library',
    'Cloud Labs',
    'Digital Literacy Onboarding',
    'Flow ProServ: Integrations',
    'Flow ProServ: Onboarding',
    'Flow ProServ: Server Access & Support',
    'Flow ProServ: Server Installation',
    'Individual',
    'Individual - Code School Legacy',
    'Individual Free Trial',
    'Individual Free Weekend',
    'Pluralsight Individual (App Store)',
    'Pluralsight Individual (India)',
    'Pluralsight Workshops',
    'Projects and Interactive Courses',
    'ProServ Custom',
    'Tableau Connector',
]

export const AuditMissingFeatures: React.FC = () => {
    const { products } = useAuditData()

    const audit = useMemo(() => {
        const { Business, Individual } = ProductEnum.CustomerType
        const { Skills, Proserv } = ProductEnum.Type
        const { Limited } = ProductEnum.LibraryScope
        const activeProductsWithFeatureScope = products.filter(
            ({ customerType, libraryScope, name, status, type }) =>
                status !== CommonEnum.Status.Inactive &&
                !ignoreByName.includes(name) &&
                [Skills, Proserv].includes(type) &&
                (customerType === Business ||
                    (customerType === Individual && libraryScope !== Limited))
        )
        const productsWithMissingFeatures =
            activeProductsWithFeatureScope.filter(
                (product) => product.features.length === 0
            )

        return {
            percent: Math.round(
                (productsWithMissingFeatures.length /
                    activeProductsWithFeatureScope.length) *
                    100
            ),
            activeProductsWithFeatureScope,
            productsWithMissingFeatures,
        }
    }, [products])

    const detail =
        audit.percent > 0 ? (
            <ol>
                {audit.productsWithMissingFeatures.map(({ id, name }) => (
                    <li key={id}>
                        <Link
                            to={apiService.getRelativeUrl(
                                apiService.getProductFeaturesUrl(id)
                            )}
                        >
                            {name}
                        </Link>
                    </li>
                ))}
            </ol>
        ) : (
            <Result status="success" title="No affected products" />
        )

    return (
        <div>
            <Divider orientation="center">Missing Features</Divider>

            <Row gutter={[12, 24]} className={'row-gap-24'}>
                <Col flex={1}>
                    <Descriptions title="Totals" bordered={true}>
                        <Descriptions.Item label="Active products scoped for Features">
                            {audit.activeProductsWithFeatureScope.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total without Features">
                            {audit.productsWithMissingFeatures.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="% Affected">
                            <Progress
                                format={(percent) => `${percent}%`}
                                percent={audit.percent}
                                status="normal"
                                strokeColor={{
                                    '0%': '#d21c09',
                                    '100%': '#ffd6d6',
                                }}
                                trailColor="transparent"
                                type="circle"
                                width={50}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Detail detail={detail} />
        </div>
    )
}
