import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { ContentLibrary } from '../typings/content-libraries.interface'
import { setFetchInProgress, setPayload, setRequestError } from '../utils'

export type ContentLibrariesById = Common.ItemsById<ContentLibrary>

export interface ContentLibrariesDefaultState {
    byId: ContentLibrariesById
}

export const defaultState: ContentLibrariesDefaultState = {
    byId: {},
}

export const byIdReducer = createReducer(defaultState.byId)
    .handleAction(actions.contentLibraries.get.request, (state, action) =>
        setFetchInProgress(state, action.payload)
    )
    .handleAction(actions.contentLibraries.get.success, (state, action) =>
        setPayload(state, action.payload)
    )
    .handleAction(actions.contentLibraries.get.failure, (state, action) =>
        setRequestError(state, action.payload.id, action.payload.error)
    )
    .handleAction(actions.contentLibraries.clearErrorFor, (state, action) =>
        setRequestError(state, action.payload, null)
    )

export const contentLibrariesReducer = combineReducers({
    byId: byIdReducer,
})

// Redux Types
export type ContentLibrariesAction = ActionType<typeof actions.contentLibraries>
export type ContentLibrariesState = StateType<typeof contentLibrariesReducer>
