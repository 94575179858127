import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { map, omit } from 'lodash'
import React from 'react'
import { ProductOption } from '../../../store/typings/product-option.interface'
import styles from './ReviewImport.module.scss'

type ImportError = Omit<ProductOption.ImportIssue, 'children'> & {
    name: string
    rowNumber: number
}

interface Props {
    data: ProductOption.ImportResponse[]
}

const sortAlpha = (a: any, b: any) =>
    a?.toString().localeCompare(b?.toString()) ?? 0

export const ErrorsTable: React.FC<Props> = ({ data }) => {
    const allIssues = data.flatMap((d) =>
        d.issues
            ? d.issues?.map((i) =>
                  Object.assign(
                      {
                          rowNumber: d.rowNumber,
                          name: d.productOption.entity.name,
                      },
                      omit(i, 'children')
                  )
              )
            : []
    )

    const columns: ColumnProps<ImportError>[] = [
        {
            title: 'CSV ROW',
            dataIndex: 'rowNumber',
            fixed: 'left',
            sorter: sortAlpha,
            width: 120,
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            width: 300,
        },
        {
            title: 'FIELD',
            dataIndex: 'property',
            width: 120,
        },
        {
            title: 'ERRORS',
            key: 'errors',
            render: (_, record) => (
                <div>
                    {map(record.constraints, (c) => (
                        <div key={c}>{c}</div>
                    ))}
                </div>
            ),
        },
    ]

    return (
        <Table
            className={styles.table}
            columns={columns}
            dataSource={allIssues}
            rowKey={(record) => `${record.rowNumber}_${record.property}`}
            pagination={{ defaultPageSize: 10 }}
        />
    )
}
