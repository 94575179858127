import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { selectCountries } from '../../store/product-options/product-options.selectors'
import { actions } from '../../store/root-action'
import styles from './Localization.module.scss'
import { LocalizationFilterInputs } from './LocalizationFilterInputs'
import { PreferredCurrency } from './preferred-currency/PreferredCurrency'
import { SupportedCurrencyList } from './supported-currency/SupportedCurrencyList'

const divider = <div className={styles.divider}></div>

export const Localization: React.FC = () => {
    const dispatch = useDispatch()

    const countries = useSelector(selectCountries)

    const checkForCountryList = useCallback(() => {
        if (!countries.length) {
            dispatch(actions.productOptions.getFields.request())
        }
    }, [countries, dispatch])

    useEffect(() => {
        dispatch(actions.currency.getPreferred.request())
        dispatch(actions.currency.getSupported.request())
        checkForCountryList()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Helmet>
                <title>Localization - Product Catalog</title>
            </Helmet>
            <div className={styles.container}>
                {divider}
                <SupportedCurrencyList />
                {divider}
                <LocalizationFilterInputs />
                {divider}
                <PreferredCurrency />
            </div>
        </>
    )
}
