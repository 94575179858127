export enum CxnButtonLabels {
    Exclude = 'Exclude',
    Unconfigure = 'Unconfigure',
    Confirm = 'Confirm',
}

export const cxnOptions = {
    /**
     * True: Even if a product option already has a from product option mapped
     * to it another product option can also be assigned to upgrade to it.
     *
     * False: Once a product options has been mapped to it is not available to
     *  be mapped from another from Product Option.
     *
     */
    allowDuplicateToMappings: false,

    // should it stay?
    filterBestGuessInSelect: true,

    /**
     * To test out if tables can save space with redundant text
     */
    useUpgradeShorthand: false,
}
