import { Card, Col, Row } from 'antd'
import { Gutter } from 'antd/lib/grid/row'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { compose } from 'redux'
import { ability, rules } from '../../Authentication/permissions/ability'
import { AdminTools } from '../../store/typings/admin-tools.interface'
import { ConnectedProductContainer } from '../connected-products/products/CxnProductContainer'
import { ConnectedProductLoader } from '../connected-products/products/CxnProductLoader'
import { hasData } from '../hoc/hasData'
import { AbilityRoute } from '../router/AbilityRoute'
import { AdhocDvsBackfill } from './adhoc/dvs-backfill/AdhocDvsBackfill'
import { Audits } from './audits/Audits'
import { ExportProductOptions } from './export-product-options/ExportProductOptions'
import { ManageProductOptions } from './manage-product-options/ManageProductOptions'
import styles from './Tools.module.scss'

const tools: AdminTools.Tool[] = [
    {
        component: ManageProductOptions,
        description: 'Batch process for managing product options',
        disabled: false,
        rule: rules.manageProductOption,
        slug: 'manage-product-options',
        title: 'Manage Product Options',
    },
    {
        component: Audits,
        description: `Helpful audits, reports, and overview of the catalog.`,
        disabled: false,
        slug: 'audits',
        title: 'Audits',
    },
    {
        component: AdhocDvsBackfill,
        description: `Run database sync adhoc after DVS schema evolution.`,
        disabled: false,
        rule: rules.manageAll,
        slug: 'db-sync',
        title: 'DVS Backfill Adhoc',
    },
    {
        component: ConnectedProductContainer,
        description: `Status 0 out of 8 product options configured`,
        disabled: false,
        rule: rules.manageConnectedProducts,
        slug: 'connected-products',
        title: 'Connected Product',
    },
    {
        component: ExportProductOptions,
        description: `Export all product options`,
        disabled: false,
        slug: 'export-product-options',
        title: 'Export Product Options',
    },
]

export const ToolsComponent: React.FC = () => {
    const { path } = useRouteMatch()

    const leftGutter: Gutter = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }

    const cards = (
        <div className={styles.container}>
            <Row gutter={[leftGutter, 18]} className={'row-gap-18'}>
                {tools.map(({ description, disabled, slug, title, rule }) =>
                    !rule || ability.can(rule.action, rule.subject) ? (
                        <Col key={slug} flex="0 0 33.333%">
                            <Card
                                bordered={false}
                                title={title}
                                extra={
                                    <Link
                                        to={`${path}/${slug}`}
                                        className={
                                            disabled ? styles.disabled : ''
                                        }
                                    >
                                        {disabled ? 'Disabled' : 'View'}
                                    </Link>
                                }
                            >
                                {description}
                            </Card>
                        </Col>
                    ) : null
                )}
            </Row>
        </div>
    )

    const makeHelmetHeader = (nm?: string) => {
        return (
            <Helmet>
                <title>
                    {nm ? `${nm} - ` : ''}Product Catalog | Admin tools
                </title>
            </Helmet>
        )
    }

    return (
        <Switch>
            {tools.map((tool) => {
                const { component, disabled, slug, title } = tool
                const Tool = component as any

                const redirector = <Redirect key={slug} to={path} />
                const toolPage = (
                    <>
                        {makeHelmetHeader(title)}
                        <Tool {...tool} />
                    </>
                )

                const abilityProps = {
                    exact: true,
                    path: `${path}/${slug}`,
                    rule: tool.rule,
                }

                return (
                    <AbilityRoute key={slug} {...abilityProps}>
                        {disabled ? redirector : toolPage}
                    </AbilityRoute>
                )
            })}

            <AbilityRoute
                rule={rules.manageConnectedProducts}
                path="/tools/connected-products/:productId"
            >
                <ConnectedProductLoader />
            </AbilityRoute>

            <Route exact path={path}>
                <>
                    {makeHelmetHeader()}
                    {cards}
                </>
            </Route>
            <Route path="*">
                <Redirect to={path} />
            </Route>
        </Switch>
    )
}

export const Tools = compose(hasData, connect())(ToolsComponent)
