import { LocalizationFilters } from '../../store/filters/filters.reducer'
import { LocalizationEnum } from '../../store/typings/localization.enum'
import { PreferredCurrency } from '../../store/typings/preferred-currency.interface'

export const filterPreferredCurrency = (
    data: PreferredCurrency[],
    filters: LocalizationFilters
) => filterByType(data, filters)

export const filterByType = (data: any[], filters: any) =>
    data.filter((item) =>
        Object.keys(filters).every((key) => {
            if (!filters[key]?.length) {
                return true // passing an empty filter means that filter is ignored.
            }

            if (key === 'status') {
                const status = item['active']
                    ? LocalizationEnum.Status.Active
                    : LocalizationEnum.Status.Inactive

                return filters[key].includes(status)
            }

            return filters[key].includes(item[key])
        })
    )
