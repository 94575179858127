import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools as devToolsStaging } from 'redux-devtools-extension'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import config from '../config'
import rootReducer, { defaultState } from './root-reducer'
import { rootSaga } from './root-saga'

const middlewares = []

if (config.isDevelopment) {
    // eslint-disable-next-line
    const { createLogger } = require('redux-logger')
    const logger = createLogger({
        collapsed: true,
    })
    middlewares.push(logger)
}

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

// Make sure to install the extension!
// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const composeEnhancers = config.isStaging
    ? devToolsStaging({})
    : composeWithDevTools({})

const store = createStore(
    rootReducer,
    defaultState,
    composeEnhancers(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)

export default store
