import React from 'react'
import styles from './Value.module.scss'

type Props = { emptyText?: string; className?: string }

export class Value extends React.Component<Props> {
    render() {
        const {
            children: value,
            emptyText = <span className={styles.undefined}>N/A</span>,
            className,
            ...props
        } = this.props

        let text = value || emptyText
        if (typeof value === 'number') {
            text = value
        }
        if (typeof value === 'boolean') {
            text = value ? 'Yes' : 'No'
        }

        return (
            <span className={className} {...props}>
                {text}
            </span>
        )
    }
}
