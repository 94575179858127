import Form from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormDrawer } from '../../../components/form/FormDrawer'
import { actions } from '../../../store/root-action'
import { CommonEnum } from '../../../store/typings/common.enum'
import styles from './CxnProductForm.module.scss'
import { ConnectedProducts } from './CxnProductFormFields'

export interface ConnectedProductFormProps {
    onClose: () => void
    onSubmit: () => void
    visible: boolean
    editForm: boolean
    baseproductsList: any
}

type Props = ConnectedProductFormProps & {
    name: 'ConnectedProducts'
    submitText: string
    title: string
}

type connectedProductProps = {
    type: CommonEnum.Link
    fromProduct: string
    toProduct: string
    properties: Record<string, unknown>
}

export const ConnectedProductForm: React.FC<Props> = ({
    name,
    onClose,
    onSubmit,
    submitText,
    title,
    visible,
    editForm,
    baseproductsList,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [connectedData, setConnectedData] = React.useState<
        connectedProductProps[]
    >([])
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [completeStatus, setCompleteStatus] = useState(false)

    useEffect(() => {
        if (connectedData.length) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }
    }, [connectedData])

    useEffect(() => {
        if (completeStatus) {
            onSubmit()
            onClose()
            setCompleteStatus(false)
        }
    }, [completeStatus])

    const handleFormSubmit = () => {
        if (connectedData.length) {
            let count = 0
            connectedData.forEach((element: connectedProductProps, i) => {
                count = i + 1
                dispatch(actions.connectedProduct.create.request(element))
            })
            if (count === connectedData.length) {
                setCompleteStatus(true)
            }
        }
    }

    const onDataComplete = (formdata: any) => {
        const connectedNewProduct: connectedProductProps[] = []
        if (formdata && formdata.fromProduct) {
            formdata.toUpgradeProduct.forEach((element: string) => {
                if (element) {
                    const data: connectedProductProps = {
                        type: CommonEnum.Link.Upgrade,
                        fromProduct: '',
                        toProduct: '',
                        properties: {},
                    }
                    data.type = CommonEnum.Link.Upgrade
                    data.fromProduct = formdata.fromProduct
                    data.toProduct = element
                    data.properties = {}
                    connectedNewProduct.push(data)
                }
            })

            formdata.toUpsellProduct.forEach((element: string) => {
                if (element) {
                    const data: connectedProductProps = {
                        type: CommonEnum.Link.Upsell,
                        fromProduct: '',
                        toProduct: '',
                        properties: {},
                    }
                    data.type = CommonEnum.Link.Upsell
                    data.fromProduct = formdata.fromProduct
                    data.toProduct = element
                    data.properties = {}
                    connectedNewProduct.push(data)
                }
            })
        }
        setConnectedData(connectedNewProduct)
    }

    return (
        <FormDrawer
            titleText={title}
            visible={visible}
            onClose={onClose}
            formName={name}
            submitText={submitText}
            width={800}
            disableSave={disableSubmit}
        >
            <Form
                className={styles.productForm}
                form={form}
                hideRequiredMark
                layout="vertical"
                name={name}
                onFinish={() => handleFormSubmit()}
                preserve={false}
            >
                <ConnectedProducts
                    baseProducts={baseproductsList}
                    onFormComplete={(data: any) => onDataComplete(data)}
                />
            </Form>
        </FormDrawer>
    )
}
