import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { mergePayload, setRequestError, setSaveInProgress } from '../utils'

export type AdhocById = Common.ItemsById<any>

export interface AdhocDefaultState {
    byId: AdhocById
    error: Common.ApiErrors
}

export const defaultState: AdhocDefaultState = {
    byId: {
        dvsBackfill: {
            error: null,
            isFetching: false,
            isSaving: false,
            payload: {},
            receivedAt: null,
        },
    },
    error: { onFetch: null, onSave: null },
}

// Reducers
const adhocId = 'dvsBackfill'
export const byIdReducer = createReducer(defaultState.byId)
    .handleAction([actions.adhoc.runDvsBackfill.request], (state, action) =>
        setSaveInProgress(state, adhocId)
    )
    .handleAction(actions.adhoc.runDvsBackfill.success, (state, action) =>
        mergePayload(state, adhocId, action.payload)
    )
    .handleAction([actions.adhoc.runDvsBackfill.failure], (state, action) => {
        return setRequestError(state, adhocId, action.payload)
    })

export const errorReducer = createReducer(defaultState.error)
    .handleAction([actions.adhoc.runDvsBackfill.request], () => ({
        onFetch: null,
        onSave: null,
    }))
    .handleAction(actions.adhoc.runDvsBackfill.failure, (state, action) => ({
        ...state,
        onSave: action.payload,
    }))

export const adhocReducer = combineReducers({
    byId: byIdReducer,
    error: errorReducer,
})

// Redux Types
export type AdhocAction = ActionType<typeof actions.adhoc>
export type AdhocState = StateType<typeof adhocReducer>
