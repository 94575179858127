import { Tag } from 'antd'
import React from 'react'
import { Common } from '../../../store/typings/common.interface'

interface Props {
    outcome: Common.Outcome
}

export const outcomeColors = {
    create: '#009E52',
    update: '#E2B500',
    none: '#AAAAAA',
    error: '#D21C09',
}

export const ImportOutcome: React.FC<Props> = ({ outcome }) => (
    <Tag color={outcomeColors[outcome] || '#000'}>{outcome}</Tag>
)
