import React from 'react'
import Gravatar from 'react-gravatar'
import { useAuth } from '../../Authentication/authentication.hooks'
import styles from './User.module.scss'

export const User: React.FC = () => {
    const { user } = useAuth()
    const { email, name: fullName } = user
    if (!email) {
        return null
    }

    return (
        <div className={styles.user}>
            <Gravatar
                email={email}
                size={48}
                default={'mm'}
                className={styles.avatar}
            />
            <span className={styles.name}>{fullName}</span>
        </div>
    )
}
