export namespace CommonEnum {
    export enum State {
        Active = 'active',
        DraftExisting = 'draft:existing',
        DraftNew = 'draft:new',
        Inactive = 'inactive',
    }

    export enum Status {
        Active = 'Active',
        Inactive = 'Inactive',
    }

    export enum Target {
        Product = 'product',
        ProductOption = 'productOption',
        PriceOption = 'priceOption',
    }

    export enum Link {
        Downgrade = 'downgrade',
        Exclude = 'exclude',
        Upgrade = 'upgrade',
        Upsell = 'upsell',
    }
}
