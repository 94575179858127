import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { selectProductOptionFieldOptions } from '../../store/product-options/product-options.selectors'
import { ProductOption } from '../../store/typings/product-option.interface'
import styles from './CountryFilter.module.scss'

export const CountryFilter: React.FC<SelectProps<any>> = (props) => {
    const filterOptions = useSelector((state: RootState) =>
        selectProductOptionFieldOptions(state)
    )

    const options = [
        ...filterOptions.countries.map(
            (country: ProductOption.CountryOption) => {
                return { label: country.displayName, value: country.code }
            }
        ),
    ]

    return (
        <Select
            allowClear
            className={styles.country}
            filterOption={(inputValue, option) =>
                (typeof option?.label === 'string' &&
                    option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())) ??
                true
            }
            mode="multiple"
            options={options}
            placeholder="All countries"
            showArrow
            showSearch
            size="large"
            {...props}
        />
    )
}
