import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { SecondaryButton } from '../../components/buttons/Buttons'
import { strings } from '../../constants'
import { defaultState } from '../../store/filters/filters.reducer'
import { selectLocalizationFilters } from '../../store/filters/filters.selectors'
import { actions } from '../../store/root-action'
import { LocalizationEnum } from '../../store/typings/localization.enum'
import { CountryFilter } from '../filters/CountryFilter'
import { CurrencyFilter } from '../filters/CurrencyFilter'
import { StatusFilter } from '../filters/StatusFilter'
import styles from './LocalizationFilterInputs.module.scss'

export const LocalizationFilterInputs: React.FC = () => {
    const dispatch = useDispatch()
    const filters = useSelector((state: RootState) =>
        selectLocalizationFilters(state)
    )
    const setDefaultFilters = () =>
        dispatch(
            actions.filters.filterLocalization({
                ...defaultState.localization,
            })
        )

    // status
    const setStatusFilter = (status: LocalizationEnum.Status[]) =>
        dispatch(actions.filters.filterLocalization({ ...filters, status }))
    const onStatusFilterChange = useCallback(setStatusFilter, [
        dispatch,
        filters,
    ])

    // country
    const setCountryFilter = (country: string[]) =>
        dispatch(
            actions.filters.filterLocalization({
                ...filters,
                country,
            })
        )
    const onCountryFilterChange = useCallback(setCountryFilter, [
        dispatch,
        filters,
    ])

    // currency
    const setCurrencyFilter = (currency: string[]) =>
        dispatch(
            actions.filters.filterLocalization({
                ...filters,
                currency,
            })
        )
    const onCurrencyFilterChange = useCallback(setCurrencyFilter, [
        dispatch,
        filters,
    ])

    const onFiltersReset = useCallback(setDefaultFilters, [dispatch])
    return (
        <div className={styles.container}>
            <Row
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
                className={'row-gap-18'}
            >
                <Col flex="1">
                    <label>{strings.filterStatusLabel}</label>
                    <StatusFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onStatusFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as LocalizationEnum.Status[]
                            )
                        }
                        options={[
                            ...Object.values(LocalizationEnum.Status).map(
                                (status: string) => {
                                    return { label: status, value: status }
                                }
                            ),
                        ]}
                        value={filters.status}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.filterCountryLabel}</label>
                    <CountryFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onCountryFilterChange(
                                options.map((option: any) => option.value)
                            )
                        }
                        value={filters.country}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.filterCurrencyLabel}</label>
                    <CurrencyFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onCurrencyFilterChange(
                                options.map((option: any) => option.value)
                            )
                        }
                        value={filters.currency}
                    />
                </Col>
                <Col className={styles.action} flex="0">
                    <SecondaryButton
                        icon={<CloseCircleOutlined />}
                        onClick={onFiltersReset}
                    >
                        {strings.resetFiltersBtn}
                    </SecondaryButton>
                </Col>
            </Row>
        </div>
    )
}
