import {
    ExclamationCircleOutlined,
    LockFilled,
    UnlockTwoTone,
} from '@ant-design/icons'
import { Input as AntInput, Modal } from 'antd'
import React, { useState } from 'react'
import inputStyles from '../../components/form/input/Input.module.scss'
import { FormItem, RequiredFormItem } from './ProductOptionFields'

const { confirm } = Modal

type LockButtonProps = {
    clickLock: () => void
    clickUnlock: () => void
    isLocked: boolean
}

const LockButton: React.FC<LockButtonProps> = (props) =>
    !props.isLocked ? (
        <UnlockTwoTone
            data-testid="LockButton"
            onClick={() => props.clickLock()}
        />
    ) : (
        <LockFilled
            data-testid="LockButton"
            onClick={() => props.clickUnlock()}
        />
    )

// ---------------------------------------------------------------- //
type Props = {
    autoFillInvoice: () => void
    formGet: (v: string) => any
}

export const NameInputs: React.FC<Props> = ({ formGet, autoFillInvoice }) => {
    const [isLocked, setIsLocked] = useState(
        formGet('name') === formGet('invoiceName')
    )

    /**
     * Sets the invoice name to match the name in the form
     */
    const setInvoiceName = () => {
        if (isLocked) {
            autoFillInvoice()
        }
    }

    /**
     * simple confirmation modal if name and invoice do not match
     */
    const warningModal = () => {
        confirm({
            title: `Reset?`,
            icon: <ExclamationCircleOutlined />,
            content: (
                <p>
                    Are you sure you want to reset? This will set name on
                    invoice back to <b>{formGet('name')}</b>.
                </p>
            ),
            okText: 'Yes, reset',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                setIsLocked(true)
                autoFillInvoice()
            },
        })
    }

    const clickLock = () => {
        if (formGet('name') === formGet('invoiceName')) {
            // if the names are matching there is no need to pop up the modal to confirm
            setIsLocked(true)
            autoFillInvoice() // just in case, but not necessary
        } else {
            // show a warning modal to confirm that user will reset the invoice name to match name
            warningModal()
        }
    }

    return (
        <>
            <RequiredFormItem name="name" label="Name">
                <AntInput
                    size="large"
                    allowClear
                    className={inputStyles.input}
                    onChange={setInvoiceName}
                />
            </RequiredFormItem>

            <FormItem label="Name on invoice" name="invoiceName">
                <AntInput
                    size="large"
                    className={inputStyles.input}
                    disabled={isLocked}
                    addonAfter={
                        <LockButton
                            isLocked={isLocked}
                            clickLock={clickLock}
                            clickUnlock={() => setIsLocked(false)}
                        />
                    }
                />
            </FormItem>
        </>
    )
}
