import classNames from 'classnames'
import React from 'react'
import { Value } from '../../containers/product-details/components/Value'
import { formatTimestamp } from '../../store/utils'
import styles from './Timestamp.module.scss'

type Props = {
    label: string
    timestamp: string | null
    className?: string
}

export const Timestamp: React.FC<Props> = ({
    label,
    timestamp,
    className,
    ...props
}) => {
    const formattedTimestamp = timestamp ? formatTimestamp(timestamp) : null

    return (
        <span className={classNames(styles.date, className)} {...props}>
            {label}: <Value>{formattedTimestamp}</Value>
        </span>
    )
}
