import { put, retry, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { actions } from '../root-action'
import { ContentLibrary } from '../typings/content-libraries.interface'

export function* getContentLibrariesSaga(
    action: ReturnType<typeof actions.contentLibraries.get.request>
) {
    try {
        const contentLibraries: ContentLibrary = yield retry(
            2,
            3,
            api.getContentLibrary,
            action.payload
        )
        yield put(actions.contentLibraries.get.success(contentLibraries))
    } catch (error) {
        yield put(
            actions.contentLibraries.get.failure({ id: action.payload, error })
        )
    }
}

export function* contentLibrariesWatcher() {
    yield takeEvery(
        getType(actions.contentLibraries.get.request),
        getContentLibrariesSaga
    )
}
