export namespace ProductOptionEnum {
    export enum BillingPeriod {
        Annual = 'Annual',
        Month = 'Month',
        Quarter = 'Quarter',
        Semiannual = 'Semi-Annual',
    }

    export enum ChargeModel {
        FlatFee = 'FlatFee',
        PerUnit = 'PerUnit',
    }

    export enum ChargeType {
        OneTime = 'OneTime',
        Recurring = 'Recurring',
    }

    export enum CustomerSource {
        Amazon = 'Amazon',
        Apple = 'Apple',
        Aws = 'AWS Marketplace',
        GcpMarketplace = 'GCP Marketplace',
        GooglePlay = 'Google Play',
        Pluralsight = 'Pluralsight',
        Reseller = 'Reseller',
    }

    export enum Status {
        Active = 'Active',
        Inactive = 'Inactive',
    }

    export enum Uom {
        Pass = 'Pass',
        Subscription = 'Subscription',
        User = 'User',
    }

    export enum State {
        Active = 'active',
        DraftExisting = 'draft:existing',
        DraftNew = 'draft:new',
        Inactive = 'inactive',
    }

    export enum Tags {
        Checkout = 'checkout',
        Expand = 'expand',
        Free = 'free',
        FreeTrial = 'free trial',
        NotTaxable = 'not taxable',
        PSOne = 'ps one',
        Redeemable = 'redeemable',
        Renew = 'renew',
        Royalty = 'royalty',
        TaxCode = 'tax code',
    }
}
