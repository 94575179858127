/**
 * This is the PARENT CONTAINER for the sidebar and the main body area
 * Responsibilities are:
 * to keep state between menu and body
 * Render the child components
 * Create overall section layout
 */

// --> PoContainer
// ! ----> CxnPoContainer
//    ------> CxnPoContent

import { Layout } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'
import {
    CxnCompletions,
    PConnection,
    ProductOptionConnection,
} from '../cxn-po.interface'
import { CxnPoContentContainer } from './CxnPoContent'
import styles from './CxnPoContent.module.scss'
import { ConnectedPoSidebar } from './CxnPoSidebar'

const { Sider, Content } = Layout

type Props = {
    allProductCxn: PConnection[]
    currentPos: ProductOptionConnection[]
    currentProduct: PConnection
    completionInfo: CxnCompletions
    setCurrentProduct: Dispatch<SetStateAction<PConnection | undefined>>
    setCurrentPos: Dispatch<
        SetStateAction<ProductOptionConnection[] | undefined>
    >
}

export const ConnectedPoPageLayout = (props: Props) => {
    const { completionInfo, currentPos, allProductCxn, currentProduct } = props
    return (
        <Layout>
            <Sider width={500}>
                <ConnectedPoSidebar
                    currentProductId={currentProduct.toProduct.id}
                    setCurrentProduct={props.setCurrentProduct}
                    pCxns={allProductCxn}
                    poCxns={currentPos}
                    completionInfo={completionInfo}
                />
            </Sider>
            <Content className={styles.contentContainer}>
                <CxnPoContentContainer
                    currentPos={currentPos}
                    currentProduct={currentProduct}
                    setCurrentPos={props.setCurrentPos}
                />
            </Content>
        </Layout>
    )
}
