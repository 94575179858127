import { Typography } from 'antd'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectProductOptionsApiMeta,
    selectReviewImportData,
} from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { AdminTools } from '../../../store/typings/admin-tools.interface'
import { BackToTools } from '../BackToTools'
import { CsvUpload } from './CsvUpload'
import styles from './ManageProductOptions.module.scss'
import { ReviewImport } from './ReviewImport'
import { ReviewNavbar } from './ReviewNavbar'
import { SubmitImport } from './SubmitImport'

const { Title } = Typography

type Props = AdminTools.Tool

export const ManageProductOptions: React.FC<Props> = ({ title }) => {
    const { data, step } = useSelector(selectReviewImportData)
    const { isSaving } = useSelector(selectProductOptionsApiMeta)
    const dispatch = useDispatch()

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(
        () => () => {
            dispatch(actions.productOptions.setImportReviewStep(1))
        },
        []
    )
    return (
        <>
            <Helmet>
                <title>Manage Product Options - Product Catalog</title>
            </Helmet>
            <div className={styles.container}>
                <BackToTools />
                <Title>{title}</Title>
                <ReviewNavbar currentStep={step} />

                <div className={styles.steps}>
                    {step === 1 && <CsvUpload isUploading={isSaving} />}
                    {step === 2 && <ReviewImport data={data} />}
                    {step === 3 && <SubmitImport data={data} />}
                </div>
            </div>
        </>
    )
}
