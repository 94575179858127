import { call, delay, put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { FormName } from '../forms/forms.reducer'
import { actions } from '../root-action'
import { Product } from '../typings/product.interface'

export function* getProductsSaga() {
    try {
        const products: Product.AllFields[] = yield call(api.getProducts)
        yield put(actions.products.get.success(products))
    } catch (error) {
        yield put(actions.products.get.failure(error))
    }
}

export function* createProductSaga(
    action: ReturnType<typeof actions.products.create.request>
) {
    try {
        const data: Product.AllFields = yield call(
            api.createProductWithDraft,
            action.payload
        )
        yield put(
            actions.products.create.success({
                data,
                message: 'Product was successfully created!',
            })
        )
    } catch (error) {
        yield put(actions.products.create.failure(error))
    }
}

export function* updateProductSaga(
    action: ReturnType<typeof actions.products.update.request>
) {
    try {
        const data: Product.AllFields = yield call(
            api.updateProductDraft,
            action.payload
        )
        yield put(
            actions.products.update.success({
                data,
                message: 'Product was successfully updated!',
            })
        )
        yield put(actions.forms.close(FormName.ProductEdit))
    } catch (error) {
        yield put(
            actions.products.update.failure({
                id: action.payload.id,
                error,
            })
        )
    }
}

export function* assignLibrarySaga(
    action: ReturnType<typeof actions.products.assignLibrary.request>
) {
    try {
        const data: Product.AllFields = yield call(
            api.assignLibrary,
            action.payload
        )
        yield put(
            actions.products.assignLibrary.success({
                data,
                message: 'Library was successfully assigned!',
            })
        )
        yield put(actions.forms.close(FormName.ContentLibraries))
    } catch (error) {
        yield put(
            actions.products.assignLibrary.failure({
                id: action.payload.productId,
                error,
            })
        )
    }
}

export function* unassignLibrarySaga(
    action: ReturnType<typeof actions.products.assignLibrary.request>
) {
    try {
        const data: Product.AllFields = yield call(
            api.unassignLibrary,
            action.payload
        )
        yield put(
            actions.products.unassignLibrary.success({
                data,
                message: 'Library was successfully removed!',
            })
        )
    } catch (error) {
        yield put(
            actions.products.unassignLibrary.failure({
                id: action.payload.productId,
                error,
            })
        )
    }
}

export function* toggleFeaturesSaga(
    action: ReturnType<typeof actions.products.toggleFeatures.request>
) {
    try {
        // TODO: maybe add Dto type?
        const dto: any = {
            productId: action.payload.id,
            features: action.payload.features,
        }

        const data: Product.AllFields = yield call(api.toggleFeatures, dto)
        yield delay(500)
        yield put(
            actions.products.toggleFeatures.success({
                data,
                message: 'Features updated successfully!',
            })
        )
        yield put(actions.forms.close(FormName.Features))
    } catch (error) {
        yield put(
            actions.products.toggleFeatures.failure({
                id: action.payload.id,
                error,
            })
        )
    }
}

export function* getProductFieldsSaga() {
    try {
        const options: Product.FieldOptions = yield call(api.getProductFields)
        yield put(actions.products.getFields.success(options))
    } catch (error) {
        yield put(actions.products.getFields.failure(error))
    }
}

export function* productsWatcher() {
    yield takeEvery(getType(actions.products.get.request), getProductsSaga)

    yield takeEvery(getType(actions.products.create.request), createProductSaga)

    yield takeEvery(getType(actions.products.update.request), updateProductSaga)

    yield takeEvery(
        getType(actions.products.assignLibrary.request),
        assignLibrarySaga
    )

    yield takeEvery(
        getType(actions.products.unassignLibrary.request),
        unassignLibrarySaga
    )

    yield takeEvery(
        getType(actions.products.toggleFeatures.request),
        toggleFeaturesSaga
    )
    yield takeEvery(
        getType(actions.products.getFields.request),
        getProductFieldsSaga
    )
}
