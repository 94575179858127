/**
 * This is the PARENT CONTAINER for the sidebar and the main body area
 * Responsibilities are to keep state between menu and body
 * Render the child components
 *
 *  -- Nice To Haves --
 *   - [] Sort by percentage completed
 */

import { Menu } from 'antd'
import { divide, find, reduce, round } from 'lodash'
import { multiply, reduce as reduceFp } from 'lodash/fp'
import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react'
import { isComplete } from '../../connected-products.service'
import {
    CxnCompletions,
    PConnection,
    PoCompletion,
    ProductOptionConnection,
} from '../cxn-po.interface'
import styles from './CxnPoContent.module.scss'
import { Completed, UpsellOrUpgrade } from './CxnPoSidebarItem'

type PoCxn = ProductOptionConnection
type Cmptl = PoCompletion

const hardcodedStyles = { background: 'white', height: '100%', borderRight: 0 }

const numDone = reduceFp<Cmptl, number>((p, v) => (v.isComplete ? ++p : p), 0)
const recalc = reduceFp<PoCxn, number>((p, v) => (isComplete(v) ? ++p : p), 0)
const calcPercent = (done: number, total: number) =>
    round(multiply(100)(divide(done, total)))

/**
 * This is the completed badge
 */

// -------------------------------- Main Component -------------------------------- //
type Props = {
    pCxns: PConnection[]
    poCxns: ProductOptionConnection[]
    currentProductId: string
    setCurrentProduct: Dispatch<SetStateAction<PConnection | undefined>>
    completionInfo: CxnCompletions
}

export const ConnectedPoSidebar = (props: Props) => {
    const { completionInfo, poCxns, currentProductId } = props
    const lastPos = useRef<ProductOptionConnection[]>()
    /**
     * Sets the menu and triggers some methods to run
     */
    const setCurrentMenu = ({ key }: { key: string }) => {
        const finder = (p: PConnection) => p.toProduct.id === key
        const newProduct = find(props.pCxns, finder)

        // ? this would be programmer error if it reaches here
        if (!newProduct) throw new Error('Something is broken: 1001')

        props.setCurrentProduct(newProduct)
    }

    // only used in dynamic percentages memo.
    const inactivePercentages = useMemo(() => {
        const eachPercentage: { [id: string]: number } = {}
        return reduce(
            completionInfo,
            (prev, { poCompletions: po, toProductId }) => ({
                ...prev,
                [toProductId]: calcPercent(numDone(po), po.length),
            }),
            eachPercentage
        )
    }, [completionInfo])

    // recalculates current menu percentages as things update
    const dynamicPercentages = useMemo(() => {
        if (!lastPos.current || lastPos.current !== poCxns) {
            return {
                ...inactivePercentages,
                [currentProductId]: calcPercent(recalc(poCxns), poCxns.length),
            }
        }
        return inactivePercentages
    }, [inactivePercentages, poCxns, currentProductId])

    // allows keeping reference to last props
    lastPos.current = props.poCxns
    return (
        <Menu
            style={hardcodedStyles}
            onClick={setCurrentMenu}
            selectedKeys={[currentProductId]}
            mode="vertical"
        >
            {props.pCxns.map(({ toProduct, linkType }) => (
                <Menu.Item key={toProduct.id} className={styles.menuItem}>
                    <div className={styles.container}>
                        <UpsellOrUpgrade
                            name={toProduct.name}
                            type={linkType}
                        />
                        <Completed
                            percentComplete={dynamicPercentages[toProduct.id]}
                        />
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    )
}
