import React from 'react'
import { Helmet } from 'react-helmet'
import newRelicConfig from '../../assets/js/newrelic/newrelic.browser-config'
import { RootRouter } from '../../routing/RootRouter'

const App: React.FC = () => {
    return (
        <>
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        innerHTML: newRelicConfig(),
                    },
                ]}
            />
            <RootRouter />
        </>
    )
}

export default App
