import { PlusOutlined } from '@ant-design/icons'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Can } from '../../../Authentication/permissions/Can'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { AllPCxnsByTypeApi } from '../../../services/api.interface'
import api from '../../../services/api.service'
import { CxnProductCreateForm } from '../../forms/connected-products/CxnProductCreateForm'
import { AddOnProducts } from './add-on-products/AddOnProducts'
import { BaseProducts } from './base-products/BaseProducts'
import { PCxnsByType } from './cxn-products.interface'
import { addonToSimple, productToSimple } from './cxn-products.service'
import { CxnProductPageHeader } from './CxnProductPageHeader'
import styles from './CxnProductPageHeader.module.scss'

export const ConnectedProductContainer = () => {
    const [{ baseProducts, addOnProducts }, setConnectedProducts] =
        useState<PCxnsByType>({ addOnProducts: [], baseProducts: [] })
    const [showForm, setShowForm] = useState(false)
    const [editForm, setEditForm] = useState(false)
    useEffect(() => {
        api.getConnectedProductsList().then(setTranf(setConnectedProducts))
    }, [])

    const handleSubmit = () => {
        api.getConnectedProductsList().then(setTranf(setConnectedProducts))
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerLayout}>
                    <CxnProductPageHeader />
                    <div className={styles.buttonContainer}>
                        <Can I="create" a="Product">
                            <PrimaryButton
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setShowForm(!showForm)
                                }}
                            >
                                Add new product
                            </PrimaryButton>
                        </Can>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.contentLayout}>
                    <BaseProducts baseProducts={baseProducts} />
                    <AddOnProducts addOnProducts={addOnProducts} />
                </div>
            </div>
            <CxnProductCreateForm
                baseproductsList={baseProducts}
                visible={showForm}
                editForm={editForm}
                onClose={() => {
                    setShowForm(!showForm)
                }}
                onSubmit={() => handleSubmit()}
            />
        </div>
    )
}

function setTranf(dis: Dispatch<SetStateAction<PCxnsByType>>) {
    return ({ addOnProducts, baseProducts }: AllPCxnsByTypeApi) => {
        return dis({
            addOnProducts: addonToSimple(addOnProducts),
            baseProducts: productToSimple(baseProducts),
        })
    }
}
