import { PlusOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Can } from '../../../Authentication/permissions/Can'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { strings } from '../../../constants'
import { selectFeatures } from '../../../store/features/features.selectors'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import { actions } from '../../../store/root-action'
import { Common } from '../../../store/typings/common.interface'
import { Product } from '../../../store/typings/product.interface'
import styles from './Features.module.scss'
import { FeaturesDisplay } from './FeaturesDisplay'
import { FeaturesEdit } from './FeaturesEdit'

interface Props {
    product: Common.ApiItem<Product.AllFields>
}

export const Features: React.FC<Props> = ({ product }) => {
    const isFormOpen = useSelector((state: RootState) =>
        selectFormOpen(state, FormName.Features)
    )

    const dispatch = useDispatch()
    const openForm = () => dispatch(actions.forms.open(FormName.Features))
    const closeForm = () => dispatch(actions.forms.close(FormName.Features))

    const features = useSelector(selectFeatures)
    const featureListItems = useMemo(
        () =>
            Object.values(features)
                .map(({ payload }) => ({
                    ...payload,
                    assignedTimestamp: product.payload.features.find(
                        (f) => f.id === payload.id
                    )?.assignedTimestamp,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        [product, features]
    )

    return (
        <div className={styles.container}>
            <Can I="manage" a="Feature">
                <div className={styles.actions}>
                    <PrimaryButton
                        icon={<PlusOutlined />}
                        onClick={openForm}
                        disabled={isFormOpen}
                    >
                        {strings.assignFeatureBtn}
                    </PrimaryButton>
                </div>
            </Can>
            <Card className={styles.card}>
                {isFormOpen ? (
                    <FeaturesEdit
                        closeEdit={closeForm}
                        features={featureListItems}
                        product={product}
                    />
                ) : (
                    <FeaturesDisplay
                        features={featureListItems}
                        isSaving={product.isSaving}
                    />
                )}
            </Card>
        </div>
    )
}
