import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { Feature } from '../typings/features.interface'
import { mapPayloadById } from '../utils'

export type FeaturesById = Common.ItemsById<Feature>

export interface FeaturesDefaultState {
    byId: FeaturesById
}

export const defaultState: FeaturesDefaultState = {
    byId: {},
}

export const byIdReducer = createReducer(defaultState.byId)
    // .handleAction(actions.features.get.request, (state, action) =>
    //     setFetchInProgress(state)
    // )
    .handleAction(actions.features.get.success, (state, action) =>
        mapPayloadById(action.payload)
    )
// .handleAction(actions.features.get.failure, (state, action) =>
//     setRequestError(state, action.payload.error)
// )

export const featuresReducer = combineReducers({
    byId: byIdReducer,
})

// Redux Types
export type FeaturesAction = ActionType<typeof actions.features>
export type FeaturesState = StateType<typeof featuresReducer>
