import { Card, Form, Input } from 'antd'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
    CancelButton,
    LinkButton,
    SubmitButton,
} from '../../../components/buttons/Buttons'
import config from '../../../config'
import { strings } from '../../../constants'
import { actions } from '../../../store/root-action'
import { Product } from '../../../store/typings/product.interface'
import styles from './ContentLibraryForm.module.scss'

interface Props {
    product: Product.AllFields
    onCancel(): void
}

const contentLibrariesLink = (
    <LinkButton
        size="normal"
        href={config.contentLibrariesUri}
        target="_blank"
        rel="noreferrer"
    >
        Search for content library
    </LinkButton>
)

export const ContentLibraryForm: React.FC<Props> = ({
    product,
    onCancel,
}: Props) => {
    const inputRef = useRef<Input>(null)
    const dispatch = useDispatch()

    const assign = (payload: Product.ContentLibraryRequest) =>
        dispatch(actions.products.assignLibrary.request(payload))

    const handleSubmit = (fields: any) => {
        assign({ id: fields.libraryId.toLowerCase(), productId: product.id })
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return (
        <Card
            title="Assign content library"
            extra={contentLibrariesLink}
            className={styles.container}
        >
            <Form
                colon={false}
                hideRequiredMark
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Content Library ID"
                    name="libraryId"
                    rules={[
                        { required: true, message: 'This field is required' },
                    ]}
                >
                    <Input
                        className={styles.input}
                        ref={inputRef}
                        autoComplete="off"
                    />
                </Form.Item>
                <SubmitButton>{strings.assignLibrarySubmitBtn}</SubmitButton>
                <CancelButton onClick={onCancel} />
            </Form>
        </Card>
    )
}
