import React from 'react'
import { PrimaryButton } from '../../components/buttons/Buttons'
import styles from './Footer.module.scss'

export const Footer: React.FC = () => (
    <div className={styles.footerWrap}>
        <div className={styles.footerContent}>
            <PrimaryButton
                href="https://forms.gle/KHBWP9scFL4cKpA77"
                target="_blank"
                rel="noreferrer"
                size="small"
            >
                Give feedback
            </PrimaryButton>
            <span className={styles.text}>
                Something not working? Have a feature request?
            </span>
        </div>
    </div>
)
