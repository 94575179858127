import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'

export interface ConnectedProductsDefaultState {
    isSaving: boolean
}

export const defaultState: ConnectedProductsDefaultState = {
    isSaving: false,
}

export const isSavingReducer = createReducer(defaultState.isSaving)
    .handleAction(actions.products.create.request, () => true)
    .handleAction(
        [
            actions.connectedProduct.create.success,
            actions.products.create.failure,
        ],
        () => false
    )

export const connectedProductsReducer = combineReducers({
    isSaving: isSavingReducer,
})

// Redux Types
export type ProductAction = ActionType<typeof actions.products>
export type ProductState = StateType<typeof connectedProductsReducer>
