import {
    AppstoreOutlined,
    DatabaseOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { setTimeout } from 'timers'
import { RootState } from 'typesafe-actions'
import { Can } from '../../../Authentication/permissions/Can'
import { LinkButton, PrimaryButton } from '../../../components/buttons/Buttons'
import { EmptyState } from '../../../components/empty-state/EmptyState'
import { strings, testIds } from '../../../constants'
import apiService from '../../../services/api.service'
import { defaultState } from '../../../store/filters/filters.reducer'
import { selectProductOptionFilters } from '../../../store/filters/filters.selectors'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import {
    ProductOptionFilterFields,
    selectProductOptionsById,
    selectProductOptionView,
} from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { Alert } from '../../../store/typings/alert.interface'
import { ProductOptionForm } from '../../forms/ProductOptionForm'
import { ProductOptionFilterInputs } from './ProductOptionFilterInputs'
import { ProductOptionsList } from './ProductOptionList'
import styles from './ProductOptions.module.scss'
import { ProductOptionsTable } from './ProductOptionsTable'
import { filterProductOptions } from './utils'

type OwnProps = {
    productId: string
    optionData: ProductOptionFilterFields[]
}

export enum View {
    LIST = 'list',
    CARD = 'card',
}

export const ProductOptions: React.FC<OwnProps> = ({
    productId,
    optionData,
}) => {
    const dispatch = useDispatch()
    const { hash } = useLocation()
    const optionsById = useSelector((state: RootState) =>
        selectProductOptionsById(state)
    )
    const currentView: any = useSelector((state: RootState) =>
        selectProductOptionView(state)
    )

    const selectView = (clickedView: View) => {
        if (clickedView === View.LIST && clickedView !== currentView.layout) {
            dispatch(
                actions.productOptions.setView({
                    layout: View.LIST,
                })
            )
        } else if (
            clickedView === View.CARD &&
            clickedView !== currentView.layout
        ) {
            dispatch(
                actions.productOptions.setView({
                    layout: View.CARD,
                })
            )
        }
    }

    const shortcut = useMemo(() => {
        const id = hash.replace('#', '')
        const data = optionsById[id]?.payload
        const isLocalized = !!data?.countryCode
        const match = productId === data?.productCatalogId

        return {
            associatedProductOption:
                match && data.defaultRatePlanCountryId
                    ? [data.defaultRatePlanCountryId]
                    : [],
            filter: match ? data.name : '',
            id: id.length === 36 ? id : null,
            isLocalized,
            match,
            url: isLocalized
                ? apiService.getRelativeUrl(
                      apiService.getLocalizedProductOptionUrl(productId, id)
                  )
                : '',
        }
    }, [hash, optionsById, productId])

    const filters: any = useSelector((state: RootState) =>
        selectProductOptionFilters(state)
    )

    const isFormOpen = useSelector((state: RootState) =>
        selectFormOpen(state, FormName.ProductOptionCreate)
    )

    const openForm = () =>
        dispatch(actions.forms.open(FormName.ProductOptionCreate))

    const addFailAlert = useCallback(
        (alert: Alert.Base) => {
            dispatch(actions.alerts.addFail(alert))
        },
        [dispatch]
    )

    useEffect(() => {
        const { associatedProductOption, filter, id, isLocalized, match } =
            shortcut

        if (match) {
            // Delay until after parent component useEffect triggers (prevent clobber)
            setTimeout(() => {
                if (isLocalized) {
                    dispatch(
                        actions.filters.filterLocalizedProductOption({
                            ...defaultState.localizedProductOption,
                            associatedProductOption,
                            name: filter,
                            state: [],
                        })
                    )
                } else {
                    dispatch(
                        actions.filters.filterProductOption({
                            ...defaultState.productOption,
                            name: filter,
                            state: [],
                        })
                    )
                }
            }, 1)
        } else if (!filter && id) {
            addFailAlert({
                details: [{ message: 'Unable to find product option.' }],
                message: `Uh oh! This shortcut seems to be invalid.`,
            })
        }
        /* eslint-disable react-hooks/exhaustive-deps */
        // Excluding shortcut from dependencies to avoid unnecessarily re-running this hook
    }, [dispatch, productId, hash, addFailAlert])

    const filteredOptions = useMemo(
        () => filterProductOptions(optionData, filters),
        [optionData, filters]
    )

    const filteredResults = () =>
        filteredOptions.length !== optionData.length
            ? strings.filterResults + ': ' + filteredOptions.length
            : strings.filterResultsAll

    return shortcut.isLocalized ? (
        <Redirect to={shortcut.url} />
    ) : (
        <div className={styles.container} data-testid={testIds.productOptions}>
            <div className={styles.actions}>
                <Can I="create" a="ProductOption">
                    <PrimaryButton
                        onClick={openForm}
                        disabled={isFormOpen}
                        icon={<PlusOutlined />}
                    >
                        {strings.createOptionBtn}
                    </PrimaryButton>
                </Can>
            </div>

            <ProductOptionFilterInputs />

            <div className={styles.productOptions}>
                {isFormOpen ? (
                    <ProductOptionForm productId={productId} />
                ) : null}

                {filteredOptions.length > 0 ? (
                    <>
                        <div className={styles.results}>
                            {filteredResults()}

                            <LinkButton
                                className={classNames(
                                    styles.viewToggle,
                                    currentView.layout === View.CARD
                                        ? styles.active
                                        : undefined
                                )}
                                icon={<AppstoreOutlined />}
                                onClick={() => selectView(View.CARD)}
                            />
                            <LinkButton
                                className={classNames(
                                    styles.viewToggle,
                                    currentView.layout === View.LIST
                                        ? styles.active
                                        : undefined
                                )}
                                icon={<DatabaseOutlined />}
                                onClick={() => selectView(View.LIST)}
                            />
                        </div>
                        {currentView.layout === View.CARD ? (
                            <ProductOptionsList
                                options={filteredOptions}
                                totalOptions={optionData.length}
                            />
                        ) : (
                            <ProductOptionsTable
                                productOptions={filteredOptions}
                            />
                        )}
                    </>
                ) : (
                    <EmptyState />
                )}
            </div>
        </div>
    )
}
