import { UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, Collapse, Row, Typography, Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload/interface'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { actions } from '../../../store/root-action'
import styles from './CsvUpload.module.scss'

const { Text } = Typography

interface Props {
    isUploading?: boolean
}

const { Panel } = Collapse

export const CsvUpload: React.FC<Props> = ({ isUploading }) => {
    const [fileList, setFileList] = useState([] as any[])
    const dispatch = useDispatch()

    const handleUpload = useCallback(() => {
        const formData = new FormData()
        formData.append('csv', fileList[0])
        dispatch(actions.productOptions.startImport.request(formData))
    }, [dispatch, fileList])

    const uploadProps: UploadProps = {
        accept: '.csv',
        onRemove: () => {
            setFileList([])
        },
        beforeUpload: (file: any) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    return (
        <>
            <div className={styles.upload}>
                <Upload {...uploadProps}>
                    <Button>
                        <UploadOutlined /> Choose CSV
                    </Button>
                </Upload>
                <PrimaryButton
                    onClick={handleUpload}
                    disabled={fileList.length === 0}
                    loading={isUploading}
                    size="small"
                    style={{ marginTop: 16 }}
                >
                    Upload
                </PrimaryButton>
            </div>

            <div className={styles.rules}>
                <Card
                    title={
                        <div className={styles.header}>
                            CSV Upload Instructions
                        </div>
                    }
                    style={{ fontSize: 16 }}
                >
                    <Row
                        gutter={[
                            { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                            18,
                        ]}
                        className={'row-gap-18'}
                    >
                        <Col flex="50%">
                            <Collapse
                                defaultActiveKey={['template', 'update']}
                                style={{ fontSize: 16 }}
                            >
                                <Panel
                                    header={
                                        <div className={styles.subHeader}>
                                            Generating a CSV template
                                        </div>
                                    }
                                    key="template"
                                    showArrow={false}
                                >
                                    <p>
                                        The easiest way to generate a template
                                        for the CSV upload is to export the
                                        product options for the relevant
                                        product. You can do this from the
                                        actions menu at the top of the product
                                        details page. The exported CSV can then
                                        be used as a basis for creating new
                                        product options, or updating any
                                        existing product options under that
                                        product.
                                    </p>
                                    <p>
                                        By design, you should be able to import
                                        a CSV with the exact same columns as the
                                        exported CSV, but there are a few things
                                        to keep in mind when both creating and
                                        updating product options.
                                    </p>
                                </Panel>
                                <Panel
                                    header={
                                        <div className={styles.subHeader}>
                                            Updating a product option
                                        </div>
                                    }
                                    key="update"
                                    showArrow={false}
                                >
                                    <p>
                                        If there is a value in the{' '}
                                        <Text code={true}>id</Text> column, it
                                        will be assumed you intend to update the
                                        product option with the provided ID.
                                    </p>
                                    <p>
                                        If you add a new price option to an
                                        existing paid product option, and that
                                        product option has a corresponding free
                                        trial, then a free trial price option
                                        will be automatically created and mapped
                                        to the new price option.
                                    </p>
                                    <p>
                                        Timestamps will be ignored when updating
                                        (and creating) product options, so feel
                                        free to leave those values as they are.
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                        <Col flex="50%">
                            <Collapse
                                defaultActiveKey={['create']}
                                style={{ fontSize: 16 }}
                            >
                                <Panel
                                    header={
                                        <div className={styles.subHeader}>
                                            Creating a product option
                                        </div>
                                    }
                                    key="create"
                                    showArrow={false}
                                >
                                    <p>
                                        To create a new product option, be sure
                                        to leave <Text code={true}>id</Text>{' '}
                                        column empty. It will be assumed that
                                        you intend to create a new product
                                        option and an ID will be automatically
                                        generated.
                                    </p>
                                    <p>
                                        When creating a paid product option, if
                                        you also need a corresponding free trial
                                        version then be sure to fill in the{' '}
                                        <Text code={true}>
                                            free_trial_product_id
                                        </Text>{' '}
                                        column. Because free trial products live
                                        alongside paid products, we need to know
                                        under which product the free trial
                                        product option should be created.
                                    </p>
                                    <p>
                                        If you do include a{' '}
                                        <Text code={true}>
                                            free_trial_product_id
                                        </Text>
                                        , then please only include the base name
                                        of the product option in the{' '}
                                        <Text code={true}>name</Text> column
                                        (e.g. don&apos;t append country code,
                                        billing period, etc.) The name will be
                                        automatically generated as follows:
                                    </p>
                                    <ul>
                                        <li>
                                            If there is a billing period:{' '}
                                            <b>BASE NAME (Monthly/Annual)</b>
                                        </li>
                                        <li>
                                            If there is a country code:{' '}
                                            <b>
                                                BASE NAME (Monthly/Annual)
                                                [country_code]
                                            </b>
                                        </li>
                                        <li>
                                            If there is a test ID:{' '}
                                            <b>
                                                BASE NAME (Monthly/Annual)
                                                [country_code]
                                                &#123;test_id&#125;
                                            </b>
                                        </li>
                                        <li>
                                            For free trials:{' '}
                                            <b>
                                                BASE NAME Free Trial (converts
                                                to Monthly/Annual)
                                                [country_code]
                                                &#123;test_id&#125;
                                            </b>
                                        </li>
                                    </ul>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    )
}
