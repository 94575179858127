import { call, put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { actions } from '../root-action'
import { ConnectedProduct } from '../typings/connected-products.interface'

export function* createConnectedProductSaga(
    action: ReturnType<typeof actions.connectedProduct.create.request>
) {
    try {
        const data: ConnectedProduct.AllFields = yield call(
            api.createConnectedProduct,
            action.payload
        )
        yield put(
            actions.connectedProduct.create.success({
                data,
            })
        )
    } catch (error) {
        yield put(actions.connectedProduct.create.failure(error))
    }
}

export function* connectedProductsWatcher() {
    yield takeEvery(
        getType(actions.connectedProduct.create.request),
        createConnectedProductSaga
    )
}
