import React from 'react'
import styles from './Section.module.scss'

interface Props {
    title: string
}

export const Section: React.FC<Props> = ({ title }) => {
    return (
        <div className={styles.section}>
            <span>{title}</span>
            <hr />
        </div>
    )
}
