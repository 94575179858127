import { Col, Form, Input as AntInput, Row } from 'antd'
import { FormProps } from 'antd/lib/form'
import classNames from 'classnames'
import currencyJs from 'currency.js'
import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Assign, Optional, PickByValue } from 'utility-types'
import { Input } from '../../components/form'
import { FormDrawer } from '../../components/form/FormDrawer'
import { Section } from '../../components/section/Section'
import { Stack } from '../../components/stack/Stack'
import { strings } from '../../constants'
import { FormName } from '../../store/forms/forms.reducer'
import {
    makeSelectExistingPriceIds,
    selectProductOptionFieldOptions,
} from '../../store/product-options/product-options.selectors'
import { selectProduct } from '../../store/products/products.selectors'
import { actions } from '../../store/root-action'
import { CommonEnum } from '../../store/typings/common.enum'
import { CurrencyEnum } from '../../store/typings/currency.enum'
import { PriceOption } from '../../store/typings/price-option.interface'
import {
    ProductOptionEnum as Enum,
    ProductOptionEnum,
} from '../../store/typings/product-option.enum'
import { ProductOption } from '../../store/typings/product-option.interface'
import { ProductEnum } from '../../store/typings/product.enum'
import { FormPriceOptions } from './EditProductOptionForm'
import { LocalizedInputs } from './LocalizedInputs'
import { NameInputs } from './NameInputs'
import { PriceOptionForm } from './PriceOptionsForm'
import {
    ConditionalFormItem,
    FormItem,
    OptionalFormItem,
    RequiredFormItem,
    Select,
    TrueFalse,
} from './ProductOptionFields'
import styles from './ProductOptionForm.module.scss'
import { taxCodes } from './TaxCodes'

export type PriceOptionEntries = {
    [K in keyof FormPriceOptions]: [K, FormPriceOptions[K]]
}[keyof FormPriceOptions][]

export type AllFieldsWithFormPriceOptions = Assign<
    ProductOption.AllFields,
    {
        priceOptions: FormPriceOptions
    }
>

interface OwnProps extends FormProps {
    initial?: AllFieldsWithFormPriceOptions
    onSubmit?: () => void
    productId: string
    title?: string
}

type Props = OwnProps & ConnectedProps<typeof reduxConnector>

const FormComponent: React.FC<Props> = ({
    clearError,
    closeForm,
    create,
    existingPriceIds,
    fieldOptions,
    initial,
    onSubmit,
    product,
    productId,

    update,
}: Props) => {
    const [form] = Form.useForm()

    const nameRef = useRef<AntInput>(null)

    const [isLocalized, setIsLocalized] = useState(
        initial?.countryCode ? true : false
    )

    const initialValues = useMemo(() => {
        const setBoolDefault = (
            field: keyof PickByValue<ProductOption.AllFields, boolean>,
            bool = false
        ) => initial?.[field] ?? bool

        const defaults: Optional<ProductOption.FormFields> = {
            customerSource: initial
                ? initial.customerSource
                : ProductOptionEnum.CustomerSource.Pluralsight,
            free: setBoolDefault('free'),
            freeTrial: setBoolDefault('freeTrial'),
            digitalExpand: setBoolDefault('digitalExpand'),
            digitalRenew: setBoolDefault('digitalRenew'),
            digitalCheckout: setBoolDefault('digitalCheckout'),
            isPluralsightOne: setBoolDefault('isPluralsightOne'),
            legacyProductSku: initial
                ? initial.legacyProductSku
                : product.type === ProductEnum.Type.Proserv
                ? undefined
                : product.sku,
            royaltyBearing: setBoolDefault('royaltyBearing'),
            taxable: setBoolDefault('taxable', true),
            taxCode: initial?.priceOptions.USD.taxCode || null,
            useForOfferCodeRedemption: setBoolDefault(
                'useForOfferCodeRedemption'
            ),
        }

        const countryCode = fieldOptions.countries.find(
            (c) => c.code === initial?.countryCode
        )?.displayName

        return initial
            ? {
                  ...initial,
                  ...defaults,
                  countryCode,
              }
            : defaults
    }, [fieldOptions.countries, initial, product.sku, product.type])

    const configurePriceOptions = (
        priceOptions: FormPriceOptions,
        taxCode: string | null
    ) =>
        (Object.entries(priceOptions) as PriceOptionEntries).reduce(
            (accum, [currency, data]) => {
                const { price, convertsToPriceOptionId, active } = data

                if (!price) {
                    return accum
                }

                return [
                    ...accum,
                    {
                        id: initial?.priceOptions[currency]?.id,
                        convertsToPriceOptionId:
                            convertsToPriceOptionId || null,
                        currency,
                        price: currencyJs(price, {
                            symbol: '',
                            separator: '',
                        }).format(),
                        status:
                            active || CurrencyEnum.isDefaultCurrency(currency)
                                ? CommonEnum.Status.Active
                                : CommonEnum.Status.Inactive,
                        taxCode,
                    },
                ]
            },
            [] as PriceOption.Request[]
        )

    const extractCountryCode = (countrySelectValue?: string | null) =>
        countrySelectValue ? countrySelectValue.split('-')[1].trim() : null

    const defaultToNull = (
        fields: Omit<ProductOption.FormFields, 'countryCode' | 'priceOptions'>
    ) => {
        const isEmptyOrMissing = (value: any) =>
            value === '' || value === undefined

        const nulled = Object.entries(fields).reduce((obj, [key, val]) => {
            return {
                ...obj,
                [key]: isEmptyOrMissing(val) ? null : val,
            }
        }, {} as Omit<ProductOption.CreateRequest, 'countryCode' | 'priceOptions'>)

        const { billingPeriod, uom, chargeModel, chargeType } = fields

        const isRecurring = chargeType === Enum.ChargeType.Recurring
        const isPerUnit = chargeModel === Enum.ChargeModel.PerUnit

        return {
            ...nulled,
            // Conditional fields only rendered based on other field values
            billingPeriod: isRecurring && billingPeriod ? billingPeriod : null,
            uom: isPerUnit && uom ? uom : null,
            defaultRatePlanCountryId: fields.defaultRatePlanCountryId || null,
        }
    }

    const prepFieldsForRequest = (
        fields: ProductOption.FormFields
    ): ProductOption.CreateRequest => {
        const { countryCode, priceOptions, taxCode, ...other } = fields
        const parsedTaxCode = taxCode || null
        const returnObject = {
            ...defaultToNull(other),
            countryCode: extractCountryCode(countryCode),
            priceOptions: configurePriceOptions(priceOptions, parsedTaxCode),
            productCatalogId: productId,
            taxInclusive: false,
        }
        delete returnObject['taxCode']
        return returnObject
    }

    const formGet = (field: string) => {
        return form.getFieldValue(field)
    }

    const autoFillInvoice = () => {
        const value = form.getFieldValue('name')
        form.setFields([{ name: 'invoiceName', value }])
    }

    const sendRequest = (requestData: ProductOption.CreateRequest) => {
        if (initial) {
            update({
                id: initial.meta.parentId || initial.id,
                ...requestData,
            })
        } else {
            create(requestData)
        }
    }

    const handleFormSubmit = (fields: any) => {
        fields = {
            ...fields,
            name: fields?.name?.trim(),
            invoiceName: fields?.invoiceName?.trim(),
            testId: fields?.testId?.trim(),
            marketingId: fields?.marketingId?.trim(),
            legacyProductSku: fields?.legacyProductSku?.trim(),
            sfdcProductId: fields?.sfdcProductId?.trim(),
        }
        const requestData = prepFieldsForRequest(
            fields as ProductOption.FormFields
        )

        sendRequest(requestData)
        onSubmit && onSubmit()
    }

    const toggleLocalizedFields = (event: any) => {
        setIsLocalized(event.target.value)
    }

    const setPrice = (currency: CurrencyEnum.Code, value?: string) => ({
        name: ['priceOptions', currency, 'price'],
        value,
    })

    const setPricesToZero = () => {
        const zeroPrice = currencyJs(0).format({ symbol: '' })

        form.setFields([setPrice(CurrencyEnum.Code.USD, zeroPrice)])
        Object.values(CurrencyEnum.Code)
            .filter((c) => form.getFieldValue(['priceOptions', c])?.price)
            .forEach((c) => form.setFields([setPrice(c, zeroPrice)]))
    }

    const setFree = (value: boolean) =>
        form.setFields([{ name: 'free', value }])

    const setTaxable = (value: boolean) =>
        form.setFields([{ name: 'taxable', value }])

    const handleConditionalValues = (changed: any) => {
        if (changed.freeTrial === true) {
            setPricesToZero()
            setFree(true)
            setTaxable(false)
        }

        if (changed.free === true) {
            setPricesToZero()
            setTaxable(false)
        }

        if (changed.free === false) {
            setTaxable(true)
        }

        if (changed.chargeModel === Enum.ChargeModel.PerUnit) {
            form.setFields([{ name: 'uom', value: Enum.Uom.Subscription }])
        }
    }

    useEffect(() => {
        if (nameRef.current) {
            nameRef.current.focus()
        }
    }, [])

    return (
        <FormDrawer
            className={classNames(styles.productOptionForm)}
            titleText={
                initial
                    ? strings.editProductOptionFormTitle
                    : strings.createOptionFormTitle
            }
            visible={true}
            onClose={() => {
                clearError()
                closeForm()
            }}
            formName={strings.productOptionFormName}
            submitText={
                initial ? strings.editSubmitBtn : strings.createSubmitBtn
            }
            data-testid={strings.productOptionDrawerFormName}
        >
            <Form
                className={styles.content}
                colon={false}
                form={form}
                hideRequiredMark
                initialValues={initialValues}
                layout="vertical"
                name={strings.productOptionFormName}
                onFinish={handleFormSubmit}
                onValuesChange={handleConditionalValues}
            >
                <Row gutter={[0, 12]} className={'row-gap-12'}>
                    <Col sm={22} lg={11}>
                        <Section title={'REQUIRED FIELDS'} />

                        <NameInputs
                            autoFillInvoice={autoFillInvoice}
                            formGet={formGet}
                        />
                        <FormItem name="chargeType" label="Charge Type">
                            <Select
                                options={fieldOptions.chargeType}
                                disabled={initial && !initial.meta.isNew}
                            />
                        </FormItem>
                        <ConditionalFormItem
                            relatedField={'chargeType'}
                            compareFn={(relatedValue) =>
                                relatedValue === Enum.ChargeType.Recurring
                            }
                            component={
                                <FormItem
                                    name="billingPeriod"
                                    label="Billing period"
                                >
                                    <Select
                                        options={fieldOptions.billingPeriod}
                                    />
                                </FormItem>
                            }
                        />
                        <FormItem name="chargeModel" label="Charge model">
                            <Select options={fieldOptions.chargeModel} />
                        </FormItem>
                        <ConditionalFormItem
                            relatedField={'chargeModel'}
                            compareFn={(relatedValue) =>
                                relatedValue === Enum.ChargeModel.PerUnit
                            }
                            component={
                                <RequiredFormItem
                                    name="uom"
                                    label="Unit of measure"
                                >
                                    <Select options={fieldOptions.uom} />
                                </RequiredFormItem>
                            }
                        />
                        <FormItem name="customerSource" label="Customer source">
                            <Select options={fieldOptions.customerSource} />
                        </FormItem>

                        <Stack size={24} />

                        <Section title={'OPTIONAL FIELDS'} />

                        <OptionalFormItem
                            name="sfdcProductId"
                            label="CPQ product ID"
                        >
                            <Input />
                        </OptionalFormItem>
                        <OptionalFormItem
                            name="legacyProductSku"
                            label="Legacy Product SKU"
                        >
                            <Input allowClear />
                        </OptionalFormItem>
                        <OptionalFormItem
                            name="marketingId"
                            label="Marketing ID"
                        >
                            <Input />
                        </OptionalFormItem>
                        <OptionalFormItem
                            name="testId"
                            label="Marketing test ID"
                        >
                            <Input />
                        </OptionalFormItem>
                        <Form.Item label="Do you want to localize this Product Option?">
                            <TrueFalse
                                onChange={toggleLocalizedFields}
                                defaultValue={isLocalized}
                            />
                        </Form.Item>
                        {isLocalized ? (
                            <LocalizedInputs
                                countries={fieldOptions.countries}
                            />
                        ) : null}
                    </Col>
                    <Col sm={0} lg={2}></Col>
                    <Col sm={22} lg={11}>
                        <Section title={'TAGS'} />
                        <FormItem name="freeTrial" label="Is free trial?">
                            <TrueFalse />
                        </FormItem>
                        <FormItem name="free" label="Is free?">
                            <TrueFalse />
                        </FormItem>
                        <FormItem name="taxable" label="Taxable?">
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="useForOfferCodeRedemption"
                            label="Used for offer code redemption?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="isPluralsightOne"
                            label="Is Pluralsight One?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="digitalCheckout"
                            label="Digital checkout enabled?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="digitalExpand"
                            label="Digital expand enabled?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="digitalRenew"
                            label="Digital renew enabled?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem
                            name="royaltyBearing"
                            label="Royalty bearing?"
                        >
                            <TrueFalse />
                        </FormItem>
                        <FormItem name="taxCode" label="Tax Code">
                            <Select
                                defaultValue=""
                                options={Object.values(taxCodes)}
                            />
                        </FormItem>
                        <Stack size={24} />
                        <Section title={'PRICE OPTIONS'} />
                        <PriceOptionForm
                            initial={initial && initial.priceOptions}
                            currencies={fieldOptions.currencies}
                            existingPriceIds={existingPriceIds}
                            form={form}
                        />
                    </Col>
                </Row>
            </Form>
        </FormDrawer>
    )
}

const mapStateToProps =
    () =>
    (state: RootState, { initial, productId }: OwnProps) => ({
        product: selectProduct(state, productId),
        fieldOptions: selectProductOptionFieldOptions(state),
        existingPriceIds: initial
            ? makeSelectExistingPriceIds()(
                  state,
                  initial.meta.parentId || initial.id
              )
            : [],
    })

const mapDispatchToProps = (
    dispatch: Dispatch<any>,
    { initial }: OwnProps
) => ({
    closeForm: () => {
        const formName = initial
            ? FormName.ProductOptionEdit
            : FormName.ProductOptionCreate

        return dispatch(actions.forms.close(formName))
    },
    create: (productOption: ProductOption.CreateRequest) =>
        dispatch(actions.productOptions.create.request(productOption)),
    update: (productOption: ProductOption.UpdateRequest) =>
        dispatch(actions.productOptions.update.request(productOption)),
    clearError: () => {
        if (initial) {
            return dispatch(
                actions.productOptions.clearErrorFor(
                    initial.meta.parentId || initial.id
                )
            )
        }
        return dispatch(actions.productOptions.clearError())
    },
})

const reduxConnector = connect(mapStateToProps, mapDispatchToProps)

export const ProductOptionForm = reduxConnector(FormComponent)
