import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { ProductEnum } from '../../store/typings/product.enum'
import styles from './CategoryFilter.module.scss'

export const CategoryFilter: React.FC<SelectProps<any>> = (props) => {
    const options = [
        ...Object.values(ProductEnum.Category).map((category: string) => {
            return { label: category, value: category }
        }),
    ]

    return (
        <Select
            allowClear
            className={styles.category}
            mode="multiple"
            options={options}
            placeholder="All categories"
            showArrow
            size="large"
            {...props}
        />
    )
}
