// ! antd.override.css must come before index.scss since ant attempts to override global styles
// https://github.com/ant-design/ant-design/issues/4331
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './assets/css/antd.override.css'
import './assets/scss/index.scss'
import App from './containers/app/App'
import store from './store/configure-store'

const root = (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(root, document.getElementById('root'))
