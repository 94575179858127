import { UserClaims } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import React, { createContext, useState } from 'react'
import apiService from '../services/api.service'
import type { AuthCtx, ChefAuth, UserInfo } from './authentication.interface'
import { configureAbility } from './permissions/ability'

const authAxios = axios.create(apiService.baseConfig)

const defaultUser: UserInfo = {
    email: '',
    firstName: '',
    groups: [],
    lastName: '',
    timezone: '',
    name: '',
}

const useProvideAuth = (): AuthCtx => {
    const { oktaAuth } = useOktaAuth()

    const [authState, setAuthState] = useState<ChefAuth>({
        isAuthenticated: false,
        tokenError: null,
        requestState: 'idle',
    })

    const [user, setUser] = useState<UserInfo>(defaultUser)

    /**
     * Private Method, sets user information to context after token login
     */
    const makeUser = (okta: UserClaims) => {
        const email = okta.email?.includes('.invalid')
            ? okta.preferred_username
            : okta.email

        return {
            email: email ?? '',
            firstName: okta.family_name ?? '',
            groups: okta.groups,
            lastName: okta.given_name ?? '',
            name: okta.name ?? '',
            timezone: okta.zoneinfo ?? '',
        }
    }

    /**
     * This is the login method for the backend token
     */
    const setToken = async () => {
        const authHeader = (token: string) => ({
            Authorization: `Bearer ${token}`,
        })

        if (authState.requestState === 'progress') return

        const opts = {
            headers: authHeader(oktaAuth.getAccessToken() ?? ''),
        }

        try {
            /**
             * ATTEMPTING TO VERIFY OKTA TOKEN
             */
            await authAxios.post('/token', {}, opts)

            // UPDATING THE USER INFO AND SETTING PERMISSIONS
            const user = makeUser(await oktaAuth.getUser())
            setUser(user)
            configureAbility(user)

            // UPDATE THE AUTH STATE TO IS AUTHENTICATED
            setAuthState({
                ...authState,
                isAuthenticated: true,
            })
        } catch (e) {
            setAuthState({
                isAuthenticated: false,
                tokenError: e,
                requestState: 'error',
            })
        }
    }

    /**
     * Clear token error after a message possibly
     */
    const clearTokenError = () => {
        setAuthState({
            ...authState,
            tokenError: null,
            requestState: 'idle',
        })
    }

    return {
        setToken,
        clearTokenError,
        chefAuth: authState,
        user,
    }
}

/**
 * Default Context
 */
export const ChefAuthContext = createContext<AuthCtx>({
    clearTokenError: () => null, // placeholder
    setToken: () => Promise.resolve(), // placeholder
    chefAuth: {
        isAuthenticated: false,
        requestState: 'idle',
        tokenError: null,
    },
    user: defaultUser,
})

/**
 * This is the provider. Call only once.
 */
export const ProvideAuth: React.FC = ({ children }) => {
    const auth = useProvideAuth()

    return (
        <ChefAuthContext.Provider value={auth}>
            {children}
        </ChefAuthContext.Provider>
    )
}
