import React from 'react'
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom'
import { ProductOptionsByType } from '../../../store/product-options/product-options.selectors'
import { ProductEnum } from '../../../store/typings/product.enum'
import { Product } from '../../../store/typings/product.interface'
import styles from './ProductNavbar.module.scss'

const ProductNavLink: React.FC<NavLinkProps> = (props) => (
    <NavLink
        activeClassName={styles.active}
        className={styles.title}
        exact
        {...props}
    >
        {props.children}
    </NavLink>
)

type Props = {
    product: Product.AllFields
    optionData: ProductOptionsByType
}

export const ProductNavbar: React.FC<Props> = ({ optionData, product }) => {
    const { url } = useRouteMatch()
    const localizedRoute = `${url}/localized`
    const contentLibrariesRoute = `${url}/content-libraries`
    const featuresRoute = `${url}/features`

    const { contentLibraries, features, libraryScope } = product

    return (
        <div className={styles.container}>
            <ProductNavLink to={url} key="options">
                {`Product options (${optionData.default.length})`}
            </ProductNavLink>

            {optionData.localized.length > 0 ? (
                <ProductNavLink to={localizedRoute} key="localized">
                    {`Localized (${optionData.localized.length})`}
                </ProductNavLink>
            ) : null}

            {libraryScope !== ProductEnum.LibraryScope.None ? (
                <ProductNavLink
                    to={contentLibrariesRoute}
                    key="content-libraries"
                >
                    {`Content libraries (${contentLibraries.length})`}
                </ProductNavLink>
            ) : null}
            <ProductNavLink to={featuresRoute} key="features">
                {`Features (${features.length})`}
            </ProductNavLink>
        </div>
    )
}
