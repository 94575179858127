import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Descriptions, Divider, List, Result, Row, Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import apiService from '../../../services/api.service'
import { useAuditData } from '../../hooks/useAuditData'
import { Detail } from './Detail'

export const AuditOverview: React.FC = () => {
    const {
        getProductOptionType,
        overview: { totals, drafts },
    } = useAuditData()

    const detail =
        drafts.products.length + drafts.productOptions.length > 0 ? (
            <>
                <List
                    header={
                        <div>Product drafts ({drafts.products.length})</div>
                    }
                    bordered={true}
                    dataSource={drafts.products}
                    renderItem={({ id, name }) => (
                        <List.Item>
                            <Link
                                to={apiService.getRelativeUrl(
                                    apiService.getProductUrl(id)
                                )}
                            >
                                {name}
                            </Link>
                        </List.Item>
                    )}
                />
                <br />
                <List
                    header={
                        <div>
                            Product Option drafts (
                            {drafts.productOptions.length})
                        </div>
                    }
                    bordered={true}
                    dataSource={drafts.productOptions}
                    renderItem={({ id, name, productCatalogId }) => (
                        <List.Item>
                            <Link
                                to={apiService.getRelativeUrl(
                                    apiService.getProductOptionUrl(
                                        productCatalogId,
                                        id
                                    )
                                )}
                            >
                                {name}
                            </Link>
                        </List.Item>
                    )}
                />
            </>
        ) : (
            <Result status="info" title="There are currently no drafts" />
        )

    return (
        <div>
            <Divider orientation="center">Catalog Overview</Divider>

            <Row gutter={[12, 24]} className={'row-gap-24'}>
                <Col flex={1}>
                    <Descriptions title="Totals" bordered={true}>
                        <Descriptions.Item label="Products">
                            {totals.products}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={
                                <Tooltip title="Includes both localized and non-localized totals">
                                    <span>Product Options (all)</span>
                                </Tooltip>
                            }
                        >
                            {totals.productOptions}
                        </Descriptions.Item>
                        <Descriptions.Item label="Price Options">
                            {totals.priceOptions}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Options (non-localized)">
                            {getProductOptionType.default.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Options (localized)">
                            {getProductOptionType.localized.length}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row gutter={[12, 24]} className={'row-gap-24'}>
                <Col flex={1}>
                    <Descriptions title="Drafts" bordered={true}>
                        <Descriptions.Item label="Products">
                            {drafts.products.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Product Options">
                            {drafts.productOptions.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Price Options">
                            <Tooltip title="Price Option drafts TBD">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Detail detail={detail} />
        </div>
    )
}
