import classNames from 'classnames'
import React from 'react'
import styles from './HorizontalList.module.scss'

type Props = {
    className?: string
    [key: string]: any
}

export const HorizontalList: React.FC<Props> = ({
    children,
    className,
    ...props
}) => {
    return (
        <div className={classNames(styles.container, className)} {...props}>
            {React.Children.map(children, (child: React.ReactNode) => child)}
        </div>
    )
}
