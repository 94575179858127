import { Card, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { Timestamp } from '../../../components/timestamp/Timestamp'
import config from '../../../config'
import {
    selectContentLibraryApiMeta,
    selectContentLibraryById,
} from '../../../store/content-libraries/content-libraries.selectors'
import { actions } from '../../../store/root-action'
import { Product } from '../../../store/typings/product.interface'
import { ContentLibraryActions } from './ContentLibraryActions'
import styles from './ContentLibraryDisplay.module.scss'
import { ContentLibraryError } from './ContentLibraryError'

interface Props {
    library: Product.ContentLibrary
    product: Product.AllFields
}

export const ContentLibraryDisplay: React.FC<Props> = ({
    library,
    ...props
}) => {
    const dispatch = useDispatch()

    const fullLibrary = useSelector((state: RootState) => ({
        ...library,
        ...selectContentLibraryById(state, library.id),
    }))

    const { error } = useSelector((state: RootState) =>
        selectContentLibraryApiMeta(state, library.id)
    )

    useEffect(() => {
        dispatch(actions.contentLibraries.get.request(library.id))
    }, [dispatch, library.id])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(
        () => () => {
            dispatch(actions.contentLibraries.clearErrorFor(library.id))
        },
        []
    )

    if (error) {
        return (
            <ContentLibraryError
                library={fullLibrary}
                error={error}
                {...props}
            />
        )
    }

    if (!fullLibrary.title) {
        return (
            <Card className={styles.container}>
                <Skeleton active />
            </Card>
        )
    }

    return (
        <Card
            className={styles.container}
            title={
                <>
                    <div className={styles.title}>
                        <LinkButton
                            target="_blank"
                            rel="noreferrer"
                            href={`${config.contentLibrariesUri}/${fullLibrary.id}`}
                            className={styles.contentLibraryLink}
                        >
                            {fullLibrary.title}
                        </LinkButton>
                        <ContentLibraryActions
                            library={fullLibrary}
                            {...props}
                        />
                    </div>
                    <HorizontalList className={styles.dateContainer}>
                        <Timestamp
                            label="Created"
                            timestamp={fullLibrary.createdAt}
                        ></Timestamp>
                        <Timestamp
                            label="Modified"
                            timestamp={fullLibrary.updatedAt}
                        ></Timestamp>
                        <Timestamp
                            label="Assigned"
                            timestamp={fullLibrary.assignedTimestamp}
                        ></Timestamp>
                    </HorizontalList>
                </>
            }
        >
            <div className={styles.description}>
                <span className={styles.descriptionPrefix}>Description: </span>
                {fullLibrary.description}
            </div>
        </Card>
    )
}
