import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    InfoCircleFilled,
} from '@ant-design/icons'
import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import classNames from 'classnames'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectAlerts } from '../../store/alerts/alerts.selectors'
import store from '../../store/configure-store'
import { actions } from '../../store/root-action'
import { AlertEnum } from '../../store/typings/alert.enum'
import { Alert } from '../../store/typings/alert.interface'
import styles from './Notifications.module.scss'

export const Notifications: React.FC = () => {
    const openNotification = (args: ArgsProps) => {
        const { className, ...notify } = args

        notification.open({
            ...notify,
            className: classNames(styles.notification, className),
        })
    }

    const parseDetail = (
        alert:
            | Alert.Fail
            | Alert.Success
            | Alert.Info
            | { message: string; details?: any[] }
    ): string | undefined => {
        return (
            (alert.details &&
                alert.details
                    .map((detail: any) => detail.message)
                    .join(', ')) ||
            null
        )
    }

    const notifySuccess = useCallback(
        (alert: Alert.Success | { message: string; details?: any[] }) => {
            const description = parseDetail(alert)
            openNotification({
                ...alert,
                className: styles.successNotification,
                description,
                // TODO: Explore options for making this configurable
                duration: !description?.includes('Slack') ? 3 : 0,
                icon: <CheckCircleFilled />,
            })
        },
        []
    )

    const notifyFailure = useCallback(
        (alert: Alert.Fail | { message: string; details?: any[] }) => {
            openNotification({
                ...alert,
                className: styles.failureNotification,
                description: parseDetail(alert),
                duration: 0, // Persists open
                icon: <ExclamationCircleFilled />,
            })
        },
        []
    )

    const notifyInfo = useCallback(
        (alert: Alert.Info | { message: string; details?: any[] }) => {
            openNotification({
                ...alert,
                className: styles.infoNotification,
                description: parseDetail(alert),
                duration: 10,
                icon: <InfoCircleFilled />,
            })
        },
        []
    )

    const notify = useCallback(
        (alert: Alert.Success | Alert.Fail | Alert.Info) => {
            const commonAlert = {
                ...alert,
                key: alert.id,
                onClose: () => {
                    store.dispatch(actions.alerts.remove(alert.id))
                },
            }

            if (alert.type === AlertEnum.Type.Success) {
                notifySuccess(commonAlert)
            } else if (alert.type === AlertEnum.Type.Info) {
                notifyInfo(commonAlert)
            } else {
                notifyFailure(commonAlert)
            }
        },
        [notifyFailure, notifySuccess, notifyInfo]
    )

    const notifications = useSelector(selectAlerts)

    useEffect(() => {
        notifications.forEach(notify)
    }, [notifications, notify])

    // Cleans up notifications on unmount ONLY
    useEffect(() => {
        return () => {
            notification.destroy()
        }
    }, [])

    return null
}
