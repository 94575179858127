import { Radio, Select as AntSelect } from 'antd'
import Form, { FormItemProps, Rule } from 'antd/lib/form'
import React, { ReactNode } from 'react'
import { ProductOption } from '../../store/typings/product-option.interface'
import styles from './ProductOptionForm.module.scss'

export const FormItem: React.FC<FormItemProps> = ({ children, ...props }) => {
    return (
        <>
            <Form.Item colon={false} {...props}>
                {children}
            </Form.Item>
            <div className={styles.fieldDivider} />
        </>
    )
}

export const RequiredFormItem: React.FC<FormItemProps> = ({
    rules,
    ...props
}: FormItemProps) => {
    const requiredRule = { message: 'This field is required', required: true }

    let allRules: Rule[] = [requiredRule]
    if (rules) {
        allRules = allRules.concat(rules)
    }

    return <FormItem rules={allRules} {...props} />
}

export const OptionalFormItem: React.FC<FormItemProps> = ({
    children,
    ...props
}) => (
    <>
        <Form.Item colon={false} {...props}>
            {children}
        </Form.Item>
        <div className={styles.fieldDivider} />
    </>
)

type ConditionalFormItemProps = {
    compareFn(relatedValue: string): boolean
    component: ReactNode
    relatedField: keyof ProductOption.FormFields
} & FormItemProps

export const ConditionalFormItem: React.FC<ConditionalFormItemProps> = ({
    compareFn,
    component,
    relatedField,
}) => {
    return (
        <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
                prev[relatedField] !== curr[relatedField]
            }
        >
            {({ getFieldValue }) =>
                compareFn(getFieldValue(relatedField)) ? component : null
            }
        </Form.Item>
    )
}

export const TrueFalse: React.FC<any> = (props) => (
    <Radio.Group {...props}>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
    </Radio.Group>
)

export const Select: React.FC<any> = ({ options, ...props }) => (
    <AntSelect size="large" allowClear {...props}>
        {options.map((option: string) => (
            <AntSelect.Option key={option} value={option}>
                {option}
            </AntSelect.Option>
        ))}
    </AntSelect>
)
