import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import {
    selectOptionFilterData,
    selectOptionFilterDataByProductId,
    selectProductOptionsById,
    selectProductOptionsByProductId,
} from '../../store/product-options/product-options.selectors'
import {
    selectNewAndExistingProducts,
    selectProduct,
} from '../../store/products/products.selectors'
import { isDraft } from '../../store/utils'

export function useAuditData() {
    const products = useSelector((state: RootState) =>
        selectNewAndExistingProducts(state).sort((a, b) =>
            a.name.localeCompare(b.name)
        )
    )
    const getProductById = useSelector(
        (state: RootState) => (productId: string) =>
            selectProduct(state, productId)
    )
    const productOptionsById = useSelector((state: RootState) =>
        selectProductOptionsById(state)
    )
    const getProductOptionsByProductId = useSelector(
        (state: RootState) => (productId: string) =>
            selectProductOptionsByProductId(state, productId)
    )
    const getProductOptionById = (productOptionId: string) =>
        productOptionsById[productOptionId]
    const getProductOptionTypeByProductId = useSelector(
        (state: RootState) => (productId: string) =>
            selectOptionFilterDataByProductId(state, productId)
    )
    const getProductOptionType = useSelector((state: RootState) =>
        selectOptionFilterData(state)
    )

    const productOptions = Object.values(productOptionsById)
        .flatMap((o) => o.payload)
        .sort((a, b) => a.name.localeCompare(b.name))

    const overview = {
        drafts: {
            products: products.filter(isDraft),
            productOptions: productOptions.filter(isDraft),
        },
        totals: {
            products: products.length,
            productOptions: productOptions.length,
            priceOptions: productOptions.flatMap((o) => o.priceOptions).length,
        },
    }

    return {
        getProductById,
        getProductOptionById,
        getProductOptionsByProductId,
        getProductOptionType,
        getProductOptionTypeByProductId,
        overview,
        products,
        productOptions,
    }
}
