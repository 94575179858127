import { put, takeEvery } from 'redux-saga/effects'
import { getType, RootAction } from 'typesafe-actions'
import { actions } from '../root-action'

export function* addAlertSuccessSaga(action: RootAction) {
    yield put(
        actions.alerts.addSuccess({
            message: (action.payload as any).message || 'Success!',
        })
    )
}

export function* addAlertFailSaga(action: any) {
    const error = action.payload.error || action.payload

    const defaultMessage = 'Something went wrong. '

    yield put(
        actions.alerts.addFail({
            details: error.details,
            message: error.message ?? defaultMessage,
        })
    )
}

export function* alertsWatcher() {
    yield takeEvery(
        [
            getType(actions.currency.createPreferred.success),
            getType(actions.currency.updatePreferred.success),
            getType(actions.products.create.success),
            getType(actions.products.update.success),
            getType(actions.products.assignLibrary.success),
            getType(actions.products.unassignLibrary.success),
            getType(actions.products.clone.success),
            getType(actions.products.exportProductOptions.success),
            getType(actions.products.toggleFeatures.success),
            getType(actions.productOptions.create.success),
            getType(actions.productOptions.update.success),
            getType(actions.productOptions.startImport.success),
            getType(actions.productOptions.submitImport.success),
            getType(actions.adhoc.runDvsBackfill.success),
        ],
        addAlertSuccessSaga
    )

    yield takeEvery(
        [
            getType(actions.currency.createPreferred.failure),
            getType(actions.currency.updatePreferred.failure),
            getType(actions.products.create.failure),
            getType(actions.products.update.failure),
            getType(actions.products.assignLibrary.failure),
            getType(actions.products.unassignLibrary.failure),
            getType(actions.products.clone.failure),
            getType(actions.products.exportProductOptions.failure),
            getType(actions.products.toggleFeatures.failure),
            getType(actions.productOptions.create.failure),
            getType(actions.productOptions.update.failure),
            getType(actions.productOptions.startImport.failure),
            getType(actions.productOptions.submitImport.failure),
            getType(actions.adhoc.runDvsBackfill.failure),
            getType(actions.connectedProduct.create.failure),
        ],
        addAlertFailSaga
    )
}
