import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import { Modal } from 'antd'
import { startCase } from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { LinkButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import apiService from '../../../services/api.service'
import { actions } from '../../../store/root-action'
import { PreferredCurrency } from '../../../store/typings/preferred-currency.interface'

interface Props {
    record: PreferredCurrency
    editedValue: any
    stopEditing: () => void
    editing: boolean
}

export const CurrencyAction: React.FC<Props> = ({
    record,
    editing,
    stopEditing,
    editedValue,
}) => {
    const dispatch = useDispatch()

    const confirmStatusToggle = () => {
        const statusVerb = record.active ? 'deactivate' : 'activate'
        const { name, ...dto } = record

        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: `Yes, ${statusVerb}`,
            title: `${startCase(statusVerb)}?`,
            content: (
                <p>
                    Are you sure you want to {statusVerb} the preferred currency
                    for <b>{record.name}</b>?
                </p>
            ),
            onOk() {
                return apiService
                    .updatePreferredCurrency({
                        ...dto,
                        active: record.active ? false : true,
                    })
                    .then((updated: PreferredCurrency) =>
                        dispatch(
                            actions.currency.updatePreferred.success({
                                data: updated,
                                message: `Preferred currency for ${record.name} has been ${statusVerb}d.`,
                            })
                        )
                    )
                    .catch((error: any) =>
                        dispatch(
                            actions.currency.updatePreferred.failure(error)
                        )
                    )
            },
        })
    }

    if (editing) {
        return (
            <HorizontalList>
                <LinkButton
                    disabled={!editedValue || editedValue === record.currency}
                    icon={<SaveOutlined />}
                    onClick={() => {
                        dispatch(
                            actions.currency.updatePreferred.request({
                                ...record,
                                currency: editedValue,
                            })
                        )
                        stopEditing()
                    }}
                    size="normal"
                >
                    Save
                </LinkButton>
                <LinkButton
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        stopEditing()
                    }}
                    size="normal"
                >
                    Cancel
                </LinkButton>
            </HorizontalList>
        )
    }

    const btnText = record.active ? 'Deactivate' : 'Activate'
    const btnIcon = record.active ? (
        <CloseCircleOutlined />
    ) : (
        <CheckCircleOutlined />
    )

    return (
        <LinkButton icon={btnIcon} onClick={confirmStatusToggle} size="normal">
            {btnText}
        </LinkButton>
    )
}
