import { Typography } from 'antd'
import React from 'react'
import { config } from '../../../config'
import styles from './SfValueButton.module.scss'

const { Link } = Typography

type Props = {
    sfId: string
}
export const SfValueButton = ({ sfId }: Props) => (
    <Link
        className={styles.label}
        href={`${config.sfUri}/${sfId}`}
        target="_blank"
    >
        {sfId}
    </Link>
)
