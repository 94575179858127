import { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import DatePicker from '../../components/date-picker/DatePicker'
import {
    lastMonth,
    lastQuarter,
    lastYear,
    thisQuarter,
    thisYear,
} from './change-log-service'
import styles from './ChangeLog.module.scss'
import { DownloadButton } from './DownloadButton'

type AntDate = Dayjs | null
export type Presets = 'audit'
export type AntRange = [AntDate, AntDate]

export interface ChangelogDto {
    start?: string
    end?: string
    preset?: Presets
}

export const ChangeLog: React.FC = () => {
    const [auditRange, setAuditRange] = useState<AntRange>([null, null])

    return (
        <>
            <Helmet>
                <title>Change Log - Product Catalog</title>
            </Helmet>
            <div className={styles.container}>
                <div className={styles.title}>Export change log data</div>
                <div className={styles.subTitle}>Date range (UTC)</div>

                <div>
                    <DatePicker.RangePicker
                        onChange={(rng) =>
                            setAuditRange(rng ? [rng[0], rng[1]] : [null, null])
                        }
                        ranges={{
                            'Last Month': lastMonth(),
                            'Last Quarter': lastQuarter(),
                            'Last Year': lastYear(),
                            'This Quarter': thisQuarter(),
                            'This Year': thisYear(),
                        }}
                    />
                </div>
                <div className={styles.btnGroup}>
                    <DownloadButton
                        auditRange={auditRange}
                        btnTitle={'Export all changes'}
                        preset={'audit'}
                    />
                </div>
            </div>
        </>
    )
}
