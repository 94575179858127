export const strings = {
    assignFeatureBtn: 'Assign features',
    assignFeatureSubmitBtn: 'Assign',
    assignLibraryBtn: 'Assign content library',
    assignLibrarySubmitBtn: 'Assign',
    associatedProductOption: 'Associated product option',
    category: 'Category',
    confirmSyncBtn: 'Yes, sync',
    createOptionBtn: 'Create new product option',
    createOptionFormTitle: 'Create a new product option',
    createPreferredCurrencyFormName: 'create_preferred_currency',
    createPreferredCurrencyFormTitle: 'Add new preferred currency',
    createProductBtn: 'Create new product',
    createProductFormName: 'create_product',
    createProductFormTitle: 'Create a new product',
    createSubmitBtn: 'Save as draft',
    customerFacingDescription: 'Customer facing description',
    customerType: 'Customer type',
    editBtn: 'Edit',
    editProductFormName: 'edit_product',
    editProductFormTitle: 'Edit product',
    editProductOptionFormTitle: 'Edit product option',
    editSubmitBtn: 'Save',
    filterCountryLabel: 'Country',
    filterCurrencyLabel: 'Currency',
    filterNamePlaceholder: 'Search by name or ID',
    filterNameSkuIDPlaceholder: 'Search by name, SKU, or ID',
    filterResults: 'Filtered results',
    filterResultsAll: 'All results',
    filterStatusLabel: 'Status',
    filterTagLabel: 'Tags',
    formCancelBtn: 'Cancel',
    formSubmitBtn: 'Save draft',
    groups: 'Groups',
    isUserAssignable: 'Is user assignable',
    level: 'Level',
    libraryScope: 'Library type',
    marketingTestIDFilter: 'Marketing test ID',
    name: 'Name',
    noTestingID: 'No testing ID',
    preserveProvisionedTerm: 'Preserve provisioned term',
    productOptionFormName: 'product_option',
    productOptionDrawerFormName: 'product_option_drawer',
    productType: 'Product type',
    removeLibraryBtn: 'Remove',
    resetFiltersBtn: 'Reset filters',
    search: 'Search',
    sku: 'SKU',
    status: 'Status',
    syncBtn: 'Sync to Zuora',
    taxCode: 'Tax Code',
    viewPoDetails: 'View product option details',
    createConnectedProductFormTitle: 'Add new connected product',
    editConnectedProductFormTitle: 'Edit new connected product',
    productName: 'Product Name',
}

export const formNames = {
    category: 'category',
    countryCode: 'countryCode',
    customerFacingDescription: 'customerFacingDescription',
    customerType: 'customerType',
    groups: 'groups',
    isUserAssignable: 'isUserAssignable',
    level: 'level',
    libraryScope: 'libraryScope',
    name: 'name',
    preserveProvisionedTerm: 'preserveProvisionedTerm',
    sku: 'sku',
    type: 'type',
}

export const testIds = {
    priceDefault: 'price-default',
    priceOther: 'price-other',
    productForm: 'product-form',
    productFormFooter: 'product-form-footer',
    productHeader: 'product-header',
    productLevel: 'product-level',
    productModified: 'product-modified',
    productOptionForm: 'product-option-form',
    productOptions: 'product-options',
    productOptionStatus: 'product-option-status',
    productStatus: 'product-status',
}

export const socketCodes = {
    NORMAL_CLOSURE: 1000,
    ABNORMAL_CLOSURE: 1006,
}
