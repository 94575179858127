import { ProductFilters } from '../../store/filters/filters.reducer'
import { Product } from '../../store/typings/product.interface'

export const filterProducts = (
    data: Product.AllFields[],
    filters: ProductFilters
) => filterByType(data, filters)

export const filterByType = (data: any[], filters: any) =>
    data.filter((product) =>
        Object.keys(filters).every((key) => {
            const value = product[key]
            const filter = filters[key]

            if (!filter?.length) {
                return true // passing an empty filter means that filter is ignored.
            }
            if (key === 'state') {
                return (
                    filter.includes(product.draft?.meta.state) ||
                    filter.includes(product.meta.state)
                )
            }
            if (key === 'name') {
                return (
                    value.toLowerCase().includes(filter.toLowerCase()) ||
                    product.sku.toLowerCase().includes(filter.toLowerCase()) ||
                    product.id.includes(filter) // For the devs <3
                )
            }

            return filter.includes(value)
        })
    )
