import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { CommonEnum } from '../typings/common.enum'
import { LocalizationEnum } from '../typings/localization.enum'
import { ProductOptionEnum } from '../typings/product-option.enum'
import { ProductEnum } from '../typings/product.enum'

export enum Filters {
    Localization = 'localization',
    LocalizedProductOption = 'localizedProductOption',
    Product = 'product',
    ProductOption = 'productOption',
}

export interface ProductFilters {
    category: ProductEnum.Category[]
    customerType: ProductEnum.CustomerType[]
    name: string
    state: ProductOptionEnum.State[]
    type: ProductEnum.Type[]
}

export interface ProductOptionFilters {
    name: string
    state: ProductOptionEnum.State[]
    tags: ProductOptionEnum.Tags[]
}

export interface LocalizedProductOptionFilters {
    associatedProductOption: string[]
    country: string[] // TODO: Country ISO ENUM?
    name: string
    state: ProductOptionEnum.State[]
    tags: ProductOptionEnum.Tags[]
    testId: string[]
}

export interface LocalizationFilters {
    country: string[]
    currency: string[]
    status: LocalizationEnum.Status[]
}

export interface FiltersByType {
    [Filters.Localization]: LocalizationFilters
    [Filters.LocalizedProductOption]: LocalizedProductOptionFilters
    [Filters.Product]: ProductFilters
    [Filters.ProductOption]: ProductOptionFilters
}

export type FiltersDefaultState = {
    [key in Filters]: FiltersByType[key]
}

const defaultStatus = [
    CommonEnum.State.Active,
    CommonEnum.State.DraftExisting,
    CommonEnum.State.DraftNew,
]
export const defaultState: FiltersDefaultState = {
    [Filters.Localization]: {
        country: [],
        currency: [],
        status: [],
    },

    [Filters.LocalizedProductOption]: {
        associatedProductOption: [],
        country: [],
        name: '',
        state: defaultStatus,
        tags: [],
        testId: [],
    },

    [Filters.Product]: {
        category: [],
        customerType: [],
        name: '',
        state: defaultStatus,
        type: [],
    },

    [Filters.ProductOption]: {
        name: '',
        state: defaultStatus,
        tags: [],
    },
}

export const productReducer = createReducer(defaultState.product).handleAction(
    actions.filters.filterProduct,
    (state, action) => action.payload
)

export const productOptionReducer = createReducer(
    defaultState.productOption
).handleAction(
    actions.filters.filterProductOption,
    (state, action) => action.payload
)

export const localizedProductOptionReducer = createReducer(
    defaultState.localizedProductOption
).handleAction(
    actions.filters.filterLocalizedProductOption,
    (state, action) => action.payload
)

export const localizationReducer = createReducer(
    defaultState.localization
).handleAction(
    actions.filters.filterLocalization,
    (state, action) => action.payload
)

export const filtersReducer = combineReducers({
    [Filters.Localization]: localizationReducer,
    [Filters.LocalizedProductOption]: localizedProductOptionReducer,
    [Filters.Product]: productReducer,
    [Filters.ProductOption]: productOptionReducer,
})

// Redux Types
export type FiltersAction = ActionType<typeof actions.filters>
export type FiltersState = StateType<typeof filtersReducer>
