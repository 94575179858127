import { LeftOutlined } from '@ant-design/icons'
import React from 'react'
import { Link } from 'react-router-dom'

export const BackToTools: React.FC = () => {
    return (
        <>
            <Link to={`/tools`} title="Admin tools">
                <LeftOutlined /> Back to tools
            </Link>
            <br />
        </>
    )
}
