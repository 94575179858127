import { createAsyncAction } from 'typesafe-actions'
import { ApiSuccess } from '../root-action'
import { ConnectedProduct } from '../typings/connected-products.interface'

type AllFields = ConnectedProduct.AllFields
type CreateRequest = ConnectedProduct.CreateRequest
type AllFieldSuccess = ApiSuccess<AllFields>

export const create = createAsyncAction(
    'connected_product/CREATE_REQUEST',
    'connected_product/CREATE_SUCCESS',
    'connected_product/CREATE_FAILURE'
)<CreateRequest, AllFieldSuccess, Error>()
