import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import styles from './Authentication.module.scss'

const LoadingIcon: React.FC = () => (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 26 }} spin />} />
)

export const LoginLoader: React.FC = () => (
    <div className={styles.container}>
        <span className={styles.loader}>
            <LoadingIcon />
            {/* <h2>Authenticating...</h2> */}
        </span>
    </div>
)
