import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import {
    CancelButton,
    PrimaryButton,
} from '../../../components/buttons/Buttons'
import { actions } from '../../../store/root-action'
import { Common } from '../../../store/typings/common.interface'
import { Product } from '../../../store/typings/product.interface'
import { FeatureListItem, FeaturesList } from './FeaturesList'

interface Props {
    closeEdit: () => void
    features: FeatureListItem[]
    product: Common.ApiItem<Product.AllFields>
}

enum Actions {
    Assign = 'ASSIGN',
    Remove = 'REMOVE',
}

export interface FeatureState {
    [featureId: string]: boolean
}

export const FeaturesEdit: React.FC<Props> = ({
    closeEdit,
    features,
    product,
}) => {
    const dispatch = useDispatch()
    const featureMap = features.reduce(
        (acc, feature) => ({
            ...acc,
            [feature.id]: !!feature.assignedTimestamp,
        }),
        {}
    )

    const reducer = (
        state: FeatureState,
        action: { type: Actions; id: string }
    ) => {
        switch (action.type) {
            case Actions.Assign:
                return { ...state, [action.id]: true }
            case Actions.Remove:
                return { ...state, [action.id]: false }
            default:
                return state
        }
    }

    const [featureState, toggleFeatureState] = useReducer(reducer, featureMap)

    const sendFeaturesRequest = () => {
        dispatch(
            actions.products.toggleFeatures.request({
                id: product.payload.id,
                features: Object.entries(featureState).map(
                    ([id, assigned]) => ({ id, assigned })
                ),
            })
        )
    }

    return (
        <div>
            <FeaturesList
                isSaving={product.isSaving}
                toggleState={featureState}
                features={features}
                onToggle={(value: boolean, id: string) =>
                    toggleFeatureState({
                        type: value ? Actions.Assign : Actions.Remove,
                        id,
                    })
                }
            />
            <div style={{ display: 'flex', marginRight: '12px' }}>
                <PrimaryButton
                    onClick={sendFeaturesRequest}
                    disabled={product.isSaving}
                >
                    Save Changes
                </PrimaryButton>
                <CancelButton onClick={closeEdit} disabled={product.isSaving} />
            </div>
        </div>
    )
}
