import { SecureRoute } from '@okta/okta-react'
import React from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { rules } from '../Authentication/permissions/ability'
import { ChangeLog } from '../containers/changelog/ChangeLog'
import { withHeaderAndFooter } from '../containers/hoc/withHeaderAndFooter'
import { Localization } from '../containers/localization/Localization'
import { Navbar } from '../containers/navbar/Navbar'
import { Products } from '../containers/products/Products'
import { AbilityRoute } from '../containers/router/AbilityRoute'
import { Tools } from '../containers/tools/Tools'
import { ProductDetailsRouter } from './ProductDetailsRouter'

export const HomeRouter: React.FC = withHeaderAndFooter(() => {
    const location = useLocation()

    if (location && location.pathname === '/') {
        return <Redirect to="/products" />
    }

    return (
        <>
            <Navbar />
            <Switch>
                <SecureRoute exact path="/products" component={Products} />
                <SecureRoute
                    path="/products/:productId"
                    render={({ match }) => (
                        <ProductDetailsRouter key={match?.params.productId} />
                    )}
                />
                <SecureRoute path="/change-log" component={ChangeLog} />
                <AbilityRoute
                    path="/tools"
                    component={Tools}
                    rule={rules.viewTool}
                />
                <SecureRoute path="/localization" component={Localization} />
                <Redirect to="/products" />
            </Switch>
        </>
    )
})
