import { Drawer, Form, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton, SubmitButton } from '../../../components/buttons/Buttons'
import { strings } from '../../../constants'
import { SupportedCurrencyDetail } from '../../../store/currency/currency.reducer'
import { selectProductOptionFieldOptions } from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { CurrencyEnum } from '../../../store/typings/currency.enum'
import styles from './CreatePreferredCurrency.module.scss'

type FormFields = {
    country: string
    currency: CurrencyEnum.Code
}

type Props = {
    closeForm: () => void
    visible: boolean
    // countries: ProductOption.CountryOption[]
    currencies: SupportedCurrencyDetail[]
}

export const CreatePreferredCurrency = ({
    closeForm,
    visible,
    currencies,
}: Props) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    // Ant suggests doing this as part of its solution for disabling submit button on load: https://ant.design/components/form/#components-form-demo-inline-login
    // eslint-disable-next-line
    const [_, forceUpdate] = useState<{}>()

    useEffect(() => {
        forceUpdate({})
    }, [])

    const isSubmitDisabled = useCallback((): boolean => {
        const hasVisitedFields = form.isFieldsTouched(
            ['country', 'currency'],
            true
        )

        const hasErrors =
            form.getFieldsError().flatMap(({ errors }) => errors).length > 0

        return !hasVisitedFields || hasErrors
    }, [form])

    const { countries } = useSelector(selectProductOptionFieldOptions)
    const countryOptions = countries.map((c) => ({
        label: c.displayName,
        value: c.displayName,
    }))

    const handleFormSubmit = ({ country, currency }: FormFields) => {
        dispatch(
            actions.currency.createPreferred.request({
                active: false,
                country: country.split('-')[1].trim(),
                currency,
            })
        )
        closeForm()
    }

    return (
        <Drawer
            destroyOnClose={true}
            drawerStyle={{ height: '100%' }}
            onClose={closeForm}
            title={strings.createPreferredCurrencyFormTitle}
            visible={visible}
            width={800}
        >
            <Form
                className={styles.preferredCurrencyForm}
                form={form}
                hideRequiredMark
                layout="vertical"
                name={strings.createPreferredCurrencyFormName}
                onFinish={handleFormSubmit}
            >
                <Form.Item
                    preserve={false}
                    name="country"
                    label="Country"
                    rules={[
                        {
                            required: true,
                            message: 'Country is required',
                        },
                    ]}
                >
                    <Select size="large" showSearch options={countryOptions} />
                </Form.Item>
                <Form.Item
                    preserve={false}
                    name="currency"
                    label="Currency"
                    rules={[
                        {
                            required: true,
                            message: 'Currency is required',
                        },
                    ]}
                >
                    <Select
                        size="large"
                        showSearch
                        options={currencies.map((c) => ({
                            label: c.displayName,
                            value: c.code,
                        }))}
                    />
                </Form.Item>
                <div className={styles.actions}>
                    <Form.Item noStyle shouldUpdate>
                        {() => (
                            <SubmitButton disabled={isSubmitDisabled()}>
                                {strings.createSubmitBtn}
                            </SubmitButton>
                        )}
                    </Form.Item>
                    <CancelButton onClick={closeForm} />
                </div>
            </Form>
        </Drawer>
    )
}
