import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import {
    selectOptionFilterDataByProductId,
    selectProductOptionsById,
} from '../../store/product-options/product-options.selectors'
import styles from './AssociatedOptionFilter.module.scss'

interface RouteParams {
    productId: string
}

export const AssociatedOptionFilter: React.FC<SelectProps<any>> = (props) => {
    const { productId } = useParams<RouteParams>()

    const optionIds = useSelector((state: RootState) =>
        selectOptionFilterDataByProductId(state, productId)
    )
    const optionsById = useSelector((state: RootState) =>
        selectProductOptionsById(state)
    )

    const localizedAssociatedOptions: string[] = []

    optionIds.localized.forEach(({ id }) => {
        const { defaultRatePlanCountryId } = optionsById[id].payload

        if (defaultRatePlanCountryId)
            localizedAssociatedOptions.push(defaultRatePlanCountryId)
    })

    const uniqueWithLabel = [
        ...Array.from(new Set(localizedAssociatedOptions)).map((id) => {
            const { name } = optionsById[id].payload
            return { label: name, value: id }
        }),
    ]

    return (
        <Select
            className={styles.associated}
            allowClear
            filterOption={(inputValue, option) =>
                (typeof option?.label === 'string' &&
                    option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())) ??
                true
            }
            mode="multiple"
            options={uniqueWithLabel}
            placeholder="All product options"
            showArrow
            showSearch
            size="large"
            {...props}
        />
    )
}
