import { WarningFilled } from '@ant-design/icons'
import { Card, Result } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import config from '../../../config'
import { Product } from '../../../store/typings/product.interface'
import { ContentLibraryActions } from './ContentLibraryActions'
import styles from './ContentLibraryDisplay.module.scss'

interface Props {
    library: Product.ContentLibrary
    product: Product.AllFields
    error: Error
}

export const ContentLibraryError: React.FC<Props> = ({ error, ...props }) => {
    return (
        <Card
            className={styles.container}
            title={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ContentLibraryActions {...props} />
                </div>
            }
            headStyle={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}
        >
            <Result
                className={classNames(styles.error)}
                status="warning"
                icon={<WarningFilled style={{ color: '#FAD000' }} />}
                title={`There was a problem loading this library`}
                subTitle={error.message}
                extra={
                    <PrimaryButton
                        href={config.contentLibrariesUri}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Go to Content Libraries
                    </PrimaryButton>
                }
            />
        </Card>
    )
}
