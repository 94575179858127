import { Select as AntSelect } from 'antd'
import React from 'react'
import { Input } from '../../components/form'
import { formNames } from '../../constants'
import { ProductOption } from '../../store/typings/product-option.interface'
import { FormItem } from './ProductOptionFields'

type Props = {
    countries: ProductOption.CountryOption[]
    currentCountry?: string | null
}

export class LocalizedInputs extends React.Component<Props> {
    render() {
        return (
            <div style={{ marginTop: '16px' }}>
                <FormItem
                    name={formNames.countryCode}
                    label="Country"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <AntSelect size="large" showSearch>
                        {this.props.countries.map(
                            (country: ProductOption.CountryOption) => {
                                return (
                                    <AntSelect.Option
                                        key={country.code}
                                        value={country.displayName}
                                    >
                                        {country.displayName}
                                    </AntSelect.Option>
                                )
                            }
                        )}
                    </AntSelect>
                </FormItem>
                <FormItem
                    name="defaultRatePlanCountryId"
                    label="Associated product option ID"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Input />
                </FormItem>
            </div>
        )
    }
}
