import { Typography } from 'antd'
import React from 'react'
import { AdminTools } from '../../../store/typings/admin-tools.interface'
import { BackToTools } from '../BackToTools'
import { AuditMissingContentLibraries } from './AuditMissingContentLibraries'
import { AuditMissingFeatures } from './AuditMissingFeatures'
import { AuditOverview } from './AuditOverview'
import styles from './Audits.module.scss'

const { Title, Paragraph } = Typography

type Props = AdminTools.Tool

export const Audits: React.FC<Props> = ({ description, title }) => {
    return (
        <div className={styles.container}>
            <BackToTools />

            <Title>{title}</Title>
            <Paragraph>{description}</Paragraph>

            <AuditOverview />
            <AuditMissingContentLibraries />
            <AuditMissingFeatures />
        </div>
    )
}
