import { PlusOutlined } from '@ant-design/icons'
import { Card, Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { ability } from '../../../Authentication/permissions/ability'
import { Can } from '../../../Authentication/permissions/Can'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { strings } from '../../../constants'
import {
    selectCurrencyByCountry,
    selectSupportedCurrencies,
} from '../../../store/currency/currency.selectors'
import { selectLocalizationFilters } from '../../../store/filters/filters.selectors'
import { CommonEnum } from '../../../store/typings/common.enum'
import { PreferredCurrency as ICountryCurrency } from '../../../store/typings/preferred-currency.interface'
import { Status } from '../../status/Status'
import { filterPreferredCurrency } from '../utils'
import { CreatePreferredCurrency } from './CreatePreferredCurrency'
import { CurrencyAction } from './CurrencyActions'
import { CurrencyCode } from './CurrencyCode'
import styles from './PreferredCurrency.module.scss'

const headStyle = {
    fontSize: '18px',
    fontWeight: 400,
    padding: '6px 24px',
}

export const PreferredCurrency: React.FC = () => {
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState('')
    const [selectValue, setSelectValue] = useState('')

    const byCountry = useSelector(selectCurrencyByCountry)
    const supportedCurrencies = useSelector(selectSupportedCurrencies)
    const filters = useSelector((state: RootState) =>
        selectLocalizationFilters(state)
    )
    const filtered = useMemo(
        () => filterPreferredCurrency(byCountry, filters),
        [byCountry, filters]
    )

    const filteredResults = () =>
        filtered.length !== byCountry.length
            ? strings.filterResults + ': ' + filtered.length
            : strings.filterResultsAll

    const columns: ColumnProps<ICountryCurrency>[] = [
        {
            dataIndex: 'active',
            sorter: (a, b) =>
                a.active.toString().localeCompare(b.active.toString()),
            title: 'STATUS',
            width: 140,
            render: (isActive: boolean) => (
                <Status
                    text={
                        isActive
                            ? CommonEnum.State.Active
                            : CommonEnum.State.Inactive
                    }
                />
            ),
        },
        {
            dataIndex: 'name',
            sorter: (a, b) =>
                (a.name && b.name && a.name.localeCompare(b.name)) || 0,
            title: 'COUNTRY NAME',
        },
        {
            dataIndex: 'country',
            sorter: (a, b) => a.country.localeCompare(b.country),
            title: 'COUNTRY CODE',
        },
        {
            dataIndex: 'currency',
            sorter: (a, b) => a.currency.localeCompare(b.currency),
            title: 'CURRENCY',
            width: 270,
            render: (value, record) => (
                <CurrencyCode
                    value={value}
                    onSelect={setSelectValue}
                    editing={editing === record.country}
                    startEdit={() => setEditing(record.country)}
                    stopEdit={() => setEditing('')}
                    currencies={supportedCurrencies}
                    country={record.country}
                />
            ),
        },
    ]

    if (ability.can('manage', 'Currency')) {
        columns.push({
            key: 'action',
            title: 'ACTION',
            width: 220,
            render: (_, record: ICountryCurrency) => (
                <CurrencyAction
                    record={record}
                    editing={editing === record.country}
                    stopEditing={() => {
                        setSelectValue('')
                        setEditing('')
                    }}
                    editedValue={selectValue}
                />
            ),
        })
    }

    return (
        <>
            <div className={styles.results}>{filteredResults()}</div>
            <Card
                title="Preferred currency by country"
                headStyle={headStyle}
                extra={
                    <Can I="manage" a="Currency">
                        <PrimaryButton
                            icon={<PlusOutlined />}
                            onClick={() => setCreating(true)}
                        >
                            Add New
                        </PrimaryButton>
                    </Can>
                }
            >
                <Table
                    columns={columns}
                    dataSource={filtered}
                    pagination={false}
                    className={styles.preferredCurrency}
                    scroll={{ x: 960 }}
                    rowKey="country"
                />
                <CreatePreferredCurrency
                    closeForm={() => setCreating(false)}
                    currencies={supportedCurrencies}
                    visible={creating}
                />
            </Card>
        </>
    )
}
