import React from 'react'
import { strings } from '../../../constants'
import {
    ConnectedProductForm,
    ConnectedProductFormProps,
} from './CxnProductForm'

export const CxnProductCreateForm: React.FC<ConnectedProductFormProps> = (
    props
) => {
    const { editForm } = props
    return (
        <ConnectedProductForm
            name="ConnectedProducts"
            submitText={strings.editSubmitBtn}
            title={
                editForm
                    ? strings.editConnectedProductFormTitle
                    : strings.createConnectedProductFormTitle
            }
            {...props}
        />
    )
}
