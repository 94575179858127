import { Badge, Button, Col, Row, Space, Table, Typography } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import React from 'react'
import { Link } from 'react-router-dom'
import { ProductInfo, SimpleInfo } from '../../product-options/cxn-po.interface'
import styles from './BaseProducts.module.scss'

const { Text } = Typography

interface Props {
    upgrade: SimpleInfo[]
    upsell: SimpleInfo[]
}

export const ProductNameForUpsell = () => (
    <Space>
        <Text className={styles.tableTitle}>Product Name for Upsell</Text>
    </Space>
)

const defaultTableProps: TableProps<ProductInfo | SimpleInfo> = {
    showSorterTooltip: false,
    showHeader: true,
    // bordered: true,
    size: 'middle',
    pagination: false,
    rowKey: (record) => record.id,
}

const makeUpgradeConnectionsColumn = (
    count: number
): ColumnType<SimpleInfo> => ({
    title: `UPGRADE (${count})`,
    width: 400,
    align: 'left',
    dataIndex: 'name',
    key: 1,
    render: (data) => (
        <Space key={data}>
            <Text className={styles.tableTitle}>{`${data}`}</Text>
        </Space>
    ),
})

const makeUpsellConnectionsColumn = (
    count: number
): ColumnType<SimpleInfo> => ({
    title: `UPSELL (${count})`,
    width: 400,
    align: 'left',
    dataIndex: 'name',
    key: 1,
    render: (data) => (
        <Space key={data}>
            <Text className={styles.tableTitle}>{`${data}`}</Text>
        </Space>
    ),
})

export const BaseProductsStatusCol = () => (
    <div>
        <Badge status={'success'} />
        <Text>Incomplete</Text>
    </div>
)

export const NotBaseProductsStatusCol = () => (
    <div>
        <Badge status={'error'} />
        <Text>Not Started</Text>
    </div>
)

export const ExpandProductCnx = (props: Props) => {
    return (
        <Row>
            <Col span={12}>
                <Table
                    {...defaultTableProps}
                    dataSource={props.upgrade}
                    columns={[
                        makeUpgradeConnectionsColumn(props.upgrade.length),
                    ]}
                />
            </Col>
            <Col span={12}>
                <Table
                    {...defaultTableProps}
                    dataSource={props.upsell}
                    columns={[makeUpsellConnectionsColumn(props.upsell.length)]}
                />
            </Col>
        </Row>
    )
}

export const BaseProductsLinkButtonCol = ({ id }: { id: string }) => (
    <>
        <Button type="link" className={styles.LinkBox}>
            Edit
        </Button>
        <span className={styles.verticalLine} />
        <Button type="link" className={styles.LinkBox}>
            Delete
        </Button>
        <span className={styles.verticalLine} />
        <Link to={`/tools/connected-products/${id}`} className={styles.LinkBox}>
            Configure
        </Link>
    </>
)
