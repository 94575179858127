import * as adhoc from './adhoc/adhoc.actions'
import * as alerts from './alerts/alerts.actions'
import * as connectedProduct from './connected-products/cxnProducts.actions'
import * as contentLibraries from './content-libraries/content-libraries.actions'
import * as currency from './currency/currency.actions'
import * as features from './features/features.actions'
import * as filters from './filters/filters.actions'
import * as forms from './forms/forms.actions'
import * as productOptions from './product-options/product-options.actions'
import * as products from './products/products.actions'

export interface ApiSuccess<T = any> {
    data: T
    message?: string
}

export const actions = {
    adhoc,
    alerts,
    contentLibraries,
    currency,
    features,
    filters,
    forms,
    productOptions,
    products,
    connectedProduct,
}
