import { PlusOutlined } from '@ant-design/icons'
import { Input, Select } from 'antd'
import Form, { FormItemProps as AntFormItemProps, Rule } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import { Section } from '../../../components/section/Section'
import { strings } from '../../../constants'
import { selectNewAndExistingProducts } from '../../../store/products/products.selectors'
import styles from './CxnProductForm.module.scss'

interface FormItemProps extends AntFormItemProps {
    options: any
    validators: any[]
}
const { Option } = Select
const InputGroup = Input.Group
export const FormItem: React.FC<FormItemProps> = ({
    children,
    name,
    ...formItemProps
}) => {
    return (
        <Form.Item
            name={name}
            validateFirst
            validateTrigger={['onChange', 'onBlur']}
            {...formItemProps}
        >
            {children}
        </Form.Item>
    )
}

export const RequiredFormItem: React.FC<FormItemProps> = ({
    rules,
    ...props
}: FormItemProps) => {
    let allRules: Rule[] = [{ message: 'This field is required' }]
    if (rules) {
        allRules = allRules.concat(rules)
    }
    return <FormItem rules={allRules} {...props} />
}

interface FormTypes {
    fromProduct: string
    toUpgradeProduct: string[]
    toUpsellProduct: string[]
}

export const ConnectedProducts = connect(
    (state: RootState) => ({
        products: selectNewAndExistingProducts(state),
    }),
    {}
)(({ onFormComplete, baseProducts, ...props }: any) => {
    const products =
        props?.products?.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
        ) ?? []

    const [selectedProductList, setSelectedProductList] = React.useState<
        string[]
    >([])
    const [selectedUpsellProductList, setSelectedUpsellProductList] =
        React.useState<string[]>([])
    const [connectedBaseProducts, setConnectedBaseProducts] = React.useState<
        string[]
    >([])
    const [form, setForm] = React.useState<FormTypes>({
        fromProduct: '',
        toUpgradeProduct: [],
        toUpsellProduct: [],
    })
    const [, setData] = useState({})
    const [newUpgradeProduct, setNewUpgradeProducts] = React.useState([
        { toUpgradeProduct: '' },
        { toUpgradeProduct: '' },
        { toUpgradeProduct: '' },
    ])
    const [newUpsellProduct, setNewUpsellProducts] = React.useState([
        { addProducts: '' },
        { addProducts: '' },
        { addProducts: '' },
    ])

    useEffect(() => {
        const baseProductIds: any = []
        baseProducts.forEach((element: any) => {
            baseProductIds.push(element.id)
        })
        setConnectedBaseProducts(baseProductIds)
    }, [baseProducts])

    useEffect(() => {
        onFormComplete(form)
    }, [form.fromProduct, form.toUpgradeProduct, form.toUpsellProduct])

    const handleAddProduct = (i: number) => {
        setNewUpgradeProducts([...newUpgradeProduct, { toUpgradeProduct: '' }])
    }

    const getListProductData = (data: any[], i: number) => {
        const productListData: any[] = []
        data.forEach((element, index) => {
            if (index !== i) {
                productListData.push(element)
            }
        })
        return productListData
    }

    const handleAddUpselProduct = (i: number) => {
        setNewUpsellProducts([...newUpsellProduct, { addProducts: '' }])
    }

    const onSelectBaseProduct = (value: string) => {
        form.fromProduct = value
        setForm(form)
        setData({})
    }

    const onUpgradeProductChange = (value: string, i: number) => {
        const upgradeProductListData = [...selectedProductList]
        upgradeProductListData[i] = value
        form.toUpgradeProduct = upgradeProductListData
        setForm(form)
        setSelectedProductList(upgradeProductListData)
        setNewUpgradeProducts(newUpgradeProduct)
    }

    const onUpsellProductChange = (value: string, i: number) => {
        const selectedUpsellProductListData = [...selectedUpsellProductList]
        selectedUpsellProductListData[i] = value
        form.toUpsellProduct = selectedUpsellProductListData
        setForm(form)
        setSelectedUpsellProductList(selectedUpsellProductListData)
        setNewUpsellProducts(newUpsellProduct)
    }
    return (
        <>
            <Section title={'Base Product'} />
            <RequiredFormItem
                name={'baseProduct'}
                {...props}
                className={styles.formContainer}
            >
                <InputGroup compact className={styles.selectInputContainer}>
                    <Select
                        className={styles.selectContainer}
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option &&
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value: string) => onSelectBaseProduct(value)}
                        placeholder="Product Name"
                        showArrow
                        showSearch
                        size="large"
                        allowClear
                    >
                        {products.map((item: any) => {
                            return (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            )
                        })}
                    </Select>
                </InputGroup>
            </RequiredFormItem>
            <Section title={'Upgrades'} />
            {newUpgradeProduct.map((x, i) => {
                return (
                    <div key={i}>
                        <RequiredFormItem
                            name={'upgradeProduct'}
                            label={strings.productName}
                            {...props}
                            className={styles.formContainer}
                        >
                            <InputGroup
                                compact
                                className={styles.selectInputContainer}
                            >
                                <Select
                                    className={styles.selectContainer}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option &&
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value: string) =>
                                        onUpgradeProductChange(value, i)
                                    }
                                    placeholder="Product Name"
                                    showArrow
                                    showSearch
                                    size="large"
                                    allowClear
                                >
                                    {products &&
                                        products.length &&
                                        products.map((item: any) => {
                                            return (
                                                <Option
                                                    key={item.id}
                                                    value={item.id}
                                                    disabled={
                                                        selectedProductList.length &&
                                                        selectedProductList.includes(
                                                            item.id
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {item.name}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </InputGroup>
                        </RequiredFormItem>
                    </div>
                )
            })}
            <LinkButton
                icon={<PlusOutlined />}
                className={styles.addConnected}
                onClick={() => handleAddProduct(newUpgradeProduct.length)}
            >
                Add another product
            </LinkButton>
            <Section title={'Upsells'} />
            {newUpsellProduct.map((x, i) => {
                return (
                    <div key={i}>
                        <RequiredFormItem
                            name={'upsellProduct'}
                            label={strings.productName}
                            {...props}
                            className={styles.formContainer}
                        >
                            <InputGroup
                                compact
                                className={styles.selectInputContainer}
                            >
                                <Select
                                    className={styles.selectContainer}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option &&
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value: string) =>
                                        onUpsellProductChange(value, i)
                                    }
                                    placeholder="Product Name"
                                    showArrow
                                    showSearch
                                    size="large"
                                    allowClear
                                >
                                    {products.map((item: any) => {
                                        return (
                                            <Option
                                                key={item.id}
                                                value={item.id}
                                                disabled={
                                                    selectedUpsellProductList.length &&
                                                    selectedUpsellProductList.includes(
                                                        item.id
                                                    )
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {item.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </InputGroup>
                        </RequiredFormItem>
                    </div>
                )
            })}
            <LinkButton
                icon={<PlusOutlined />}
                className={styles.addConnected}
                onClick={() => handleAddUpselProduct(newUpgradeProduct.length)}
            >
                Add another product
            </LinkButton>
        </>
    )
})
