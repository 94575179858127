import { Col, Row } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { History } from '../../../components/history/History'
import { strings, testIds } from '../../../constants'
import { ProductEnum } from '../../../store/typings/product.enum'
import { Product } from '../../../store/typings/product.interface'
import { Copy } from '../../copy/Copy'
import { Label } from '../components/Label'
import { Value } from '../components/Value'
import styles from './ProductContent.module.scss'

type Props = {
    product: Product.AllFields
}

export const ProductContent: React.FC<Props> = ({ product }) => {
    return (
        <>
            <span className={styles.title}>{product.name}</span>
            <div className={styles.meta}>
                <Row gutter={[0, 4]} className={'row-gap-4'}>
                    <Col>
                        <span className={styles.sku}>
                            {strings.sku}: {product.sku}{' '}
                            <Copy clipboard={product.sku} />
                        </span>
                    </Col>
                </Row>

                <History data={product} />

                <Row
                    gutter={[{ xs: 48, sm: 48, md: 48, lg: 80, xl: 96 }, 12]}
                    className={classNames(styles.fields, 'row-gap-12')}
                >
                    <Col>
                        <Label>{strings.category}</Label>
                        <Value>{product.category}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.productType}</Label>
                        <Value>{product.type}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.customerType}</Label>
                        <Value>{product.customerType}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.groups}</Label>
                        {/* If there are assigned groups to the product, then map them out
                        The array of enums need to be parsed in this way for them to be
                        represented as a React component array */}
                        {product.groups ? (
                            <Value>
                                {(
                                    Object.values(product.groups) as Array<
                                        keyof typeof ProductEnum.Group
                                    >
                                ).map((group, index) => (
                                    <span key={group}>
                                        {group}
                                        {Object.values(product.groups).length -
                                            1 ==
                                        index
                                            ? ''
                                            : ', '}
                                    </span>
                                ))}
                            </Value>
                        ) : (
                            <Value>{product.groups}</Value>
                        )}
                    </Col>
                    <Col>
                        <Label>{strings.libraryScope}</Label>
                        <Value>{product.libraryScope}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.level}</Label>
                        <Value data-testid={testIds.productLevel}>
                            {product.level}
                        </Value>
                    </Col>
                    <Col>
                        <Label>{strings.preserveProvisionedTerm}</Label>
                        <Value>{product.preserveProvisionedTerm}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.isUserAssignable}</Label>
                        <Value>{product.isUserAssignable}</Value>
                    </Col>
                    <Col>
                        <Label>{strings.customerFacingDescription}</Label>
                        <Value>{product.customerFacingDescription}</Value>
                    </Col>
                </Row>
            </div>
        </>
    )
}
