import { Input as AntInput } from 'antd'
import React from 'react'
import styles from './Input.module.scss'

const InputWithRef = (props: any, ref: any) => {
    return (
        <AntInput
            size="large"
            allowClear
            {...props}
            className={styles.input}
            ref={ref}
        />
    )
}

export const Input = React.forwardRef(InputWithRef)
