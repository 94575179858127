import { call, put, spawn } from 'redux-saga/effects'
import { createAction } from 'typesafe-actions'
import { adhocWatcher } from './adhoc/adhoc.sagas'
import { alertsWatcher } from './alerts/alerts.sagas'
import { connectedProductsWatcher } from './connected-products/cxnProducts.sagas'
import { contentLibrariesWatcher } from './content-libraries/content-libraries.sagas'
import { currencyWatcher } from './currency/currency.sagas'
import { featuresWatcher } from './features/features.sagas'
import { productOptionsWatcher } from './product-options/product-options.sagas'
import { productsWatcher } from './products/products.sagas'

const globalSagaError = createAction(
    '@saga/GLOBAL_ERROR',
    (payload: Error) => ({ error: payload })
)()

function* allSagas() {
    yield spawn(alertsWatcher)
    yield spawn(productOptionsWatcher)
    yield spawn(productsWatcher)
    yield spawn(contentLibrariesWatcher)
    yield spawn(featuresWatcher)
    yield spawn(currencyWatcher)
    yield spawn(adhocWatcher)
    yield spawn(connectedProductsWatcher)
}

export const rootSaga = function* root() {
    try {
        yield call(allSagas)
    } catch (error) {
        yield put(globalSagaError(error))
    }
}
