import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'
import { LoginLoader } from './LoginLoader'

export const CheckOkta: React.FC = ({ children }) => {
    const { authState, oktaAuth } = useOktaAuth()

    useEffect(() => {
        const pathUrl = window.location.href
        if (!authState) return

        if (!authState.isAuthenticated) {
            oktaAuth.signInWithRedirect({ originalUri: pathUrl })
        }
    }, [authState, oktaAuth])

    if (!authState || !authState.isAuthenticated) {
        return <LoginLoader />
    }

    return <>{children}</>
}
