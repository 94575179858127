import { put, select, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { selectCountryName } from '../product-options/product-options.selectors'
import { actions } from '../root-action'
import { PreferredCurrency } from '../typings/preferred-currency.interface'
import { SupportedCurrencyCodes } from './currency.reducer'

export function* getPreferredCurrenciesSaga() {
    try {
        const byCountry: PreferredCurrency[] =
            yield api.getPreferredCurrencies()
        yield put(actions.currency.getPreferred.success(byCountry))
    } catch (error) {
        yield put(actions.currency.getPreferred.failure(error))
    }
}

export function* createPreferredCurrencySaga(
    action: ReturnType<typeof actions.currency.createPreferred.request>
) {
    try {
        const data: PreferredCurrency = yield api.createPreferredCurrency(
            action.payload
        )

        const countryName: string = yield select((state) =>
            selectCountryName(state, data.country)
        )
        yield put(
            actions.currency.createPreferred.success({
                data,
                message: `Preferred currency ${
                    countryName ? `for ${countryName}` : ''
                } has been created`,
            })
        )
    } catch (error) {
        yield put(actions.currency.createPreferred.failure(error))
    }
}

export function* updatePreferredCurrencySaga(
    action: ReturnType<typeof actions.currency.createPreferred.request>
) {
    try {
        const data: PreferredCurrency = yield api.updatePreferredCurrency(
            action.payload
        )

        const countryName: string = yield select((state) =>
            selectCountryName(state, data.country)
        )
        yield put(
            actions.currency.updatePreferred.success({
                data,
                message: `Preferred currency ${
                    countryName ? `for ${countryName}` : ''
                } has been updated`,
            })
        )
    } catch (error) {
        yield put(actions.currency.updatePreferred.failure(error))
    }
}

export function* getSupportedCurrenciesSaga() {
    try {
        const supported: SupportedCurrencyCodes =
            yield api.getSupportedCurrencies()
        yield put(actions.currency.getSupported.success(supported))
    } catch (error) {
        yield put(actions.currency.getSupported.failure(error))
    }
}

export function* currencyWatcher() {
    yield takeEvery(
        getType(actions.currency.getPreferred.request),
        getPreferredCurrenciesSaga
    )
    yield takeEvery(
        getType(actions.currency.createPreferred.request),
        createPreferredCurrencySaga
    )
    yield takeEvery(
        getType(actions.currency.updatePreferred.request),
        updatePreferredCurrencySaga
    )
    yield takeEvery(
        getType(actions.currency.getSupported.request),
        getSupportedCurrenciesSaga
    )
}
