import React from 'react'
import Highlighter from 'react-highlight-words'
import styles from './Highlight.module.scss'

type Props = {
    searchWords: string[]
    textToHighlight: string
}

export class Highlight extends React.Component<Props> {
    render() {
        const { searchWords, textToHighlight } = this.props

        return (
            <Highlighter
                autoEscape
                highlightClassName={styles.highlighter}
                searchWords={searchWords}
                textToHighlight={textToHighlight}
            />
        )
    }
}
