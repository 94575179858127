import { ForwardOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { actions } from '../../../store/root-action'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { ErrorsTable } from './ErrorsTable'
import { outcomeColors } from './ImportOutcome'
import styles from './ReviewImport.module.scss'
import { ReviewTable } from './ReviewTable'

interface Props {
    data: ProductOption.ImportResponse[]
}

export const ReviewImport: React.FC<Props> = ({ data }) => {
    const dispatch = useDispatch()
    const totals = data.reduce(
        (accum, d) => ({
            ...accum,
            [d.outcome]: accum[d.outcome] + 1,
        }),
        { create: 0, update: 0, none: 0, error: 0 }
    )

    const valid = data.filter((d) => d.outcome !== 'error')
    const errors = data.filter((d) => d.outcome === 'error')

    const goToSubmit = () =>
        dispatch(actions.productOptions.setImportReviewStep(3))

    return (
        <div className={styles.container}>
            <Collapse defaultActiveKey="valid" ghost>
                <Collapse.Panel
                    key="valid"
                    header={
                        // TODO: move outcomeColors into sass modules
                        <HorizontalList className={styles.listHeader}>
                            <span
                                className={styles.header}
                                style={{
                                    color: outcomeColors.create,
                                }}
                            >
                                Create: {totals.create}
                            </span>
                            <span
                                className={styles.header}
                                style={{
                                    color: outcomeColors.update,
                                }}
                            >
                                Update: {totals.update}
                            </span>
                            <span
                                className={styles.header}
                                style={{
                                    color: outcomeColors.none,
                                }}
                            >
                                No change: {totals.none}
                            </span>
                        </HorizontalList>
                    }
                >
                    <ReviewTable data={valid} />
                </Collapse.Panel>
                <Collapse.Panel
                    key="errors"
                    header={
                        <span
                            className={styles.header}
                            style={{
                                color: outcomeColors.error,
                            }}
                        >
                            Errors ({errors.length})
                        </span>
                    }
                >
                    <ErrorsTable data={errors} />
                </Collapse.Panel>
            </Collapse>
            <div className={styles.buttonContainer}>
                <PrimaryButton onClick={goToSubmit}>
                    Continue to submit <ForwardOutlined />
                </PrimaryButton>
            </div>
        </div>
    )
}
