export namespace CurrencyEnum {
    export enum Code {
        USD = 'USD',
        EUR = 'EUR',
        GBP = 'GBP',
        INR = 'INR',
    }

    export enum Symbol {
        USD = '$',
        EUR = '€',
        GBP = '£',
        INR = '₹',
    }

    export enum Name {
        USD = 'US Dollar',
        EUR = 'Euro',
        GBP = 'Great British Pound',
        INR = 'Indian Rupee',
    }

    export function isDefaultCurrency(currency: Code) {
        return currency === Code.USD
    }
}
