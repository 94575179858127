import { Card } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectSupportedCurrencies } from '../../../store/currency/currency.selectors'
import { SupportedCurrency } from './SupportedCurrency'
import styles from './SupportedCurrencyList.module.scss'

const headStyle = {
    fontSize: '18px',
    fontWeight: 400,
    padding: '6px 24px',
}

export const SupportedCurrencyList: React.FC = () => {
    const currencies = useSelector(selectSupportedCurrencies)
    return (
        <Card title="Supported currencies" headStyle={headStyle}>
            <div className={styles.container}>
                {currencies.map((c) => (
                    <SupportedCurrency key={c.code} currency={c} />
                ))}
            </div>
        </Card>
    )
}
