import { ArrowsAltOutlined } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/es/table'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import { selectProductOptionFilters } from '../../../store/filters/filters.selectors'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import {
    ProductOptionFilterFields,
    selectProductOptionFieldOptions,
} from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { CommonEnum } from '../../../store/typings/common.enum'
import { CurrencyEnum } from '../../../store/typings/currency.enum'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { Highlight } from '../../highlight/Highlight'
import { Status } from '../../status/Status'
import { productOptionBooleans } from './ProductOptionContent'
import { ProductOptionDrawer } from './ProductOptionDrawer'
import { ProductOptionField } from './ProductOptionField'
import styles from './ProductOptionsTable.module.scss'

export enum PoColumnHeader {
    AdditionalInfo = 'ADDITIONAL INFO',
    Country = 'COUNTRY',
    MarketingId = 'MARKETING ID',
    Name = 'NAME',
    Pricing = 'PRICING',
    Status = 'STATUS',
}

export const PAGE_SIZE = 20

interface OwnProps {
    productOptions: any[]
}

type Props = OwnProps

const getKeyValue =
    <T extends Record<any, any>, U extends keyof T>(obj: T) =>
    (k: U) =>
        obj[k]

export const ProductOptionsTable: React.FC<Props> = ({ productOptions }) => {
    const dispatch = useDispatch()
    const [poId, setPoId] = useState<string>('')
    const isFormOpen = useSelector((state: RootState) =>
        selectFormOpen(state, FormName.ProductOptionView)
    )
    const fieldOptions = useSelector((state: RootState) =>
        selectProductOptionFieldOptions(state)
    )
    const filters = useSelector((state: RootState) =>
        selectProductOptionFilters(state)
    )
    const closeForm = () => {
        dispatch(actions.forms.close(FormName.ProductOptionView))
    }
    const openForm = (id: string) => {
        setPoId(id)
        return dispatch(
            actions.forms.openWithMeta(FormName.ProductOptionView, { id })
        )
    }

    const move = (id: string, moveForward: boolean) => {
        let currentIndex = productOptions.findIndex(function (
            po: ProductOption.AllFields,
            index
        ) {
            if (po?.id == id) return index
        })

        if (currentIndex === -1) {
            currentIndex = 0
        }

        if (
            (currentIndex == productOptions.length - 1 && moveForward) ||
            (currentIndex == 0 && !moveForward)
        ) {
            return id
        }
        currentIndex = moveForward ? currentIndex + 1 : currentIndex - 1
        const nextItem: ProductOption.AllFields = productOptions[currentIndex]
        setPoId(nextItem.id)
        return dispatch(
            actions.forms.openWithMeta(FormName.ProductOptionView, {
                id: nextItem.id,
            })
        )
    }

    const columns: ColumnProps<ProductOptionFilterFields>[] = [
        {
            dataIndex: 'status',
            key: 'status',
            render: (
                status: CommonEnum.State,
                productOption: ProductOptionFilterFields
            ) => {
                const badge = <Status text={productOption.state} />
                return badge
            },
            sorter: (a, b) => a.state.localeCompare(b.state),
            title: PoColumnHeader.Status,
            width: 120,
        },
        {
            dataIndex: 'name',
            ellipsis: true,
            key: 'name',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                return (
                    <Tooltip
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0.1}
                        overlayClassName={styles.tooltip}
                        placement="bottomLeft"
                        title={productOption.name}
                    >
                        <LinkButton onClick={() => openForm(productOption.id)}>
                            <Highlight
                                searchWords={[filters.name]}
                                textToHighlight={productOption.name}
                            />
                        </LinkButton>
                    </Tooltip>
                )
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
            title: PoColumnHeader.Name,
            width: 400,
        },
        {
            dataIndex: 'pricing',
            ellipsis: true,
            key: 'pricing',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                const priceOptions = productOption.priceOptions
                const USDPri = priceOptions.filter(
                    (p) => p.currency === CurrencyEnum.Code.USD
                )
                const pri = priceOptions[0]
                const tax = productOption.taxable ? '(+tax) ' : ''
                const billingPeriod = productOption?.billingPeriod
                if (USDPri.length > 0) {
                    return (
                        <span>{`${CurrencyEnum.Symbol.USD} ${USDPri[0].price} ${CurrencyEnum.Code.USD} ${tax} /  ${billingPeriod} `}</span>
                    )
                }

                return (
                    <span>{`${CurrencyEnum.Symbol[pri.currency]} ${pri.price} ${
                        pri.currency
                    }  ${tax} / ${billingPeriod} `}</span>
                )
            },
            title: PoColumnHeader.Pricing,
            width: 250,
        },
        {
            dataIndex: 'country',
            ellipsis: true,
            key: 'country',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                const country: ProductOption.CountryOption[] =
                    fieldOptions.countries.filter(
                        (country) => country.code === productOption.country
                    )
                return country[0]?.displayName
            },

            title: PoColumnHeader.Country,
            width: 200,
        },
        {
            dataIndex: 'marketingId',
            ellipsis: true,
            key: 'marketingId',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                return productOption.marketingId
                    ? productOption.marketingId
                    : ''
            },
            title: PoColumnHeader.MarketingId,
            width: 185,
        },
        {
            dataIndex: PoColumnHeader.AdditionalInfo,
            ellipsis: true,
            key: 'additionalInfo',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                {
                    let tagObj: any
                    for (const key in productOptionBooleans) {
                        const tag = Object.entries(productOptionBooleans)
                            .filter(([k, _]) => {
                                return k === key
                            })
                            .map(([_, label]) => {
                                tagObj = new Object()
                                tagObj.label = label ? label[0] : ''
                                tagObj.tooltip = label ? label[1] : ''
                                return tagObj
                            })

                        let value = getKeyValue(productOption)(key as any)

                        if (key === 'taxable') {
                            value = !value
                        }
                        const label = tag[0]?.label
                        const tooltip = tag[0]?.tooltip

                        return tag ? (
                            <ProductOptionField
                                key={key}
                                label={label}
                                name={key}
                                tooltip={tooltip}
                                value={value}
                            />
                        ) : null
                    }
                }
            },
            title: PoColumnHeader.AdditionalInfo,
            width: 300,
        },
        {
            ellipsis: true,
            fixed: 'right',
            render: (
                text: string,
                productOption: ProductOptionFilterFields
            ) => {
                return (
                    <LinkButton
                        icon={<ArrowsAltOutlined />}
                        onClick={() => openForm(productOption.id)}
                    />
                )
            },
            width: 50,
        },
    ]

    const tableProps = {
        className: styles.productOptionsTable,
        columns,
        dataSource: productOptions,
        pagination: { defaultPageSize: PAGE_SIZE },
        rowKey: 'id',
        scroll: { x: 768 },
    }

    if (isFormOpen) {
        return (
            <>
                <ProductOptionDrawer
                    id={poId}
                    onClose={closeForm}
                    next={move}
                />
                <Table {...tableProps} />
            </>
        )
    }

    return <Table {...tableProps} />
}
