import { isEmpty, map } from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from 'typesafe-actions'
import { CurrencyEnum } from '../typings/currency.enum'

export const selectSupportedCurrencies = createSelector(
    ({ currency }: RootState) => currency.supported,
    (supported) =>
        supported
            .map((code) => ({
                code,
                name: CurrencyEnum.Name[code],
                symbol: CurrencyEnum.Symbol[code],
                displayName: `${CurrencyEnum.Name[code]} (${code})`,
            }))
            .sort((a, b) => {
                if (CurrencyEnum.isDefaultCurrency(a.code)) {
                    return -1
                }
                if (CurrencyEnum.isDefaultCurrency(b.code)) {
                    return 1
                }
                return a.code.localeCompare(b.code)
            })
)

export const selectCurrencyByCountry = createSelector(
    ({ currency }: RootState) => currency.preferred,
    ({ productOptions }: RootState) => productOptions.fieldOptions.countries,
    (byCountry, countryMap) => {
        if (isEmpty(countryMap)) {
            return []
        }

        const mapped = map(byCountry, ({ payload }) => ({
            ...payload,
            name: countryMap[payload.country],
        }))

        return mapped.sort((a, b) => a.name.localeCompare(b.name))
    }
)
