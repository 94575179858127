import { Space, Table } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import { map } from 'lodash/fp'
import React from 'react'
import { ProductOptionConnection } from '../cxn-po.interface'
import { ExcludeTableData } from '../table-unassigned/unassigned-table.interface'
import { poCxnWidths, UP_LABEL_BREAKS } from '../tables/cxn-table.constants'
import {
    UndoClick,
    UndoConnectionButton,
} from '../tables/SharedTableComponents'

const { UPGRADE_LABEL, SELECT_NAME } = poCxnWidths

type PoCxn = ProductOptionConnection
type ExclCol = ColumnType<ExcludeTableData>

export type excludedPoMappingRes = {
    productName: string
}

// -------------------------------- COLUMNS -------------------------------- //

/**
 * Column - Name of the excluded product option
 */
const makeNameColumn = (num: number): ExclCol => ({
    key: 0,
    ellipsis: true,
    dataIndex: ['fromPo', 'name'],
    title: `EXCLUDED (${num})`,
})

const upgradeUpsellColumn: ExclCol = {
    key: 1,
    width: UPGRADE_LABEL,
    responsive: UP_LABEL_BREAKS,
    align: 'center',
    render: () => <></>,
}

const makeToColumn = (): ExclCol => ({
    key: 2,
    width: SELECT_NAME,
    render: () => <div></div>,
})

const makeMapToColumn = (inc: UndoClick) => ({
    key: 3,
    render: ({ fromPo }: ExcludeTableData) => (
        <Space>
            <UndoConnectionButton
                isExclude={true}
                fromId={fromPo.id}
                setUndo={inc}
            />
        </Space>
    ),
})

// -------------------------------- TABLE Component -------------------------------- //

const defaultTableProps: TableProps<ExcludeTableData> = {
    showHeader: true,
    bordered: false,
    pagination: false,
    size: 'small',
    rowKey: (record) => record.fromPo.id,
}

export type Props = {
    excludedData: PoCxn[]
    undoClick: UndoClick
}

export const ExcludedConnectionsTable = (props: Props) => {
    const { excludedData, undoClick } = props

    if (!excludedData.length) return null

    return (
        <Table
            {...defaultTableProps}
            columns={[
                makeNameColumn(excludedData.length),
                upgradeUpsellColumn,
                makeToColumn(),
                makeMapToColumn(undoClick),
            ]}
            dataSource={map<PoCxn, ExcludeTableData>(
                ({ fromProductOption }) => ({ fromPo: fromProductOption })
            )(excludedData)}
        />
    )
}
