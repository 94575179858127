import { Badge, Card, Col, Descriptions, Divider, Row } from 'antd'
import classNames from 'classnames'
import { upperFirst } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import apiService from '../../../services/api.service'
import { socketService } from '../../../services/socket.service'
import { formatTimestamp } from '../../../store/utils'
import styles from './Feedback.module.scss'

export class Feedback extends React.Component<any, any> {
    state = {
        counts: {
            active: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            waiting: 0,
        },
        jobData: [],
        status: 'default' as
            | 'success'
            | 'processing'
            | 'error'
            | 'default'
            | 'warning',
    }

    constructor(props: any) {
        super(props)
    }

    async componentDidMount() {
        const updateFeedbackData = async () => {
            await this.sleep(1000)
            const jobs: any = await apiService.productOptionImportGetJobs()
            const feedback: any = await apiService.productOptionImportFeedback()

            this.setState({
                ...this.state,
                counts: feedback.jobCounts,
                jobData: jobs.filter((job: any) => job.jobStatus),
                status: this.currentJobStatus(),
            })
        }

        socketService
            .init()
            .subscribe('queue.active', updateFeedbackData)
            .subscribe('queue.completed', updateFeedbackData)
            .subscribe('queue.failed', updateFeedbackData)
            .subscribe('queue.stalled', updateFeedbackData)
            .subscribe('queue.error', updateFeedbackData)
    }

    sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

    componentWillUnmount() {
        socketService.disconnect()
    }

    currentJobStatus() {
        let status = this.state.status
        const { totalRecords } = this.props.totals
        const { active, delayed, failed, waiting } = this.state.counts
        const started = totalRecords > 0
        const remaining = active + delayed + waiting

        status =
            started && remaining === 0
                ? started && failed > 0
                    ? 'error'
                    : 'success'
                : started && remaining > 0
                ? 'processing'
                : status

        return status
    }

    statusText() {
        const { active, delayed, waiting } = this.state.counts
        const remaining = active + delayed + waiting

        switch (this.state.status.toString()) {
            default:
            case 'warning':
                return this.state.status
            case 'processing':
                return `Processing (${remaining})`
            case 'default':
                return 'Idle'
            case 'error':
                return 'Complete with failures'
            case 'success':
                return 'Complete'
        }
    }

    render() {
        const {
            totalQueued,
            totalRecords,
            totalSkippedInvalid,
            totalSkippedUnchanged,
        } = this.props.totals
        const { active, completed, delayed, failed, waiting } =
            this.state.counts

        return (
            <div className={styles.container}>
                <Divider orientation="left">
                    Job Status: &nbsp;{' '}
                    <Badge
                        status={this.state.status as any}
                        text={this.statusText()}
                    />
                </Divider>
                <Row gutter={[12, 24]} className={'row-gap-24'}>
                    <Col flex={1}>
                        <Descriptions title="Import" bordered={true}>
                            <Descriptions.Item
                                label="Total Product Options"
                                span={2}
                            >
                                {totalRecords}
                            </Descriptions.Item>
                            <Descriptions.Item label="Import Total">
                                {totalQueued}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label="Skipped (Unchanged)"
                                span={2}
                            >
                                {totalSkippedUnchanged}
                            </Descriptions.Item>
                            <Descriptions.Item label="Skipped (Invalid)">
                                {totalSkippedInvalid}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col flex={1}>
                        <Descriptions title="Queue" bordered={true}>
                            <Descriptions.Item label="Active">
                                {active}
                            </Descriptions.Item>
                            <Descriptions.Item label="Delayed">
                                {delayed}
                            </Descriptions.Item>
                            <Descriptions.Item label="Waiting">
                                {waiting}
                            </Descriptions.Item>
                            <Descriptions.Item label="Completed">
                                <span className={styles.completed}>
                                    {completed}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Failed">
                                <span className={failed ? styles.failed : ''}>
                                    {failed}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>

                <Divider orientation="left">
                    Job Data ({this.state.jobData.length})
                </Divider>

                <Row
                    className={classNames(styles.scroll, 'row-gap-24')}
                    gutter={[12, 24]}
                >
                    {this.state.jobData.map((job: any, i) => {
                        let completedVal = null
                        if (job.jobStatus === 'completed') {
                            completedVal = (
                                <div className={styles.completed}>
                                    <b>Result:</b>{' '}
                                    {upperFirst(job.data.dto.outcome)}d{' '}
                                    <Link
                                        to={`/products/${job.data.dto.entity.productCatalogId}#${job.data.dto.entity.id}`}
                                        target="_blank"
                                    >
                                        {job.data.dto.entity.name}
                                    </Link>
                                    <div>
                                        <b>Completed Time:</b>{' '}
                                        {formatTimestamp(job.finishedOn)}
                                    </div>
                                </div>
                            )
                        }

                        let cardStyle = ''
                        let failedInfo = null
                        if (job.jobStatus === 'failed') {
                            cardStyle = styles.failed
                            failedInfo = <br /> + JSON.stringify(job, null, 2)
                        }
                        if (job.jobStatus === 'completed') {
                            cardStyle = styles.completed
                        }
                        if (job.jobStatus === 'waiting') {
                            cardStyle = styles.waiting
                        }

                        return (
                            <Col key={`job${i}`} flex={1}>
                                <Card className={cardStyle}>
                                    <b>Job Id:</b> {job.id}
                                    <br />
                                    <b>Job Status:</b> {job.jobStatus}
                                    <br />
                                    {completedVal}
                                    {failedInfo}
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }
}
