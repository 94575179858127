import {
    CheckOutlined,
    LoadingOutlined,
    MinusOutlined,
} from '@ant-design/icons'
import { Switch, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/es/table'
import React from 'react'
import { Feature } from '../../../store/typings/features.interface'
import { formatTimestamp } from '../../../store/utils'
import { Copy } from '../../copy/Copy'
import styles from './FeaturesList.module.scss'

export interface FeatureListItem extends Feature {
    assignedTimestamp?: string | null
}

interface Props {
    features: FeatureListItem[]
    onToggle?: (value: boolean, id: string) => void
    toggleState?: any
    isSaving: boolean
}

const isActive = (feature: FeatureListItem, toggleState?: any) => {
    if (!toggleState) {
        return !!feature.assignedTimestamp
    }

    return toggleState[feature.id]
}

export const FeaturesList: React.FC<Props> = ({
    features,
    onToggle,
    isSaving,
    toggleState,
}) => {
    const columns: ColumnProps<FeatureListItem>[] = [
        {
            dataIndex: 'id',
            key: 'toggle',
            render: (id, feature) =>
                toggleState ? (
                    <Switch
                        checked={isActive(feature, toggleState)}
                        disabled={!onToggle}
                        onChange={(value) => onToggle && onToggle(value, id)}
                    />
                ) : feature.assignedTimestamp ? (
                    <CheckOutlined className={styles.checkMark} />
                ) : (
                    <MinusOutlined className={styles.minus} />
                ),
            width: 60,
            align: 'center',
        },
        {
            dataIndex: 'name',
            render: (name, feature) => (
                <div
                    className={
                        isActive(feature, toggleState)
                            ? styles.name
                            : styles.nameInactive
                    }
                >
                    <Tooltip
                        title={
                            feature.assignedTimestamp
                                ? `Assigned: ${formatTimestamp(
                                      feature.assignedTimestamp
                                  )}`
                                : null
                        }
                        placement="right"
                        overlayClassName={styles.timestampTip}
                        mouseEnterDelay={0.5}
                    >
                        {name}
                    </Tooltip>
                </div>
            ),
        },
        {
            dataIndex: 'id',
            key: 'copyId',
            render: (id) => (
                <Copy clipboard={id} title="Copy ID?" label="Copy ID" />
            ),
            align: 'right',
            width: 120,
        },
    ]

    return (
        <Table
            className={styles.featuresList}
            columns={columns}
            dataSource={features}
            pagination={false}
            rowKey="id"
            showHeader={false}
            size="small"
            loading={{
                spinning: isSaving,
                indicator: <LoadingOutlined style={{ fontSize: '72px' }} />,
            }}
        />
    )
}
