import { Space, TableProps } from 'antd'
import Table, { ColumnProps, ColumnType } from 'antd/lib/table'
import React from 'react'
import { CommonEnum } from '../../../../store/typings/common.enum'
import { UpgradeIcon, UpsellIcon } from '../../CxtComponents'
import { ProductOptionConnection } from '../cxn-po.interface'
import { CompleteTableData } from '../table-unassigned/unassigned-table.interface'
import { poCxnWidths, UP_LABEL_BREAKS } from '../tables/cxn-table.constants'
import {
    ExcludeClick,
    ExcludeConnectionButton,
    UndoClick,
    UndoConnectionButton,
} from '../tables/SharedTableComponents'

type PoCxn = ProductOptionConnection
type CompleteCol = ColumnType<CompleteTableData>

const upgrade = CommonEnum.Link.Upgrade

const { UPGRADE_LABEL, SELECT_NAME } = poCxnWidths

const makeTitleColumn = (num: number): CompleteCol => ({
    key: 0,
    ellipsis: true,
    title: `CONFIGURED (${num})`,
    dataIndex: ['fromPo', 'name'],
})

const upgradeUpsellColumn: CompleteCol = {
    key: 1,
    align: 'center',
    width: UPGRADE_LABEL,
    responsive: UP_LABEL_BREAKS,
    render: (data: CompleteTableData) => {
        const isUpgrade = data.linkType === upgrade
        const icon = isUpgrade ? <UpgradeIcon /> : <UpsellIcon />
        const labelText = isUpgrade ? 'Upgrade' : 'Upsell'

        return (
            <>
                {icon}
                {` ${labelText} to`}
            </>
        )
    },
}

const makeToColumn = (): CompleteCol => ({
    key: 2,
    width: SELECT_NAME,
    dataIndex: ['toPo', 'name'],
})

const mapToColumn = (excl: ExcludeClick, undo: UndoClick): CompleteCol => ({
    key: 3,
    render: ({ fromPo, toPo }: CompleteTableData) => {
        return (
            <Space>
                <UndoConnectionButton
                    fromId={fromPo.id}
                    toId={toPo.id}
                    setUndo={undo}
                    isExclude={false}
                />
                |
                <ExcludeConnectionButton
                    poId={fromPo.id}
                    updater={excl}
                    type={'complete'}
                />
            </Space>
        )
    },
})

const defaultTableProps: TableProps<CompleteTableData> = {
    showHeader: true,
    bordered: false,
    pagination: false,
    size: 'middle',
    rowKey: (record) => record.fromPo.id,
}
// -------------------------------- TABLE Component -------------------------------- //

type Props = {
    mappingType: CommonEnum.Link
    poCxns: Required<PoCxn>[]
    clickExclude: ExcludeClick
    clickUndo: UndoClick
}

export const CompletedPoMappingsTable = (props: Props) => {
    const { mappingType, poCxns } = props

    const data = poCxns.map(
        (v): CompleteTableData => ({
            fromPo: v.fromProductOption,
            toPo: v.toProductOption,
            linkType: mappingType,
        })
    )

    const columns: ColumnProps<CompleteTableData>[] = [
        makeTitleColumn(5),
        upgradeUpsellColumn,
        makeToColumn(),
        mapToColumn(props.clickExclude, props.clickUndo),
    ]
    const tableProps = {
        ...defaultTableProps,
        columns,
        dataSource: data,
    }

    if (!poCxns.length) return null

    return <Table {...tableProps} />
}
