/**
 * This is the PARENT CONTAINER for the sidebar and the main body area
 * Responsibilities are to keep state between menu and body
 * Render the child components
 *
 *  -- Nice To Haves --
 *   - [] Sort by percentage completed
 */

import { Badge, Typography } from 'antd'
import React from 'react'
import { CommonEnum } from '../../../../store/typings/common.enum'
import { UpgradeIcon, UpsellIcon } from '../../CxtComponents'
import styles from './CxnPoContent.module.scss'

const { Text } = Typography
const { Link } = CommonEnum

/**
 * This is the completed badge
 */

type CompletedProps = {
    percentComplete: number
}
export const Completed = (props: CompletedProps) => {
    const { percentComplete } = props

    const badgeStatus = !percentComplete
        ? 'error'
        : percentComplete < 100
        ? 'warning'
        : 'success'

    return (
        <div className={styles.status}>
            <Badge status={badgeStatus} />
            <div className={styles.text}>{percentComplete}% Complete</div>
        </div>
    )
}

type UpProps = { type: CommonEnum.Link; name: string }

export const UpsellOrUpgrade = ({ type, name }: UpProps) => (
    <div>
        {type === Link.Upgrade ? <UpgradeIcon /> : <UpsellIcon />}
        <Text>{name}</Text>
    </div>
)
