import { InputNumber } from 'antd'
import Form, {
    FormInstance,
    FormItemProps as AntFormItemProps,
    Rule,
} from 'antd/lib/form'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Input, Select } from '../../components/form'
import { formNames, strings } from '../../constants'
import {
    selectCategoryOptions,
    selectCustomerTypeOptions,
    selectGroupOptions,
    selectLibraryScopeOptions,
    selectProductSkus,
    selectProductTypeOptions,
} from '../../store/products/products.selectors'
import { ProductEnum } from '../../store/typings/product.enum'
import { TrueFalse } from './ProductOptionFields'

interface FormItemProps extends AntFormItemProps {
    form: FormInstance
    options: any
    validators: any[]
}

export const FormItem: React.FC<FormItemProps> = ({
    children,
    name,
    ...formItemProps
}) => {
    return (
        <Form.Item
            name={name}
            validateFirst
            validateTrigger={['onChange', 'onBlur']}
            {...formItemProps}
        >
            {children}
        </Form.Item>
    )
}

export const RequiredFormItem: React.FC<FormItemProps> = ({
    rules,
    ...props
}: FormItemProps) => {
    let allRules: Rule[] = [
        { message: 'This field is required', required: true },
    ]
    if (rules) {
        allRules = allRules.concat(rules)
    }
    return <FormItem rules={allRules} {...props} />
}

export const NameField = ({ style, ...props }: any) => {
    return (
        <RequiredFormItem name={formNames.name} label={strings.name} {...props}>
            <Input className={style} />
        </RequiredFormItem>
    )
}

export const SkuField = connect(
    (state: RootState) => ({
        skus: selectProductSkus(state),
    }),
    {}
)(({ skus, style, initialSku, ...props }: any) => {
    const uniqueValidator = {
        validator: function (rule: any, value: any) {
            if (value && skus[value.toUpperCase()] && value !== initialSku) {
                return Promise.reject('SKU must be unique')
            }
            return Promise.resolve()
        },
    }
    const spaceValidator = {
        validator: function (rule: any, value: any) {
            if (value && value.indexOf(' ') >= 0) {
                return Promise.reject('SKU must not contain spaces')
            }
            return Promise.resolve()
        },
    }

    return (
        <RequiredFormItem
            name={formNames.sku}
            label={strings.sku}
            rules={[uniqueValidator, spaceValidator]}
            validateFirst
            {...props}
        >
            <Input className={style} />
        </RequiredFormItem>
    )
})

export const CategoryField = connect(
    (state: RootState) => ({
        categories: selectCategoryOptions(state),
    }),
    {}
)(({ categories, ...props }: any) => {
    return (
        <RequiredFormItem
            name={formNames.category}
            label={strings.category}
            {...props}
        >
            <Select options={categories} />
        </RequiredFormItem>
    )
})

export const CustomerTypeField = connect(
    (state: RootState) => ({
        customerTypes: selectCustomerTypeOptions(state),
    }),
    {}
)(({ customerTypes, ...props }: any) => (
    <RequiredFormItem
        name={formNames.customerType}
        label={strings.customerType}
        {...props}
    >
        <Select options={customerTypes} />
    </RequiredFormItem>
))

export const GroupsField = connect(
    (state: RootState) => ({
        groups: selectGroupOptions(state),
    }),
    {}
)(({ groups, ...props }: any) => {
    const options: string[] = [
        ...Object.values(ProductEnum.Group).map((state: string) => {
            return state
        }),
    ]
    return (
        <FormItem name={formNames.groups} label={strings.groups} {...props}>
            <Select
                options={options}
                mode="multiple"
                placeholder="Select Groups"
            />
        </FormItem>
    )
})

export const ProductTypeField = connect(
    (state: RootState) => ({
        productTypes: selectProductTypeOptions(state),
    }),
    {}
)(({ productTypes, ...props }: any) => (
    <RequiredFormItem
        name={formNames.type}
        label={strings.productType}
        {...props}
    >
        <Select options={productTypes} />
    </RequiredFormItem>
))

export const LibraryScopeField = connect(
    (state: RootState) => ({
        libraryScopes: selectLibraryScopeOptions(state),
    }),
    {}
)(({ libraryScopes, ...props }: any) => (
    <RequiredFormItem
        name={formNames.libraryScope}
        label={strings.libraryScope}
        {...props}
    >
        <Select options={libraryScopes} />
    </RequiredFormItem>
))

export const LevelField = (props: any) => (
    <RequiredFormItem name={formNames.level} label={strings.level} {...props}>
        <InputNumber data-testid={formNames.level} min={0} max={255} />
    </RequiredFormItem>
)

export const PreserveProvisionedField = () => (
    <Form.Item
        name={formNames.preserveProvisionedTerm}
        label="Preserve Provisioned Term?"
    >
        <TrueFalse />
    </Form.Item>
)

export const IsUserAssignableField = () => (
    <Form.Item name={formNames.isUserAssignable} label="Is user assignable?">
        <TrueFalse />
    </Form.Item>
)

export const CustomerFacingDescription = () => (
    <Form.Item
        name={formNames.customerFacingDescription}
        label="Customer Facing Description"
    >
        <Input />
    </Form.Item>
)
