import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { ProductOptionEnum } from '../../store/typings/product-option.enum'
import styles from './TagFilter.module.scss'

export const TagFilter: React.FC<SelectProps<any>> = (props) => {
    const options = [
        ...Object.values(ProductOptionEnum.Tags).map((state: string) => {
            return { label: state, value: state }
        }),
    ]

    return (
        <Select
            className={styles.tags}
            mode="multiple"
            options={options}
            placeholder="All tags"
            showArrow
            size="large"
            {...props}
        />
    )
}
