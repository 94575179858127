import { createAction } from 'typesafe-actions'
import {
    LocalizationFilters,
    LocalizedProductOptionFilters,
    ProductFilters,
    ProductOptionFilters,
} from './filters.reducer'

export const filterProduct = createAction('ui/FILTER_PRODUCT')<ProductFilters>()

export const filterProductOption = createAction(
    'ui/FILTER_PRODUCT_OPTION'
)<ProductOptionFilters>()

export const filterLocalizedProductOption = createAction(
    'ui/FILTER_LOCALIZED_PRODUCT_OPTION'
)<LocalizedProductOptionFilters>()

export const filterLocalization = createAction(
    'ui/FILTER_LOCALIZATION'
)<LocalizationFilters>()
