import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { AntRange } from './ChangeLog'

dayjs.extend(quarterOfYear)

export const changelogDto = ([start, end]: AntRange, preset: 'audit') => ({
    start: start?.format('YYYY-MM-DD'),
    end: end?.format('YYYY-MM-DD'),
    preset,
})

export const lastQuarter = (): AntRange => {
    const end = dayjs().startOf('Q').subtract(1, 'd')
    return [dayjs(end).startOf('Q'), end]
}
export const lastMonth = (): AntRange => {
    const end = dayjs().startOf('M').subtract(1, 'd')
    return [dayjs(end).startOf('M'), end]
}
export const lastYear = (): AntRange => {
    const end = dayjs().startOf('y').subtract(1, 'd')
    return [dayjs(end).startOf('y'), end]
}

export const thisQuarter = (): AntRange => {
    return [dayjs().startOf('Q'), dayjs()]
}

export const thisYear = (): AntRange => {
    return [dayjs().startOf('y'), dayjs()]
}
