import {
    AuditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    ExportOutlined,
    FormOutlined,
    QuestionCircleOutlined,
    ShareAltOutlined,
    SyncOutlined,
} from '@ant-design/icons'
import { Menu, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ability } from '../../../Authentication/permissions/ability'
import { Can } from '../../../Authentication/permissions/Can'
import { LinkButton } from '../../../components/buttons/Buttons'
import { Dropdown } from '../../../components/dropdown/Dropdown'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { actions } from '../../../store/root-action'
import { Alert } from '../../../store/typings/alert.interface'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { Product } from '../../../store/typings/product.interface'
import { Copy } from '../../copy/Copy'
import styles from './Actions.module.scss'

export interface ActionConfig {
    clone?: {
        onClick: () => void
        onSuccess?: () => void
    }
    delete: {
        apiRequest: any
        onSuccess: (deleted: any) => void
    }
    edit: {
        onClick?: () => void
    }
    export?: {
        canExport: boolean
        onClick: () => void
    }
    share: {
        url: string
    }
    status: {
        apiRequest: any
        onSuccess: (toggled: any) => void
    }
    sync: {
        apiRequest: any
        onClick?: () => void
        onSuccess: (synced: any) => void
    }
}

type Props = {
    config: ActionConfig
    data: Product.AllFields | ProductOption.AllFields
    permissions: {
        action: string
        subject: string
    }
    visibleData: Product.AllFields | ProductOption.AllFields
}

export const Actions: React.FC<Props> = ({
    permissions,
    data,
    config,
    visibleData,
}) => {
    const dispatch = useDispatch()

    const addSuccessAlert = (alert: Alert.Base) => {
        dispatch(actions.alerts.addSuccess(alert))
    }

    const addFailAlert = (alert: Alert.Base) => {
        dispatch(actions.alerts.addFail(alert))
    }

    const confirmSync = () => {
        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, sync',
            title: 'Sync to Zuora?',
            content: (
                <p>
                    Are you sure you want to sync <b>{visibleData.name}</b> to
                    Zuora?
                </p>
            ),
            onOk() {
                return config.sync
                    .apiRequest({
                        id: data.id,
                        lastModified: visibleData.modifiedTimestamp,
                    })
                    .then(
                        (
                            synced: Product.AllFields | ProductOption.AllFields
                        ) => {
                            config.sync.onSuccess(synced)
                            addSuccessAlert({
                                message: `"${synced.name}" was successfully synced.`,
                            })
                        }
                    )
                    .catch((error: any) => {
                        addFailAlert({
                            details: error.details,
                            message: `"${visibleData.name}" failed to sync.`,
                        })
                    })
            },
        })
    }

    const confirmActivate = () => {
        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, activate',
            title: 'Activate?',
            content: (
                <p>
                    Are you sure you want to activate <b>{data.name}</b>?
                </p>
            ),
            onOk() {
                return config.status
                    .apiRequest({ id: data.id })
                    .then(
                        (
                            toggled: Product.AllFields | ProductOption.AllFields
                        ) => {
                            config.status.onSuccess(toggled)
                            addSuccessAlert({
                                message: `"${toggled.name}" was successfully activated.`,
                            })
                        }
                    )
                    .catch((error: any) => {
                        addFailAlert({
                            details: error.details,
                            message: `"${data.name}" failed to activate.`,
                        })
                    })
            },
        })
    }

    const confirmDeactivate = () => {
        const isProduct = !!(data as Product.AllFields).sku
        const productContent =
            'All associated product options and pricing will be deactivated.'

        const productOptionContent =
            'All associated pricing will be deactivated.'

        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, deactivate',
            title: 'Deactivate?',
            content: (
                <p>
                    Are you sure you want to deactivate <b>{data.name}</b>?{' '}
                    {isProduct ? productContent : productOptionContent}
                </p>
            ),
            onOk() {
                return config.status
                    .apiRequest({ id: data.id })
                    .then(
                        (
                            toggled: Product.AllFields | ProductOption.AllFields
                        ) => {
                            config.status.onSuccess(toggled)
                            addSuccessAlert({
                                message: `"${toggled.name}" was successfully deactivated.`,
                            })
                        }
                    )
                    .catch((error: any) => {
                        addFailAlert({
                            details: error.details,
                            message: `"${data.name}" failed to deactivate.`,
                        })
                    })
            },
        })
    }

    const confirmDeleteDraft = () => {
        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, delete draft',
            title: 'Delete draft?',
            content: (
                <p>
                    Are you sure you want to delete the draft{' '}
                    <b>{visibleData.name}</b>?
                </p>
            ),
            onOk() {
                return config.delete
                    .apiRequest({ id: data.id })
                    .then(() => {
                        config.delete.onSuccess(data)
                        addSuccessAlert({
                            message: `Draft "${visibleData.name}" was successfully deleted.`,
                        })
                    })
                    .catch((error: any) => {
                        addFailAlert({
                            details: error.details,
                            message: `Draft "${visibleData.name}" failed to delete.`,
                        })
                    })
            },
        })
    }

    const canManage = ability.can(permissions.action, permissions.subject)
    const canManageDivider = canManage && <Menu.Divider />

    const isDraft = visibleData.meta.isDraft
    const canClone = Product.isProduct(data)
        ? !isDraft
        : !isDraft || data.meta.isNew

    const isActive = data.status === CommonEnum.Status.Active
    const statusData = {
        icon: isActive ? <CloseCircleOutlined /> : <CheckCircleOutlined />,
        label: isActive ? 'Deactivate' : 'Activate',
        onClick: isActive ? confirmDeactivate : confirmActivate,
    }

    const menu = (
        <Menu className={styles.menu}>
            {canClone && canManage ? (
                <Menu.Item key="clone">
                    <LinkButton
                        icon={<AuditOutlined />}
                        onClick={config.clone?.onClick}
                    >
                        Clone
                    </LinkButton>
                </Menu.Item>
            ) : null}

            {canClone && canManageDivider}
            <Menu.Item key="copy-id">
                <Copy clipboard={data.id} title="Copy ID?" label="Copy ID" />
            </Menu.Item>

            {isDraft && canManageDivider}
            {isDraft && canManage ? (
                <Menu.Item key="delete-draft">
                    <LinkButton
                        icon={<DeleteOutlined />}
                        onClick={confirmDeleteDraft}
                    >
                        Delete draft
                    </LinkButton>
                </Menu.Item>
            ) : null}

            {canManageDivider}
            {canManage ? (
                <Menu.Item key="toggle-status">
                    <LinkButton
                        icon={statusData.icon}
                        onClick={statusData.onClick}
                        disabled={isDraft}
                    >
                        {statusData.label}
                    </LinkButton>
                </Menu.Item>
            ) : null}

            {config.export?.canExport && <Menu.Divider />}
            {config.export?.canExport ? (
                <Menu.Item key="export-product-options">
                    <LinkButton
                        icon={<ExportOutlined />}
                        onClick={config.export?.onClick}
                    >
                        Export product options
                    </LinkButton>
                </Menu.Item>
            ) : null}

            <Menu.Divider />
            <Menu.Item key="share-url">
                <Copy
                    clipboard={config.share.url}
                    title="Copy URL to share?"
                    label="Share URL"
                    icon={<ShareAltOutlined />}
                />
            </Menu.Item>
        </Menu>
    )

    return (
        <HorizontalList>
            <Can I={permissions.action} a={permissions.subject}>
                <LinkButton
                    onClick={config.edit.onClick}
                    icon={<FormOutlined />}
                    disabled={!!data.draft && !visibleData.meta.isDraft}
                >
                    Edit
                </LinkButton>
                <LinkButton
                    icon={<SyncOutlined />}
                    onClick={config.sync.onClick || confirmSync}
                    disabled={!visibleData.meta.isDraft}
                >
                    Sync to Zuora
                </LinkButton>
            </Can>

            {canManage ? (
                <Dropdown overlay={menu} trigger={['click']}>
                    <LinkButton
                        className="ant-dropdown-link"
                        icon={<EllipsisOutlined />}
                    />
                </Dropdown>
            ) : (
                <>
                    <Copy
                        clipboard={data.id}
                        title="Copy ID?"
                        label="Copy ID"
                    />
                    {config.export?.canExport ? (
                        <LinkButton
                            icon={<ExportOutlined />}
                            onClick={config.export?.onClick}
                        >
                            Export product options
                        </LinkButton>
                    ) : null}
                    <Copy
                        clipboard={config.share.url}
                        title="Copy URL to share?"
                        label="Share URL"
                        icon={<ShareAltOutlined />}
                    />
                </>
            )}
        </HorizontalList>
    )
}
