import React from 'react'
import styles from './Label.module.scss'

type Props = { separator?: string }

export class Label extends React.Component<Props> {
    render() {
        const { children, separator = '', ...props } = this.props
        const label = children

        return (
            <span className={styles.label} {...props}>
                {label}
                {separator}
            </span>
        )
    }
}
