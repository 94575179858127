/**
 * This component will render all of the tables and display
 * what has been selected from the sidebar
 *
 */

// --> PoContainer
// ----> CxnPoContainer
// ! ------> CxnPoContent

// TODO: Need to recalc best guess after moving back to incomplete

import { Dictionary, find, map } from 'lodash'
import { groupBy as groupByFp, keyBy as keyByFp, map as mapFp } from 'lodash/fp'
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Required } from 'utility-types'
import api from '../../../../services/api.service'
import { cxnOptions } from '../../connected-product.constants'
import { getAllPos, updatePoCxns } from '../../connected-products.service'
import {
    CompletedStatus,
    PConnection,
    ProductOptionConnection,
    SimpleInfo,
} from '../cxn-po.interface'
import { CompletedPoMappingsTable } from '../table-completed/CompletedPoMappings'
import { ExcludedConnectionsTable } from '../table-excluded/ExcludedCxnTable'
import { UnassignedConnectionsTable } from '../table-unassigned/UnassignedCxnTable'
import styles from './CxnPoContent.module.scss'

type PoCxn = ProductOptionConnection

const linkGroup = groupByFp<PoCxn>((po) => po.linkState)
const { removeLinkByIds, excludePoLink } = api

type Props = {
    currentPos: ProductOptionConnection[]
    currentProduct: PConnection
    setCurrentPos: Dispatch<SetStateAction<PoCxn[] | undefined>>
}

export const CxnPoContentContainer = (props: Props) => {
    const { currentPos, currentProduct } = props

    const [allToPos, setToPos] = useState<SimpleInfo[]>([])

    useEffect(() => {
        getAllPos(props.currentProduct.toProduct.id).then(setToPos)
    }, [props.currentProduct])

    // -------------------------------- MEMOS -------------------------------- //
    const byLinkState = useMemo(() => linkGroup(currentPos), [currentPos])

    const remainingPos = useMemo(() => {
        return getRemainingToPos(
            allToPos,
            mapFp(makeCompleted)(getPoByType(byLinkState)('complete'))
        )
    }, [allToPos, byLinkState])

    // -------------------------------- Pass Down Methods -------------------------------- //

    const clickExclude = (fromId: string) => {
        const oldList = [...currentPos]
        const updatedList = updatePoCxns(currentPos, fromId, 'exclude')
        props.setCurrentPos(updatedList)
        excludePoLink(fromId)
            .then(() => {
                // loading item
            })
            .catch((e) => {
                // reset the list if fails
                // TODO: How to inform user of fail?
                props.setCurrentPos(oldList)
            })

        // do the api call
    }

    const clickUndo = (isExclude: boolean, fromId: string, toId?: string) => {
        const oldList = [...currentPos]
        props.setCurrentPos(updatePoCxns(currentPos, fromId, 'incomplete'))

        removeLinkByIds(isExclude, fromId, toId)
            .then(() => {
                //loading item
            })
            .catch((e) => {
                // reset the list if fails
                // TODO: How to inform user of fail?
                props.setCurrentPos(oldList)
            })
    }

    const clickConfirm = (fromId: string, toId: string) => {
        const oldList = [...currentPos]
        const updatedList = updatePoCxns(
            currentPos,
            fromId,
            'complete',
            find(remainingPos, { id: toId })
        )
        props.setCurrentPos(updatedList)
        // do the api call
        api.createConnectedProductOption(fromId, toId, currentProduct.linkType)
            .then(() => {
                //loading element
            })
            .catch((e) => {
                // reset the list if fails
                // TODO: How to inform user of fail?
                props.setCurrentPos(oldList)
            })
    }

    const getList = getPoByType(byLinkState)
    return (
        <div>
            <UnassignedConnectionsTable
                poCxns={getList('incomplete')}
                linkType={currentProduct.linkType}
                remainingToPos={remainingPos}
                currentProduct={currentProduct}
                clickExclude={clickExclude}
                clickConfirm={clickConfirm}
            />
            <div className={styles.CxnPoBottom} />
            <CompletedPoMappingsTable
                mappingType={currentProduct.linkType}
                poCxns={mapFp(makeCompleted)(getList('complete'))}
                clickExclude={clickExclude}
                clickUndo={clickUndo}
            />
            <div className={styles.CxnPoBottom} />
            <ExcludedConnectionsTable
                excludedData={getList('exclude')}
                undoClick={clickUndo}
            />
        </div>
    )
}

// Returns only the product options that do not have an assignment
function getRemainingToPos(allPos: SimpleInfo[], pos: Required<PoCxn>[]) {
    if (cxnOptions.allowDuplicateToMappings) {
        return allPos
    }

    const poMap = keyByFp('id')(map(pos, (po) => po.toProductOption))
    return allPos.filter((v) => !poMap[v.id])
}

function getPoByType(pos: Dictionary<ProductOptionConnection[]>) {
    return (st: CompletedStatus) => pos[st] ?? []
}

// All this is doing is changing the typescript type so that programmers
// will not need to do checks on optional params
function makeCompleted(cxn: PoCxn): Required<PoCxn> {
    if (!cxn.toProductOption) {
        // ? this would be programmer error. We prob want to surface this.
        throw new Error('Something went wrong: 1002')
    }
    return {
        ...cxn,
        toProductOption: cxn.toProductOption,
    }
}
