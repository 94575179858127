import { createAction } from 'typesafe-actions'
import { FormName } from './forms.reducer'

export const open = createAction('ui/FORM_OPEN')<FormName>()
export const openWithMeta = createAction('ui/FORM_OPEN_WITH_META')<
    FormName,
    { id: string }
>()

export const close = createAction('ui/FORM_CLOSE')<FormName>()
