export namespace ProductEnum {
    export enum Category {
        Addon = 'Add On Services',
        Base = 'Base Products',
        Misc = 'Miscellaneous Products',
    }

    export enum CustomerType {
        All = 'All',
        Business = 'Business',
        Individual = 'Individual',
    }

    export enum Group {
        B2C = 'B2C',
        B2B = 'B2B',
        Lifetime = 'Lifetime',
        Basic = 'Basic',
        Plus = 'Plus',
    }

    export enum LibraryScope {
        Full = 'Full',
        Limited = 'Limited',
        None = 'None',
    }

    export enum Type {
        Event = 'Event',
        Flow = 'Flow',
        Gift = 'Gift',
        Proserv = 'ProServ',
        Skills = 'Skills',
        Acg = 'ACG',
    }
}
