import omit from 'object.omit'
import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { View } from '../../containers/product-details/product-options/ProductOptions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { ProductOption } from '../typings/product-option.interface'
import {
    isNew,
    mapPayloadById,
    mergePayload,
    now,
    setPayload,
    setRequestError,
    setSaveInProgress,
} from '../utils'

export type ProductOptionsById = Common.ItemsById<ProductOption.AllFields>

export interface ProductOptionsDefaultState {
    byId: ProductOptionsById
    error: Common.ApiErrors
    fieldOptions: ProductOption.FieldOptions
    isFetching: boolean
    isSaving: boolean
    receivedAt: Common.ReceivedAt
    reviewImport: {
        data: ProductOption.ImportResponse[]
        freeTrial: {
            [id: string]: boolean
        }
        step: number
    }
    view: ProductOption.Layout
}

export const defaultState: ProductOptionsDefaultState = {
    byId: {},
    error: { onFetch: null, onSave: null },
    fieldOptions: {
        billingPeriod: [],
        chargeModel: [],
        chargeType: [],
        countries: {},
        currencies: [],
        customerSource: [],
        uom: [],
    },
    isFetching: false,
    isSaving: false,
    receivedAt: null,
    reviewImport: {
        data: [],
        freeTrial: {},
        step: 1,
    },
    view: {
        layout: View.CARD,
    },
}

export const byIdReducer = createReducer(defaultState.byId)
    .handleAction(
        [
            actions.productOptions.update.request,
            actions.products.deleteDraft.request,
        ],
        (state, action) => setSaveInProgress(state, action.payload.id)
    )
    .handleAction(actions.productOptions.get.success, (state, action) =>
        mapPayloadById(action.payload)
    )
    .handleAction(
        [
            actions.productOptions.create.success,
            actions.productOptions.sync.success,
        ],
        (state, action) => setPayload(state, action.payload)
    )
    .handleAction(actions.products.clone.success, (state, action) => ({
        ...state,
        ...mapPayloadById(action.payload.data.productOptions),
    }))
    .handleAction(
        [
            actions.productOptions.update.success,
            actions.productOptions.updateStatus.success,
        ],
        (state, action) =>
            mergePayload(state, action.payload.id, action.payload)
    )
    .handleAction(
        actions.productOptions.deleteDraft.success,
        (state, action) => {
            const { payload } = action

            if (isNew(payload)) {
                return omit(state, payload.id)
            }

            return mergePayload(state, payload.id, { draft: null })
        }
    )
    .handleAction(
        [
            actions.productOptions.update.failure,
            actions.productOptions.deleteDraft.failure,
        ],
        (state, action) =>
            setRequestError(state, action.payload.id, action.payload.error)
    )
    .handleAction(actions.productOptions.clearErrorFor, (state, action) =>
        setRequestError(state, action.payload, null)
    )

export const errorReducer = createReducer(defaultState.error)
    .handleAction(
        [actions.productOptions.get.request, actions.productOptions.clearError],
        () => ({ onFetch: null, onSave: null })
    )
    .handleAction(actions.productOptions.get.failure, (state, action) => ({
        ...state,
        onFetch: action.payload,
    }))
    .handleAction(actions.productOptions.create.failure, (state, action) => ({
        ...state,
        onSave: action.payload,
    }))

export const fieldOptionsReducer = createReducer(
    defaultState.fieldOptions
).handleAction(
    actions.productOptions.getFields.success,
    (state, action) => action.payload
)

export const isFetchingReducer = createReducer(defaultState.isFetching)
    .handleAction(actions.productOptions.get.request, () => true)
    .handleAction(
        [
            actions.productOptions.get.success,
            actions.productOptions.get.failure,
        ],
        () => false
    )

export const isSavingReducer = createReducer(defaultState.isSaving)
    .handleAction(
        [
            actions.productOptions.create.request,
            actions.productOptions.startImport.request,
            actions.productOptions.submitImport.request,
        ],
        () => true
    )
    .handleAction(
        [
            actions.productOptions.create.success,
            actions.productOptions.create.failure,
            actions.productOptions.startImport.success,
            actions.productOptions.startImport.failure,
            actions.productOptions.submitImport.success,
            actions.productOptions.submitImport.failure,
        ],
        () => false
    )

export const receivedAtReducer = createReducer(
    defaultState.receivedAt
).handleAction(actions.productOptions.get.success, now)

export const reviewImportReducer = createReducer(defaultState.reviewImport)
    .handleAction(
        actions.productOptions.startImport.success,
        (state, action) => {
            return {
                ...state,
                data: action.payload.data,
                freeTrial: {},
            }
        }
    )
    .handleAction(
        actions.productOptions.setImportReviewStep,
        (state, action) => ({
            ...state,
            step: action.payload,
        })
    )
export const OptionsViewReducer = createReducer(defaultState.view).handleAction(
    actions.productOptions.setView,
    (state, action) => action.payload
)

export const productOptionsReducer = combineReducers({
    byId: byIdReducer,
    error: errorReducer,
    fieldOptions: fieldOptionsReducer,
    isFetching: isFetchingReducer,
    isSaving: isSavingReducer,
    receivedAt: receivedAtReducer,
    reviewImport: reviewImportReducer,
    view: OptionsViewReducer,
})

// Redux Types
export type ProductOptionsAction = ActionType<typeof actions.productOptions>
export type ProductOptionsState = StateType<typeof productOptionsReducer>
