import { SecureRoute } from '@okta/okta-react'
import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { AuthenticationCheck } from '../Authentication/Authentication'
import config from '../config'
import { Notifications } from '../containers/notifications/Notifications'
import { HomeRouter } from './HomeRouter'

export const RootRouter: React.FC = () => (
    <Router basename={config.routerBasePath}>
        <AuthenticationCheck>
            <Switch>
                <SecureRoute path="/" component={HomeRouter} />
            </Switch>
            <Notifications />
        </AuthenticationCheck>
    </Router>
)
