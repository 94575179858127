import autobind from 'auto-bind'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import config from '../config'
import { ChangelogDto } from '../containers/changelog/ChangeLog'
import { View } from '../containers/product-details/product-options/ProductOptions'
import { DvsBackfillDto } from '../containers/tools/adhoc/dvs-backfill/AdhocDvsBackfill'
import { SupportedCurrencyCodes } from '../store/currency/currency.reducer'
import { CommonEnum } from '../store/typings/common.enum'
import { ContentLibrary } from '../store/typings/content-libraries.interface'
import { Feature } from '../store/typings/features.interface'
import { PreferredCurrency } from '../store/typings/preferred-currency.interface'
import { ProductLink } from '../store/typings/product-link.interface'
import { ProductOptionLink } from '../store/typings/product-option-link.interface'
import { ProductOption } from '../store/typings/product-option.interface'
import { Product } from '../store/typings/product.interface'
import {
    ProductOptionConnection,
    SimpleInfo,
} from './../containers/connected-products/product-options/cxn-po.interface'
import {
    AllPCxnsByTypeApi,
    BestGuessParams,
    ExcludedPO,
    LinkGuess,
} from './api.interface'
import {
    ExcludeProductOptionLinkDto,
    PoCxnInfoDto,
    PoRemoveByIdsDto,
} from './api/dto/connected-Products.dto'

type FieldsWithoutCountries = Omit<ProductOption.FieldOptions, 'countries'>

type PoLink = ProductOptionLink.Entity
type PoCxn = ProductOptionConnection

const { Link } = CommonEnum
type Link = CommonEnum.Link

export type ExcludedLinksResponse = {
    createdTimestamp: string
    fromProductOption: string
    id: string
    modifiedTimestamp: string
    productName: string
    properties: any
    toProductOption: string
    type: CommonEnum.Link
}

export class ApiService {
    // #region Top
    api: AxiosInstance

    baseConfig: AxiosRequestConfig = {
        baseURL: `${config.domain}${config.isAlpha ? '/alpha/catalog/app' : '/catalog/app'}`,
        withCredentials: true,
    }

    constructor() {
        this.api = axios.create(this.baseConfig)

        this.api.interceptors.response.use(
            function (response) {
                return response.data
            },
            function (error) {
                return Promise.reject(error.response.data)
            }
        )
        autobind(this)
    }

    // ----------------------- Products ----------------------- //

    getProducts(): Promise<Product.AllFields[]> {
        return this.api.get('/products')
    }

    getProductProductOptions(id: string) {
        const params = { id }
        return this.api.get<
            ProductOption.AllFields[],
            ProductOption.AllFields[]
        >('products/product-options', { params })
    }

    createProductWithDraft(
        product: Product.CreateRequest
    ): Promise<Product.AllFields> {
        return this.api.post<void, Product.AllFields>(
            '/products/draft',
            product
        )
    }

    updateProductDraft(
        draft: Product.UpdateRequest
    ): Promise<Product.AllFields> {
        return this.api.put<void, Product.AllFields>('/products/draft', draft)
    }

    deleteProductDraft(payload: { id: string }): Promise<Product.AllFields> {
        return this.api.request<void, Product.AllFields>({
            data: payload,
            method: 'DELETE',
            url: '/products/draft',
        })
    }

    cloneProduct(productId: string): Promise<Product.AllFieldsWithChildren> {
        return this.api.post<void, Product.AllFieldsWithChildren>(
            '/products/clone',
            {
                id: productId,
            }
        )
    }

    assignLibrary(
        payload: Product.ContentLibraryRequest
    ): Promise<Product.AllFields> {
        return this.api.put<void, Product.AllFields>(
            '/products/content-libraries/assign',
            payload
        )
    }

    unassignLibrary(
        payload: Product.ContentLibraryRequest
    ): Promise<Product.AllFields> {
        return this.api.put<void, Product.AllFields>(
            '/products/content-libraries/unassign',
            payload
        )
    }

    toggleFeatures(
        payload: Product.FeaturesRequest
    ): Promise<Product.AllFields> {
        return this.api.put('/products/features', payload)
    }

    syncNewProductToZuora(payload: {
        id: string
        lastModified: string
    }): Promise<Product.AllFields> {
        return this.api.post<void, Product.AllFields>('/products/sync', payload)
    }

    syncExistingProductToZuora(payload: {
        id: string
        lastModified: string
    }): Promise<Product.AllFields> {
        return this.api.put<void, Product.AllFields>('/products/sync', payload)
    }

    activateProduct(payload: { id: string }): Promise<Product.AllFields> {
        return this.api.post<void, Product.AllFields>(
            '/products/status/active',
            payload
        )
    }

    deactivateProduct(payload: { id: string }): Promise<Product.AllFields> {
        return this.api.post<void, Product.AllFields>(
            '/products/status/inactive',
            payload
        )
    }

    getProductFields(): Promise<Product.FieldOptions> {
        return this.api.get<void, Product.FieldOptions>('/products/fields')
    }

    // ----------------------- Product Options ----------------------- //

    getProductOptions(): Promise<ProductOption.AllFields[]> {
        return this.api.get('/product-options')
    }

    createProductOptionWithDraft(
        productOption: ProductOption.CreateRequest
    ): Promise<ProductOption.AllFields> {
        return this.api.post<void, ProductOption.AllFields>(
            '/product-options/draft',
            productOption
        )
    }

    updateProductOptionDraft(
        productOption: ProductOption.UpdateRequest
    ): Promise<ProductOption.AllFields> {
        return this.api.put<void, ProductOption.AllFields>(
            '/product-options/draft',
            productOption
        )
    }

    deleteProductOptionDraft(payload: {
        id: string
    }): Promise<ProductOption.AllFields> {
        return this.api.request<void, ProductOption.AllFields>({
            data: payload,
            method: 'DELETE',
            url: '/product-options/draft',
        })
    }

    syncNewProductOptionToZuora(payload: {
        id: string
        lastModified: string
    }): Promise<ProductOption.AllFields> {
        return this.api.post<void, ProductOption.AllFields>(
            '/product-options/sync',
            payload
        )
    }

    syncExistingProductOptionToZuora(payload: {
        id: string
        lastModified: string
    }): Promise<ProductOption.AllFields> {
        return this.api.put<void, ProductOption.AllFields>(
            '/product-options/sync',
            payload
        )
    }

    activateProductOption(payload: {
        id: string
    }): Promise<ProductOption.AllFields> {
        return this.api.post<void, ProductOption.AllFields>(
            '/product-options/status/active',
            payload
        )
    }

    deactivateProductOption(payload: {
        id: string
    }): Promise<ProductOption.AllFields> {
        return this.api.post<void, ProductOption.AllFields>(
            '/product-options/status/inactive',
            payload
        )
    }

    getProductOptionFields(): Promise<FieldsWithoutCountries> {
        return this.api.get<void, FieldsWithoutCountries>(
            '/product-options/fields'
        )
    }

    getCountryOptions(): Promise<ProductOption.CountryOption[]> {
        return this.api.get<void, ProductOption.CountryOption[]>(
            '/other/countries'
        )
    }

    exportProductOptions(productId: string): Promise<any> {
        return this.api.post(
            '/product-options/export',
            { productId },
            {
                responseType: 'blob',
            }
        )
    }

    exportAllProductOptions(): Promise<any> {
        return this.api.post('/product-options/export/all', {
            responseType: 'blob',
        })
    }

    startProductOptionImport(formData: FormData) {
        return this.api.post<void, any>('/csv/import', formData)
    }

    submitProductOptionImport(payload: ProductOption.ImportSubmit[]) {
        return this.api.post<void, any>('/product-options/import', payload)
    }

    setProductOptionView(payload: any) {
        if (payload.layout === View.LIST) {
            window.NREUM.addPageAction('clickedProductOptionViewList', payload)
        } else if (payload.layout === View.CARD) {
            window.NREUM.addPageAction('clickedProductOptionViewCard', payload)
        }
    }

    // #endregion
    // ----------------------- Connected Products ----------------------- //

    getConnectedProducts(id: string): Promise<ProductLink.RelationDetail[]> {
        return this.api.get<void, ProductLink.RelationDetail[]>(
            '/products/link',
            { params: { productId: id } }
        )
    }

    createConnectedProduct(
        dto: ProductLink.CreateRequest
    ): Promise<ProductLink.Entity> {
        return this.api.post<void, ProductLink.Entity>('/products/link', dto)
    }

    updateConnectedProduct(
        dto: ProductLink.UpdateRequest
    ): Promise<ProductLink.Entity> {
        return this.api.put<void, ProductLink.Entity>('/products/link', dto)
    }

    deleteConnectedProduct(linkId: string): Promise<void> {
        return this.api.request<void, void>({
            data: {
                id: linkId,
            },
            method: 'DELETE',
            url: '/products/link',
        })
    }

    getConnectedProductsList(): Promise<AllPCxnsByTypeApi> {
        return this.api.get<AllPCxnsByTypeApi, AllPCxnsByTypeApi>(
            '/products/links'
        )
    }

    // TODO: FIX ME PLS
    getCompletedConnectedPoMappings(
        dto: ProductLink.CompletedPoMappingRequest
    ): Promise<any[]> {
        return this.api.get<void, any[]>('/products/completedlinks', {
            params: {
                fromProduct: dto.fromProduct,
                toProduct: dto.toProduct,
                type: dto.type,
            },
        })
    }

    /**
     * Attempts to derive the mappings for two given product ids
     */
    async getProductsGuesslinks(params: BestGuessParams) {
        return this.api.get<void, LinkGuess[]>('/products/guesslinks', {
            params,
        })
    }
    /**
     * Attempts to derive the mappings for two given product ids
     */
    async getCompletedLinks(params: BestGuessParams) {
        const cfg = { params }
        return this.api.get<PoLink[], PoLink[]>('/products/completedlinks', cfg)
    }

    getExcludedProductOptions(productId: string): Promise<ExcludedPO[]> {
        return this.api.get<ExcludedPO[], ExcludedPO[]>(
            `/products/exclude?productId=${productId}`
        )
    }

    // ----------------------- Connected Product Options ----------------------- //

    getConnectedProductOptions(id: string) {
        return this.api.get<void, ProductOptionLink.RelationDetail[]>(
            '/product-options/link',
            { params: { productOptionId: id } }
        )
    }

    createConnectedProductOption(from: string, to: string, type: Link) {
        const dto: ProductOptionLink.CreateRequest = {
            fromProductOption: from,
            toProductOption: to,
            type: type,
            properties: {},
        }
        return this.api.post<void, ProductOptionLink.Entity>(
            '/product-options/link',
            dto
        )
    }

    updateConnectedProductOption(dto: ProductOptionLink.UpdateRequest) {
        return this.api.put<void, ProductOptionLink.Entity>(
            '/product-options/link',
            dto
        )
    }

    /**
     * Delete a link by the link table id, not by product option id
     * @param linkId - id column in link table
     */
    deleteConnectedProductOption(linkId: string): Promise<void> {
        return this.api.request<void, void>({
            data: { id: linkId },
            method: 'DELETE',
            url: '/product-options/link',
        })
    }

    excludePoLink(fromProductOption: string) {
        const dto: ExcludeProductOptionLinkDto = {
            fromProductOption,
            properties: {},
            type: Link.Exclude,
        }
        return this.api.post<void, ProductOptionLink.Entity>(
            '/product-options/link/exclude',
            dto
        )
    }

    // -------------------- Connected Products More -------------------- //
    async getPoConnections(dto: PoCxnInfoDto) {
        const route = '/cp/product-options/for-products'
        return this.api.get<void, PoCxn[]>(route, { params: dto })
    }

    removeLinkByIds(isExclude: boolean, from: string, to?: string) {
        const dto: PoRemoveByIdsDto = { from, to, isExclude }
        const route = '/cp/product-options/by-ids'
        return this.api.delete<void, string>(route, { data: dto })
    }

    getSimpleProduct(id: string) {
        const route = '/cp/product/simple'
        return this.api.get<void, SimpleInfo>(route, { params: { id } })
    }

    // #region
    // ----------------------- Content Libraries ----------------------- //

    getContentLibrary(id: string): Promise<ContentLibrary> {
        return this.api.request<void, ContentLibrary>({
            method: 'GET',
            url: `/content-libraries/${id}`,
        })
    }

    // ----------------------- Features ----------------------- //
    // TODO: Typing here is wrong. Needs to be array for return type
    getFeatures(): Promise<Feature> {
        return this.api.get<void, Feature>('/features')
    }

    // ----------------------- Currency ----------------------- //

    getPreferredCurrencies(): Promise<PreferredCurrency> {
        return this.api.get<void, PreferredCurrency>('/currency')
    }

    getSupportedCurrencies(): Promise<SupportedCurrencyCodes> {
        return this.api.get<void, SupportedCurrencyCodes>('/currency/supported')
    }

    createPreferredCurrency(
        dto: PreferredCurrency
    ): Promise<PreferredCurrency> {
        return this.api.post<PreferredCurrency, PreferredCurrency>(
            '/currency',
            dto
        )
    }

    updatePreferredCurrency(
        dto: PreferredCurrency
    ): Promise<PreferredCurrency> {
        return this.api.put<PreferredCurrency, PreferredCurrency>(
            '/currency',
            dto
        )
    }

    // ----------------------- Jobs ----------------------- //

    adhocFeedback() {
        return this.api.get<any>('/jobs/adhoc/info')
    }

    adhocGetJobs() {
        return this.api.get<any>('/jobs/adhoc/all')
    }

    productOptionImportFeedback() {
        return this.api.get<any>('/jobs/info', {
            params: { queue: 'manage:po' },
        })
    }

    productOptionImportGetJobs() {
        return this.api.get<any>('/jobs', {
            params: { queue: 'manage:po' },
        })
    }

    cleanAllJobs() {
        return this.api.get<any>('/jobs/clean')
    }

    // ----------------------- Other ----------------------- //

    downloadChangeLog(dto: ChangelogDto): Promise<any> {
        return this.api.post<void, any>('/reports/download', dto, {
            responseType: 'blob',
        })
    }

    backfillDvsDatabaseSync(dto: DvsBackfillDto): Promise<any> {
        return this.api.post<void, any>('/other/db-sync', dto)
    }

    // ----------------------- App URLs ----------------------- //

    getProductUrl(productId: string) {
        return `${config.domain}${config.routerBasePath}products/${productId}`
    }

    getProductOptionUrl(productId: string, optionId: string) {
        return `${this.getProductUrl(productId)}#${optionId}`
    }

    getLocalizedProductOptionUrl(productId: string, optionId: string) {
        return `${this.getProductUrl(productId)}/localized#${optionId}`
    }

    getProductContentLibrariesUrl(productId: string) {
        return `${this.getProductUrl(productId)}/content-libraries`
    }

    getProductFeaturesUrl(productId: string) {
        return `${this.getProductUrl(productId)}/features`
    }

    getRelativeUrl(url: string) {
        return url.replace(`${config.domain}${config.routerBasePath}`, '/')
    }
}
// #endregion

export default new ApiService()
