import { Card } from 'antd'
import React from 'react'
import { SupportedCurrencyDetail } from '../../../store/currency/currency.reducer'
import { CurrencyEnum } from '../../../store/typings/currency.enum'
import styles from './SupportedCurrency.module.scss'

interface Props {
    currency: SupportedCurrencyDetail
}

const bodyStyle = {
    padding: '10px 12px',
}

export const SupportedCurrency: React.FC<Props> = ({ currency }) => {
    return (
        <Card className={styles.card} bodyStyle={bodyStyle}>
            <div className={styles.container}>
                <div className={styles.symbol}>{currency.symbol}</div>
                <div>
                    <div className={styles.currencyName}>
                        {currency.displayName}
                    </div>
                    <div className={styles.defaultText}>
                        {CurrencyEnum.isDefaultCurrency(currency.code)
                            ? 'Default currency'
                            : null}
                    </div>
                </div>
            </div>
        </Card>
    )
}
