import { Col, Row, Select, Typography } from 'antd'
import { props } from 'ramda'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrimaryButton } from '../../../../components/buttons/Buttons'
import { selectAdhoc } from '../../../../store/adhoc/adhoc.selectors'
import { actions } from '../../../../store/root-action'
import { AdminTools } from '../../../../store/typings/admin-tools.interface'
import { CommonEnum } from '../../../../store/typings/common.enum'
import { formatTimestamp } from '../../../../store/utils'
import { BackToTools } from '../../BackToTools'
import styles from './AdhocDvsBackfill.module.scss'

const { Title, Paragraph } = Typography

type Props = AdminTools.Tool

export interface DvsBackfillDto {
    target: CommonEnum.Target
}

export const AdhocDvsBackfill: React.FC<Props> = ({ description, title }) => {
    const dispatch = useDispatch()
    const adhoc = useSelector(selectAdhoc)
    const [processing, setProcessing] = useState(false)
    const [target, setTarget] = useState<CommonEnum.Target>()

    const backfill = async () => {
        if (target) {
            setProcessing(true)
            dispatch(
                actions.adhoc.runDvsBackfill.request({
                    target,
                })
            )
        }
    }

    const options = [
        ...Object.values(CommonEnum.Target).map((target: string) => {
            return { label: target, value: target }
        }),
    ]

    return (
        <div className={styles.container}>
            <BackToTools />

            <Title>{title}</Title>
            <Paragraph>{description}</Paragraph>

            <Row gutter={[12, 24]} className={'row-gap-24'} align="middle">
                <Col>
                    <Select
                        allowClear
                        options={options}
                        onChange={(value: CommonEnum.Target) => {
                            setProcessing(false)
                            return setTarget(value)
                        }}
                        placeholder="Select target"
                        showArrow
                        size="large"
                        {...props}
                    />
                </Col>
                <Col>
                    <PrimaryButton
                        onClick={backfill}
                        loading={adhoc.dvsBackfill.isSaving}
                        disabled={!target || processing}
                        {...props}
                    >
                        Run Backfill
                    </PrimaryButton>
                </Col>
                {adhoc.dvsBackfill.receivedAt ? (
                    <Col>
                        <b>
                            Adhoc started at:{' '}
                            {formatTimestamp(adhoc.dvsBackfill.receivedAt)}
                        </b>
                    </Col>
                ) : null}
            </Row>
        </div>
    )
}
