import { Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/es/table'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { strings } from '../../../constants'
import { selectProductFilters } from '../../../store/filters/filters.selectors'
import { ProductOptionsById } from '../../../store/product-options/product-options.reducer'
import { CommonEnum } from '../../../store/typings/common.enum'
import { Product } from '../../../store/typings/product.interface'
import { formatTimestamp, isDraft } from '../../../store/utils'
import { Highlight } from '../../highlight/Highlight'
import { Status } from '../../status/Status'
import { filterProducts } from '../utils'
import styles from './ProductsTable.module.scss'

export enum ColumnHeader {
    CustomerType = 'CUSTOMER TYPE',
    ModifiedDate = 'MODIFIED DATE',
    ProductName = 'PRODUCT NAME',
    ProductOptions = 'PRODUCT OPTIONS',
    ProductType = 'TYPE',
    Sku = 'SKU',
    Status = 'STATUS',
}

export enum ColumnKey {
    CustomerType = 'customerType',
    ModifiedTimestamp = 'modifiedTimestamp',
    Name = 'name',
    Options = 'options',
    Sku = 'sku',
    Status = 'status',
    Type = 'type',
}

export type DataIndex = Exclude<ColumnKey, ColumnKey.Options>

export const PAGE_SIZE = 20

interface OwnProps {
    loading: boolean
    productOptionsById: ProductOptionsById
    products: Product.AllFields[]
}
type Props = OwnProps

const selectProductOptionCountsByStatus = (
    productOptionsById: ProductOptionsById,
    productId: string
) => {
    return Object.values(productOptionsById)
        .map((o) => o.payload)
        .filter((o) => o.productCatalogId === productId)
        .reduce(
            (accum: any, option) => {
                accum[option.status]++

                if (isDraft(option)) {
                    accum['Draft']++
                }
                return accum
            },
            {
                Active: 0,
                Draft: 0,
            }
        )
}

export const ProductsTable: React.FC<Props> = ({
    loading,
    productOptionsById,
    products,
}) => {
    const filters = useSelector((state: RootState) =>
        selectProductFilters(state)
    )

    const columns: ColumnProps<Product.AllFields>[] = [
        {
            dataIndex: ColumnKey.Status,
            key: ColumnKey.Status,
            render: (status: CommonEnum.State, product: Product.AllFields) => {
                const { draft, meta } = product
                let badge = <Status text={meta.state} />

                // New product draft? Only 1 status.
                if (draft?.meta.isNew) {
                    badge = <Status text={draft.meta.state} />
                }

                // Existing product draft? Show 2 statuses.
                if (draft?.meta.isNew === false) {
                    badge = (
                        <>
                            <Status text={meta.state} />
                            <Status text={draft.meta.state} />
                        </>
                    )
                }

                return badge
            },
            sorter: (a, b) => a.status.localeCompare(b.status),
            title: ColumnHeader.Status,
            width: 120,
        },
        {
            dataIndex: ColumnKey.Name,
            ellipsis: true,
            key: ColumnKey.Name,
            render: (text: string, product: Product.AllFields) => {
                return (
                    <Tooltip
                        mouseEnterDelay={0.5}
                        mouseLeaveDelay={0.1}
                        overlayClassName={styles.tooltip}
                        placement="bottomLeft"
                        title={product.name}
                    >
                        <Link to={`/products/${product.id}`} id={product.id}>
                            <Highlight
                                searchWords={[filters.name]}
                                textToHighlight={product.name}
                            />
                        </Link>
                    </Tooltip>
                )
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
            title: ColumnHeader.ProductName,
            width: 400,
        },
        {
            dataIndex: ColumnKey.Sku,
            ellipsis: true,
            key: ColumnKey.Sku,
            render: (text: string) => {
                return (
                    <span title={text.toString()}>
                        <Highlight
                            searchWords={[filters.name]}
                            textToHighlight={text.toString()}
                        />
                    </span>
                )
            },
            sorter: (a, b) => a.sku.localeCompare(b.sku),
            title: ColumnHeader.Sku,
            width: 300,
        },
        {
            dataIndex: ColumnKey.CustomerType,
            ellipsis: true,
            key: ColumnKey.CustomerType,
            sorter: (a, b) => a.customerType.localeCompare(b.customerType),
            title: ColumnHeader.CustomerType,
            width: 180,
        },
        {
            dataIndex: ColumnKey.Type,
            ellipsis: true,
            key: ColumnKey.Type,
            sorter: (a, b) => a.type.localeCompare(b.type),
            title: ColumnHeader.ProductType,
            width: 105,
        },
        {
            dataIndex: ColumnKey.Options,
            ellipsis: false,
            key: ColumnKey.Options,
            render: (text: string, product: Product.AllFields) => {
                const counts = selectProductOptionCountsByStatus(
                    productOptionsById,
                    product.id
                )

                return (
                    <>
                        {Object.entries(counts)
                            .filter(([_, total]) => total)
                            .map(([status, total]) => {
                                return (
                                    <div key={status}>
                                        {total} {status}
                                    </div>
                                )
                            })}
                    </>
                )
            },
            title: ColumnHeader.ProductOptions,
            width: 100,
        },
        {
            dataIndex: ColumnKey.ModifiedTimestamp,
            defaultSortOrder: 'descend',
            key: ColumnKey.ModifiedTimestamp,
            render: (_: string, product: Product.AllFields) => (
                <div
                    title={product.modifiedTimestamp}
                    style={{ textAlign: 'right' }}
                >
                    {formatTimestamp(product.modifiedTimestamp)}
                </div>
            ),
            sorter: (a, b) =>
                a.modifiedTimestamp.localeCompare(b.modifiedTimestamp),
            title: ColumnHeader.ModifiedDate,
            width: 140,
        },
    ]

    const filteredProducts = useMemo(
        () => filterProducts(products, filters),
        [products, filters]
    )

    const filteredResults = () =>
        filteredProducts.length !== products.length
            ? strings.filterResults + ': ' + filteredProducts.length
            : strings.filterResultsAll

    const tableProps = {
        className: styles.productsTable,
        columns,
        dataSource: filteredProducts,
        loading,
        pagination: { defaultPageSize: PAGE_SIZE },
        rowKey: 'id',
        scroll: { x: 768 },
    }

    return (
        <>
            <div className={styles.results}>{filteredResults()}</div>
            <Table {...tableProps} />
        </>
    )
}
