import { Select as AntSelect } from 'antd'
import { SelectProps as AntSelectProps } from 'antd/lib/select'
import React from 'react'
import styles from './Select.module.scss'

interface SelectProps extends AntSelectProps<any> {
    options: any[]
}

export class Select extends React.Component<SelectProps> {
    render() {
        const { options, ...rest } = this.props
        return (
            <AntSelect
                size="large"
                allowClear
                {...rest}
                className={styles.select}
            >
                {options.map((option: string) => (
                    <AntSelect.Option key={option} value={option}>
                        {option}
                    </AntSelect.Option>
                ))}
            </AntSelect>
        )
    }
}
