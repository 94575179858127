import { put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { actions } from '../root-action'
import { Feature } from '../typings/features.interface'

export function* getFeaturesSaga() {
    try {
        const features: Feature[] = yield api.getFeatures()
        yield put(actions.features.get.success(features))
    } catch (error) {
        yield put(actions.features.get.failure(error))
    }
}

export function* featuresWatcher() {
    yield takeEvery(getType(actions.features.get.request), getFeaturesSaga)
}
