import { Col, Collapse, Row } from 'antd'
import React from 'react'

const { Panel } = Collapse

interface OwnProps {
    detail: any
}
type Props = OwnProps

export const Detail: React.FC<Props> = ({ detail }) => {
    const key = 'detail'

    return (
        <Row gutter={[12, 24]} className={'row-gap-24'}>
            <Col flex={1}>
                <Collapse defaultActiveKey={[]}>
                    <Panel header="View Detail" key={key}>
                        {detail}
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    )
}
