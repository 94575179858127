import { notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Redirect,
    Route,
    Switch,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import { compose } from 'redux'
import { RootState } from 'typesafe-actions'
import { hasData } from '../containers/hoc/hasData'
import { ContentLibraries } from '../containers/product-details/content-libraries/ContentLibraries'
import { Features } from '../containers/product-details/features/Features'
import { ProductHeader } from '../containers/product-details/product-header/ProductHeader'
import { LocalizedProductOptions } from '../containers/product-details/product-options/LocalizedProductOptions'
import { ProductOptions } from '../containers/product-details/product-options/ProductOptions'
import { NotFound } from '../containers/router/NotFound'
import { defaultState as alertsDefaultState } from '../store/alerts/alerts.reducer'
import { defaultState } from '../store/filters/filters.reducer'
import { selectOptionFilterDataByProductId } from '../store/product-options/product-options.selectors'
import { selectProductWithMeta } from '../store/products/products.selectors'
import { actions } from '../store/root-action'

export interface ProductDetailsParams {
    productId: string
}

export const ProductDetailsRouter: React.FC = compose(
    hasData
    // withHeaderAndFooter
)(() => {
    const { path, url } = useRouteMatch()
    const { productId } = useParams<ProductDetailsParams>()

    const [clonedProductId, setClonedProductId] = useState<string | null>(null)

    const dispatch = useDispatch()

    const product = useSelector((state: RootState) =>
        selectProductWithMeta(state, productId)
    )

    const optionIds = useSelector((state: RootState) =>
        selectOptionFilterDataByProductId(state, productId)
    )

    useEffect(() => {
        return () => {
            // Reset filters on product change (stop persisting)
            dispatch(
                actions.filters.filterProductOption({
                    ...defaultState.productOption,
                })
            )
            dispatch(
                actions.filters.filterLocalizedProductOption({
                    ...defaultState.localizedProductOption,
                })
            )
            dispatch(actions.alerts.clear(alertsDefaultState))
            notification.destroy()
        }
    }, [dispatch, productId])

    if (!product) {
        return <NotFound />
    }

    if (clonedProductId) {
        return <Redirect to={clonedProductId} />
    }

    return (
        <>
            <ProductHeader
                optionIds={optionIds}
                product={product.payload}
                onClone={setClonedProductId}
            />
            <Switch>
                <Route exact path={path}>
                    <ProductOptions
                        productId={productId}
                        optionData={optionIds.default}
                    />
                </Route>
                {optionIds.localized.length > 0 ? (
                    <Route exact path={`${path}/localized`}>
                        <LocalizedProductOptions
                            optionData={optionIds.localized}
                        />
                    </Route>
                ) : null}
                <Route exact path={`${path}/content-libraries`}>
                    <ContentLibraries product={product.payload} />
                </Route>
                <Route exact path={`${path}/features`}>
                    <Features product={product} />
                </Route>
                <Route path="*">
                    <Redirect to={url} />
                </Route>
            </Switch>
        </>
    )
})
