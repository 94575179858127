import React from 'react'
import { LinkButton } from '../../../../components/buttons/Buttons'
import { CxnButtonLabels } from '../../connected-product.constants'
import {
    CompleteButtonIcon,
    DeleteButtonIcon,
    UndoButtonIcon,
} from '../../CxtComponents'
import { CompletedStatus } from '../cxn-po.interface'

export type ExcludeClick = (id: string) => void

export type UndoClick = (
    isExclude: boolean,
    fromId: string,
    toId?: string
) => void

export type ConfirmClick = (fromId: string, toId: string) => void

type ExcldBtnProps = {
    updater: ExcludeClick
    poId: string
    type: CompletedStatus
}
export const ExcludeConnectionButton = (props: ExcldBtnProps) => {
    // ? Currently the functionality from configured --> exclude doesnt work
    // ? to exclude, first unconfigure and then click exclude
    const isTemporarilyDisabled = props.type === 'complete'
    return (
        <LinkButton
            onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                props.updater(props.poId)
            }}
            icon={<DeleteButtonIcon />}
            disabled={isTemporarilyDisabled}
        >
            {CxnButtonLabels.Exclude}
        </LinkButton>
    )
}

type UndoBtnProps = {
    setUndo: UndoClick
    fromId: string
    isExclude: boolean
    toId?: string
}
export const UndoConnectionButton = (props: UndoBtnProps) => {
    return (
        <LinkButton
            onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                props.setUndo(props.isExclude, props.fromId, props.toId)
            }}
            icon={<UndoButtonIcon />}
        >
            {CxnButtonLabels.Unconfigure}
        </LinkButton>
    )
}
type ConfirmBtnProps = {
    setComplete: ConfirmClick
    fromId: string
    toId?: string
    disabled: boolean
}
export const ConfirmConnectionButton = (props: ConfirmBtnProps) => {
    return (
        <LinkButton
            disabled={props.disabled}
            onClick={(e: React.MouseEvent) => {
                if (props.toId) {
                    e.preventDefault()
                    props.setComplete(props.fromId, props.toId)
                }
            }}
            icon={<CompleteButtonIcon />}
        >
            {CxnButtonLabels.Confirm}
        </LinkButton>
    )
}
