import omit from 'object.omit'
import { RootState } from 'typesafe-actions'

export const selectContentLibraryById = (
    { contentLibraries }: RootState,
    id: string
) => contentLibraries.byId[id]?.payload

export const selectContentLibraryApiMeta = (
    { contentLibraries }: RootState,
    id: string
) => omit(contentLibraries.byId[id], 'payload')
