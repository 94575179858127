import { Dropdown as AntDropdown } from 'antd'
import React from 'react'

/*
This component effectively wraps children of the Ant Dropdown component in a Class component, to resolve a warning that Ant gives about incorrectly passing refs to function components (via a Trigger component that Dropdown renders).
See details here describing how there is no plan to fix this issue: https://github.com/react-component/trigger/pull/156
*/

export class ChildrenWrapper extends React.Component {
    render() {
        const { children, ...rest } = this.props
        return React.cloneElement(children as React.ReactElement, { ...rest })
    }
}

export const Dropdown = ({ children, ...rest }: any) => {
    return (
        <AntDropdown {...rest}>
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </AntDropdown>
    )
}
