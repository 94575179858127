import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../../store/root-action'
import { GeneralError } from './GeneralError'

export const FetchError = () => {
    const dispatch = useDispatch()

    const fetchData = () => {
        dispatch(actions.products.get.request())
        dispatch(actions.productOptions.get.request())

        dispatch(actions.products.getFields.request())
        dispatch(actions.productOptions.getFields.request())
    }

    return (
        <GeneralError
            title="Unable to get products, please try again."
            extra={
                <Button type="primary" onClick={fetchData}>
                    Retry
                </Button>
            }
        />
    )
}
