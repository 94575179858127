import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { validate as uuidValidate } from 'uuid'
import api from '../../../services/api.service'
import { Loader } from '../../loader/Loader'
import { SimpleInfo } from '../product-options/cxn-po.interface'
import { ConnectedPoContainer } from '../product-options/CxnPoContainer'

const { getSimpleProduct } = api

export const ConnectedProductLoader = () => {
    const { productId } = useParams<{ productId: string }>()

    const [fromProduct, setToProduct] = useState<SimpleInfo>()
    const [fail, setFail] = useState(false)

    useEffect(() => {
        // ! Attempts to prevent too many backend log errors. If problem, introduce redux to check if becomes issue.
        if (!uuidValidate(productId)) {
            return setFail(true)
        }

        getSimpleProduct(productId)
            .then(setToProduct)
            .catch(() => setFail(true))
    }, [productId])

    if (fail) {
        return <Redirect to={{ pathname: '/tools' }} />
    }

    if (!fromProduct) {
        return <Loader />
    }

    return <ConnectedPoContainer fromProduct={fromProduct} />
}
