import React from 'react'
import { strings } from '../../../constants'
import { FormName } from '../../../store/forms/forms.reducer'
import { actions } from '../../../store/root-action'
import { ProductForm, ProductFormProps } from './ProductForm'

export const ProductCreateForm: React.FC<ProductFormProps> = (props) => (
    <ProductForm
        initialValues={{
            customerFacingDescription: '',
            groups: [],
            isUserAssignable: false,
            level: 0,
            preserveProvisionedTerm: false,
        }}
        name={FormName.ProductCreate}
        submitAction={actions.products.create.request}
        submitText={strings.createSubmitBtn}
        title={strings.createProductFormTitle}
        {...props}
    />
)
