import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { CurrencyEnum } from '../typings/currency.enum'
import { PreferredCurrency } from '../typings/preferred-currency.interface'
import { mapPayloadById, setPayload } from '../utils'

export type SupportedCurrencyCodes = CurrencyEnum.Code[]
export type SupportedCurrencyDetail = {
    code: CurrencyEnum.Code
    name: CurrencyEnum.Name
    symbol: CurrencyEnum.Symbol
    displayName: string
}

export interface CurrencyDefaultState {
    preferred: Common.ItemsById<PreferredCurrency>
    supported: SupportedCurrencyCodes
}

export const defaultState: CurrencyDefaultState = {
    preferred: {},
    supported: [],
}

export const preferredReducer = createReducer(defaultState.preferred)
    .handleAction(actions.currency.getPreferred.success, (_, action) =>
        mapPayloadById<PreferredCurrency>(action.payload, undefined, 'country')
    )
    .handleAction(
        [
            actions.currency.createPreferred.success,
            actions.currency.updatePreferred.success,
        ],
        (state, action) => setPayload(state, action.payload.data, 'country')
    )

export const supportedReducer = createReducer(
    defaultState.supported
).handleAction(
    actions.currency.getSupported.success,
    (_, action) => action.payload
)

export const currencyReducer = combineReducers({
    preferred: preferredReducer,
    supported: supportedReducer,
})

// Redux Types
export type CurrencyAction = ActionType<typeof actions.currency>
export type CurrencyState = StateType<typeof currencyReducer>
