import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { SecondaryButton } from '../../../components/buttons/Buttons'
import { strings } from '../../../constants'
import { defaultState } from '../../../store/filters/filters.reducer'
import { selectProductFilters } from '../../../store/filters/filters.selectors'
import { actions } from '../../../store/root-action'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductEnum } from '../../../store/typings/product.enum'
import { CategoryFilter } from '../../filters/CategoryFilter'
import { CustomerTypeFilter } from '../../filters/CustomerTypeFilter'
import { NameFilter } from '../../filters/NameFilter'
import { StatusFilter } from '../../filters/StatusFilter'
import { TypeFilter } from '../../filters/TypeFilter'
import styles from './ProductFilterInputs.module.scss'

export const ProductFilterInputs: React.FC = () => {
    const dispatch = useDispatch()
    const filters = useSelector((state: RootState) =>
        selectProductFilters(state)
    )

    const setDefaultFilters = () =>
        dispatch(
            actions.filters.filterProduct({
                ...defaultState.product,
            })
        )
    const setNameFilter = (name: string) =>
        dispatch(actions.filters.filterProduct({ ...filters, name }))
    const setStateFilter = (state: CommonEnum.State[]) =>
        dispatch(actions.filters.filterProduct({ ...filters, state }))
    const setCustomerTypeFilter = (customerType: ProductEnum.CustomerType[]) =>
        dispatch(
            actions.filters.filterProduct({
                ...filters,
                customerType,
            })
        )
    const setTypeFilter = (type: ProductEnum.Type[]) =>
        dispatch(actions.filters.filterProduct({ ...filters, type }))
    const setCategoryFilter = (category: ProductEnum.Category[]) =>
        dispatch(actions.filters.filterProduct({ ...filters, category }))

    const onNameFilterChange = useCallback(setNameFilter, [dispatch, filters])
    const onStateFilterChange = useCallback(setStateFilter, [dispatch, filters])
    const onCustomerTypeFilterChange = useCallback(setCustomerTypeFilter, [
        dispatch,
        filters,
    ])
    const onTypeFilterChange = useCallback(setTypeFilter, [dispatch, filters])
    const onCategoryFilterChange = useCallback(setCategoryFilter, [
        dispatch,
        filters,
    ])
    const onFiltersReset = useCallback(setDefaultFilters, [dispatch])

    return (
        <div className={styles.container}>
            <Row
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
                className={'row-gap-18'}
            >
                <Col flex="1">
                    <label>{strings.search}</label>
                    <NameFilter
                        onChange={(e: any) =>
                            onNameFilterChange(e.target.value)
                        }
                        onSearch={onNameFilterChange}
                        placeholder={strings.filterNameSkuIDPlaceholder}
                        value={filters.name}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.status}</label>
                    <StatusFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onStateFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as CommonEnum.State[]
                            )
                        }
                        value={filters.state}
                    />
                </Col>
            </Row>
            <Row
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
                className={'row-gap-18'}
            >
                <Col flex="1">
                    <label>{strings.category}</label>
                    <CategoryFilter
                        onChange={(value: string, options: any | Array<any>) =>
                            onCategoryFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as ProductEnum.Category[]
                            )
                        }
                        value={filters.category}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.customerType}</label>
                    <CustomerTypeFilter
                        onChange={(value: string, options: any | Array<any>) =>
                            onCustomerTypeFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as ProductEnum.CustomerType[]
                            )
                        }
                        value={filters.customerType}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.productType}</label>
                    <TypeFilter
                        onChange={(value: string, options: any | Array<any>) =>
                            onTypeFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as ProductEnum.Type[]
                            )
                        }
                        value={filters.type}
                    />
                </Col>
                <Col className={styles.action} flex="0">
                    <SecondaryButton
                        icon={<CloseCircleOutlined />}
                        onClick={onFiltersReset}
                    >
                        {strings.resetFiltersBtn}
                    </SecondaryButton>
                </Col>
            </Row>
        </div>
    )
}
