import currencyJs from 'currency.js'
import React, { useCallback } from 'react'
import { CommonEnum } from '../../store/typings/common.enum'
import { CurrencyEnum } from '../../store/typings/currency.enum'
import { PriceOption } from '../../store/typings/price-option.interface'
import { ProductOption } from '../../store/typings/product-option.interface'
import { ProductOptionForm } from './ProductOptionForm'

type Props = {
    currentProductOption: ProductOption.AllFields
    optionId: string
    onSubmit: () => void
    productId: string
}

export interface PriceFieldsWithId extends PriceOption.FormFields {
    id: string
    taxCode: string | null
}

export type FormPriceOptions = Record<CurrencyEnum.Code, PriceFieldsWithId>

export const EditProductOptionForm: React.FC<Props> = ({
    currentProductOption,
    ...props
}) => {
    const configurePriceOptions = useCallback(() => {
        const configured = {} as FormPriceOptions
        for (const p of currentProductOption.priceOptions) {
            configured[p.currency] = {
                id: p.id,
                active: p.status === CommonEnum.Status.Active,
                convertsToPriceOptionId: p.convertsTo && p.convertsTo.id,
                price: currencyJs(p.price, { symbol: '' }).format(),
                taxCode: p.taxCode || null,
            }
        }

        return configured
    }, [currentProductOption])

    return (
        <ProductOptionForm
            title="Edit product option"
            initial={{
                ...currentProductOption,
                priceOptions: configurePriceOptions(),
            }}
            {...props}
        />
    )
}
