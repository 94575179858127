import { Table } from 'antd'
import { ColumnProps, TableProps } from 'antd/es/table'
import currency from 'currency.js'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormName } from '../../../store/forms/forms.reducer'
import { actions } from '../../../store/root-action'
import { CurrencyEnum } from '../../../store/typings/currency.enum'
import { PriceOption } from '../../../store/typings/price-option.interface'
import { Copy } from '../../copy/Copy'
import styles from './PriceOptions.module.scss'

const renderPrice = (value: string, code: CurrencyEnum.Code) =>
    `${currency(value, {
        symbol: CurrencyEnum.Symbol[code],
    }).format()}`

type Props = {
    priceOptions: PriceOption.AllFields[]
}

export const PriceOptions: React.SFC<Props> = ({ priceOptions }) => {
    const dispatch = useDispatch()
    const closeViewForm = useCallback(() => {
        dispatch(actions.forms.close(FormName.ProductOptionView))
    }, [dispatch])

    if (priceOptions.length < 1) {
        return null
    }

    const priceOptionColumns: ColumnProps<PriceOption.AllFields>[] = [
        {
            dataIndex: 'id',
            key: 'id',
            render: (priceOptionId: string) => (
                <Copy clipboard={priceOptionId} title="Copy Price Option ID?" />
            ),
            title: 'ID:',
            width: 70,
        },
        {
            dataIndex: 'price',
            key: 'price',
            render: (price: any, option: PriceOption.AllFields) =>
                renderPrice(price, option.currency),
            title: 'Price:',
            width: 70,
            className: 'price-options-right-aligned',
        },
        {
            dataIndex: 'currency',
            key: 'currency',
            title: 'Currency:',
            width: 70,
        },
        {
            dataIndex: 'taxCode',
            key: 'taxCode',
            title: 'Tax Code:',
            width: 320,
        },
        {
            dataIndex: 'convertsTo',
            key: 'convertsTo',
            render: (id: string | null, option: PriceOption.AllFields) => {
                if (!option.convertsTo) {
                    return null
                }

                return (
                    <Link
                        to={`/products/${option.convertsTo.productCatalogId}#${option.convertsTo.ratePlanCountryId}`}
                        onClick={closeViewForm}
                    >
                        {option.convertsTo.name} (
                        {renderPrice(
                            option.convertsTo.price,
                            option.convertsTo.currency
                        )}{' '}
                        {option.convertsTo.currency})
                    </Link>
                )
            },
            title: 'Converts to:',
        },
    ]

    const activePriceOptions = priceOptions.filter(
        (price: PriceOption.AllFields) => price.status === 'Active'
    )

    const priceOptionTableProps: TableProps<any> = {
        className: styles.priceOptions,
        columns: priceOptionColumns,
        dataSource: activePriceOptions,
        locale: { emptyText: 'No active pricing exists.' },
        pagination: false,
        rowKey: 'currency',
        title: () => `Price Options (${activePriceOptions.length})`,
    }

    return <Table {...priceOptionTableProps} />
}
