import React from 'react'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import appConfig from '../../config'
import { Environment } from '../environment/Environment'
import styles from './Header.module.scss'
import { User } from './User'

export const Header: React.FC = () => {
    const appBaseUrl = appConfig.routerBasePath

    return (
        <div className={styles.headerWrap}>
            <Environment label={appConfig.envLabel} />
            <div className={styles.header}>
                <a href={appBaseUrl + 'products'} title="Product Catalog">
                    <Logo />
                </a>
                <User />
            </div>
        </div>
    )
}
