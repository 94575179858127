/**
 * The 'container' will have more of the logic bits and redux (if needed) connection
 */
// ! --> PoContainer
// ----> CxnPoContainer
//    ------> CxnPoContent
import { filter } from 'lodash'
import { every, map } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../services/api.service'
import { Loader } from '../../loader/Loader'
import {
    isComplete,
    loadConnections,
    pCxnToDto,
} from '../connected-products.service'
import { ConnectedPoPageLayout } from './content-with-sidebar/CxnPoPageLayout'
import {
    Completion,
    CxnCompletions,
    PConnection,
    PoCompletion,
    ProductOptionConnection,
    SimpleInfo,
} from './cxn-po.interface'
import styles from './CxnPoContainer.module.scss'
import { ConnectedPoPageHeader } from './CxnPoPageHeader'

const notFoundMsg = (name: string) => `No product connections found for ${name}`

// keeps these from being created on each render
const areAllComplete = every<PoCompletion>({ isComplete: true })
const binaryBool = map((v: ProductOptionConnection) => (isComplete(v) ? 1 : 0))
const filterFn = (id: string) => (v: Completion) => v.toProductId !== id
const reduceFn = (count: number, p: Completion) =>
    areAllComplete(p.poCompletions) ? count + 1 : count

interface Props {
    fromProduct: SimpleInfo
}

export const ConnectedPoContainer = ({ fromProduct }: Props) => {
    const [issue, setIssue] = useState<string | null>(null)

    // ? What are all available products for sidebar?
    const [allProductConnections, setAll] = useState<PConnection[]>()

    // ? What is the currently active product that is being configured?
    // ? used for active sidebar
    const [currentProduct, setCurrentProduct] = useState<PConnection>()

    // ? What are all of the currently active product options
    const [currentPos, setCurrentPos] = useState<ProductOptionConnection[]>()

    // ? this used for percentages and top part informing number completed
    const [completionInfo, setCompletionInfo] = useState<CxnCompletions>({})

    // -------------------------------- Memo Counters -------------------------------- //
    // ? returns how many are complete but does NOT include currently selected Product. This is because the current product
    // ? is dynamic and can or cannot be complete. All of the rest of the products have a static completed status. When changing
    // ? to a different product this will recalculate.
    const partialComplete = useMemo(() => {
        if (!completionInfo) return 0
        const withoutCurrent = filter(completionInfo, filterFn(fromProduct.id))
        return withoutCurrent.reduce(reduceFn, 0)
    }, [completionInfo, fromProduct.id])

    // ? Dynamic completed count of current product options. Adds 1 if all
    // ? complete and 0 if not. Used with other memo to get total completed
    const totalCompletedProducts = useMemo(() => {
        if (!currentPos) return 0
        // ? bitwise compare & symbol. Only return 1 if all return 1 (true). Signifies "all po are complete".
        const andBit = (left: number, right: number) => left & right
        return binaryBool(currentPos).reduce(andBit, 1) + partialComplete
    }, [currentPos, partialComplete])

    // -------------------------------- Side effects -------------------------------- //

    // ? This should run ONLY when a new fromProduct is selected. It is the inital data load.
    useEffect(() => {
        loadConnections(fromProduct).then(([sortedProducts, completions]) => {
            if (!sortedProducts.length) {
                return setIssue(notFoundMsg(fromProduct.name))
            }

            setAll(sortedProducts)
            setCompletionInfo(completions)
            setCurrentProduct(sortedProducts[0])
        })
    }, [fromProduct])

    // ? This is a more dynamic side effect. This will update whenever a new sidebar item (product)
    // ? is clicked on. It loads up info about the currently selected product options.
    useEffect(() => {
        if (!currentProduct) return
        api.getPoConnections(pCxnToDto(currentProduct)).then(setCurrentPos)
    }, [allProductConnections, currentProduct])

    // -------------------------------- Methods -------------------------------- //

    if (issue) {
        // TODO: Should we clean this up? How to clear?
        return <div>{issue}</div>
    }

    // TODO: need to handle these conditions
    if (!allProductConnections || !currentPos || !currentProduct) {
        return <Loader />
    }

    return (
        <div className={styles.container}>
            <ConnectedPoPageHeader
                completedCount={totalCompletedProducts}
                totalCount={allProductConnections?.length || 0}
                fromProductName={fromProduct.name}
            />
            <ConnectedPoPageLayout
                allProductCxn={allProductConnections}
                completionInfo={completionInfo}
                currentPos={currentPos}
                currentProduct={currentProduct}
                setCurrentProduct={setCurrentProduct}
                setCurrentPos={setCurrentPos}
            />
        </div>
    )
}
