import { mapValues, omit, toArray } from 'lodash'
import { compose, countBy, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { RootState } from 'typesafe-actions'
import { Common } from '../typings/common.interface'
import { CurrencyEnum } from '../typings/currency.enum'
import { PriceOption } from '../typings/price-option.interface'
import { ProductOptionEnum } from '../typings/product-option.enum'
import { ProductOption } from '../typings/product-option.interface'
import { ProductOptionsById } from './product-options.reducer'

export interface ProductOptionFilterFields {
    associatedProductOption: string
    billingPeriod?: ProductOptionEnum.BillingPeriod | null
    country: string | null
    digitalCheckout: boolean
    digitalExpand: boolean
    digitalRenew: boolean
    draftState: ProductOptionEnum.State | null
    free: boolean
    freeTrial: boolean
    id: string
    isPluralsightOne: boolean
    name: string
    royaltyBearing: boolean
    state: ProductOptionEnum.State
    taxable: boolean
    testId: string | null
    useForOfferCodeRedemption: boolean
    marketingId: string | null
    priceOptions: PriceOption.AllFields[]
}

export type ProductOptionsByType = {
    localized: ProductOptionFilterFields[]
    default: ProductOptionFilterFields[]
}

type LocalizedCounts = {
    [defaultRatePlanCountryId: string]: number
}

interface ConvertsToMap {
    [key: string]: PriceOption.ConvertsTo
}

/* --------- Standard selectors --------- */

export const selectProductOptionFieldOptions = ({
    productOptions,
}: RootState) => configureFieldOptions(productOptions.fieldOptions)

export const selectCountries = ({ productOptions }: RootState) =>
    toArray(productOptions.fieldOptions.countries)

export const selectCountryName = (
    { productOptions }: RootState,
    countryCode: string
) => productOptions.fieldOptions.countries[countryCode]

export const selectReviewImportData = ({ productOptions }: RootState) =>
    productOptions.reviewImport

export const selectProductOptionView = ({ productOptions }: RootState) =>
    productOptions.view
/* --------- Memoized selectors --------- */

export const selectProductOptionsApiMeta = createSelector(
    ({ productOptions }: RootState) => productOptions,
    (productOptions) => omit(productOptions, 'byId')
)

export const selectConvertsToMap = createSelector(
    ({ productOptions }: RootState) => productOptions.byId,
    (byId) => {
        const convertsToMap: { [key: string]: PriceOption.ConvertsTo } = {}
        const productOptions = Object.values(byId).map((option) =>
            checkNewOrExisting(option.payload)
        )
        for (const option of productOptions) {
            if (option.priceOptions) {
                for (const p of option.priceOptions) {
                    convertsToMap[p.id] = {
                        currency: p.currency,
                        id: p.id,
                        name: option.name,
                        price: p.price,
                        taxCode: p.taxCode,
                        productCatalogId: option.productCatalogId,
                        ratePlanCountryId: option.id,
                    }
                }
            }
        }

        return convertsToMap
    }
)

export const selectProductOptionsById = createSelector(
    ({ productOptions }: RootState) => productOptions.byId,
    selectConvertsToMap,
    (byId, convertsToMap) =>
        mapValues(byId, ({ payload, ...apiMeta }) => ({
            ...apiMeta,
            payload: compose(
                transformProductOption(convertsToMap),
                checkNewOrExisting
            )(payload),
        }))
)

export const selectProductOption = createSelector(
    selectProductOptionsById,
    (state: RootState, id: string) => id,
    (byId, id) => byId[id]
)

export const selectProductOptionsByProduct = createSelector(
    selectProductOptionsById,
    (state: RootState, productId: string) => productId,
    (productOptions: ProductOptionsById, productId: string) =>
        Object.values(productOptions).filter(
            (po) => po.payload.productCatalogId === productId
        )
)

export const selectProductOptionsByProductId = createSelector<
    RootState,
    string,
    ProductOptionsById,
    string,
    ProductOption.AllFields[]
>(
    selectProductOptionsById,
    (state, productId) => productId,
    (productOptions, productId) =>
        Object.values<Common.ApiItem<ProductOption.AllFields>>(productOptions)
            .map((o) => o.payload)
            .filter((o) => o.productCatalogId === productId)
            .sort((a, b) =>
                b.modifiedTimestamp.localeCompare(a.modifiedTimestamp)
            )
)

export const selectOptionFilterData = createSelector<
    RootState,
    ProductOptionsById,
    ProductOptionsByType
>(selectProductOptionsById, (productOptionsById) =>
    mapFilterFields(
        Object.values<Common.ApiItem<ProductOption.AllFields>>(
            productOptionsById
        ).map((o) => o.payload)
    )
)

export const selectOptionFilterDataByProductId = createSelector<
    RootState,
    string,
    ProductOption.AllFields[],
    string,
    ProductOptionsByType
>(
    selectProductOptionsByProductId,
    (state, productId) => productId,
    (productOptions) => mapFilterFields(productOptions)
)

const selectLocalizedCounts = createSelector(
    selectProductOptionsById,
    (optionsById) =>
        compose(
            countBy(
                pathOr<string>('none', ['payload', 'defaultRatePlanCountryId'])
            ),
            Object.values
        )(optionsById)
)

export const selectOptionWithLocalizedCount = createSelector(
    selectProductOption,
    selectLocalizedCounts,
    (productOption, counts: LocalizedCounts) => ({
        localizedCount: counts[productOption.payload.id],
        productOption,
    })
)

export const makeSelectExistingPriceIds = () =>
    createSelector(selectProductOption, (productOption) =>
        productOption.payload.priceOptions.map((p) => p.id)
    )

// --------- Helper functions ---------- //

function configureFieldOptions(options: ProductOption.FieldOptions) {
    const { countries, ...otherOptions } = options
    return {
        ...otherOptions,
        countries: Object.entries(countries).map(([code, name]) => ({
            code,
            displayName: `${name} - ${code}`,
        })),
        currencies: Object.values(CurrencyEnum.Code),
    }
}

function sortByCurrency<T extends PriceOption.AllFields>(a: T, b: T) {
    const sortedKeys = Object.values(CurrencyEnum.Code)
    return sortedKeys.indexOf(a.currency) - sortedKeys.indexOf(b.currency)
}

function checkNewOrExisting(productOption: ProductOption.AllFields) {
    return productOption.draft?.meta.isNew ? productOption.draft : productOption
}

function transformPriceOptions(
    priceOptions: PriceOption.AllFields[],
    convertsToMap: ConvertsToMap
) {
    return (
        priceOptions &&
        priceOptions
            .map((p) => ({
                ...p,
                convertsTo: p.convertsToPriceOptionId
                    ? convertsToMap[p.convertsToPriceOptionId]
                    : null,
            }))
            .sort(sortByCurrency)
    )
}

// Transform price options for both existing and draft data
function transformProductOption(convertsToMap: ConvertsToMap) {
    return (productOption: ProductOption.AllFields) => {
        const { priceOptions, draft } = productOption

        return {
            ...productOption,
            priceOptions: transformPriceOptions(priceOptions, convertsToMap),
            draft: draft && {
                ...draft,
                priceOptions: transformPriceOptions(
                    draft.priceOptions,
                    convertsToMap
                ),
            },
        }
    }
}

function mapFilterFields(
    options: ProductOption.AllFields[]
): ProductOptionsByType {
    const optionIdsByType: ProductOptionsByType = {
        localized: [],
        default: [],
    }

    for (const o of options) {
        const filterFields = {
            associatedProductOption: o.defaultRatePlanCountryId ?? '',
            billingPeriod: o.billingPeriod ?? null,
            country: o.countryCode,
            draftState: o.draft?.meta.state ?? null,
            id: o.id,
            name: o.name,
            state: o.meta.state,
            testId: o.testId,
            // booleans
            digitalCheckout: o.digitalCheckout,
            digitalExpand: o.digitalExpand,
            digitalRenew: o.digitalRenew,
            free: o.free,
            freeTrial: o.freeTrial,
            isPluralsightOne: o.isPluralsightOne,
            royaltyBearing: o.royaltyBearing,
            taxable: o.taxable,
            useForOfferCodeRedemption: o.useForOfferCodeRedemption,
            marketingId: o.marketingId,
            priceOptions: o.priceOptions,
        }
        if (o.countryCode) {
            optionIdsByType.localized.push(filterFields)
        } else {
            optionIdsByType.default.push(filterFields)
        }
    }

    return optionIdsByType
}
