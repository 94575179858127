import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import styles from './CxnPoPageHeader.module.scss'

type Props = {
    fromProductName: string
    totalCount: number
    completedCount: number
}
const { Title, Paragraph } = Typography

export const ConnectedPoPageHeader = (props: Props) => {
    const headerText = `Connection for ${props.fromProductName}`

    const statusTextCount = `${props.completedCount} out of ${props.totalCount}`
    const statusText = `Status: ${statusTextCount} product configured`

    return (
        <>
            <Helmet>
                <title>Connnected Products</title>
            </Helmet>
            <Link to="/tools/connected-products" title="Admin tools">
                <LeftOutlined /> Back to Connected products
            </Link>
            <Title className={styles.cxnPoHeaderTitle}>{headerText}</Title>
            <Paragraph>{statusText}</Paragraph>
        </>
    )
}
