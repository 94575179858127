import { createAsyncAction } from 'typesafe-actions'
import { ApiSuccess } from '../root-action'
import { PreferredCurrency } from '../typings/preferred-currency.interface'
import { SupportedCurrencyCodes } from './currency.reducer'

type CurrencySuccess = ApiSuccess<PreferredCurrency>

export const getPreferred = createAsyncAction(
    'currency/GET_PREFERRED_REQUEST',
    'currency/GET_PREFERRED_SUCCESS',
    'currency/GET_PREFERRED_FAILURE'
)<void, PreferredCurrency[], Error>()

export const createPreferred = createAsyncAction(
    'currency/CREATE_PREFERRED_REQUEST',
    'currency/CREATE_PREFERRED_SUCCESS',
    'currency/CREATE_PREFERRED_FAILURE'
)<PreferredCurrency, CurrencySuccess, Error>()

export const updatePreferred = createAsyncAction(
    'currency/UPDATE_PREFERRED_REQUEST',
    'currency/UPDATE_PREFERRED_SUCCESS',
    'currency/UPDATE_PREFERRED_FAILURE'
)<PreferredCurrency, CurrencySuccess, Error>()

export const getSupported = createAsyncAction(
    'currency/GET_SUPPORTED_REQUEST',
    'currency/GET_SUPPORTED_SUCCESS',
    'currency/GET_SUPPORTED_FAILURE'
)<void, SupportedCurrencyCodes, Error>()
