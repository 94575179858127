import { PlusOutlined } from '@ant-design/icons'
import { descend, sort } from 'ramda'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Can } from '../../../Authentication/permissions/Can'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import { EmptyState } from '../../../components/empty-state/EmptyState'
import { strings } from '../../../constants'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import { actions } from '../../../store/root-action'
import { Product } from '../../../store/typings/product.interface'
import styles from './ContentLibraries.module.scss'
import { ContentLibraryDisplay } from './ContentLibraryDisplay'
import { ContentLibraryForm } from './ContentLibraryForm'

interface Props {
    product: Product.AllFields
}

export const ContentLibraries: React.FC<Props> = ({ product }) => {
    const dispatch = useDispatch()

    const openForm = () =>
        dispatch(actions.forms.open(FormName.ContentLibraries))

    const closeForm = () =>
        dispatch(actions.forms.close(FormName.ContentLibraries))

    const isFormOpen = useSelector((state: RootState) =>
        selectFormOpen(state, FormName.ContentLibraries)
    )
    const sortedLibraries = sort<Product.ContentLibrary>(
        descend((p) => p.assignedTimestamp)
    )(product.contentLibraries)

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        return () => {
            closeForm()
        }
    }, [])

    return (
        <div className={styles.container}>
            <Can I="manage" a="ContentLibrary">
                <div className={styles.actions}>
                    <PrimaryButton
                        icon={<PlusOutlined />}
                        onClick={openForm}
                        disabled={isFormOpen}
                    >
                        {strings.assignLibraryBtn}
                    </PrimaryButton>
                </div>
            </Can>

            {!isFormOpen && sortedLibraries.length < 1 ? <EmptyState /> : null}

            {isFormOpen ? (
                <div className={styles.formContainer}>
                    <ContentLibraryForm
                        product={product}
                        onCancel={closeForm}
                    />
                </div>
            ) : null}

            <div className={styles.librariesList}>
                {sortedLibraries.map((library) => (
                    <ContentLibraryDisplay
                        key={library.id}
                        library={library}
                        product={product}
                    />
                ))}
            </div>
        </div>
    )
}
