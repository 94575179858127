import { RootState } from 'typesafe-actions'
import { FormName } from './forms.reducer'

export const selectFormState = ({ forms }: RootState, form: FormName) =>
    forms[form]

export const selectFormOpen = (
    { forms }: RootState,
    form: FormName,
    id?: string
) => {
    const { isOpen, id: openId } = forms[form]
    return id ? isOpen && openId === id : isOpen
}
