import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { SpinProps } from 'antd/es/spin'
import classNames from 'classnames'
import React from 'react'
import styles from './Loader.module.scss'

type Props = SpinProps

export const Loader: React.FC<Props> = ({ className, ...props }) => (
    <div className={classNames(styles.loader, className)}>
        <Spin indicator={<LoadingOutlined />} {...props} />
    </div>
)
