import { map } from 'lodash/fp'
import {
    CxnAddon,
    CxnExpand,
    CxnProduct,
} from './../../../services/api.interface'
import {
    ExpandProduct,
    ProductInfo,
    SimpleInfo,
} from './../product-options/cxn-po.interface'

export const productToSimple = map<CxnProduct, ProductInfo>((p) => {
    return {
        id: p.fromProduct,
        name: p.fromProductName,
        upsell: p.upsellCount,
        upgrade: p.upgradeCount,
        upgradeProduct: p.upgrade,
        upsellProduct: p.upsell,
    }
})
export const addonToSimple = map<CxnAddon, SimpleInfo>((p) => {
    return {
        id: p.id,
        name: p.productName,
    }
})

// add expand-collapse feature

export const expandToSimple = map<CxnExpand, ExpandProduct>((p) => {
    return {
        id: p.id,
        type: p.type,
    }
})

// ? -------------------------------- PRIVATE -------------------------------- ?//
