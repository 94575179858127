import {
    ArrowRightOutlined,
    ArrowUpOutlined,
    CheckOutlined,
    DeleteOutlined,
    UndoOutlined,
} from '@ant-design/icons'
import React from 'react'

export const UpgradeIcon = () => <ArrowUpOutlined />
export const UpsellIcon = () => <ArrowRightOutlined />
export const DeleteButtonIcon = () => <DeleteOutlined />
export const UndoButtonIcon = () => <UndoOutlined />
export const CompleteButtonIcon = () => <CheckOutlined />
