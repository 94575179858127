import React from 'react'
import { FeatureListItem, FeaturesList } from './FeaturesList'

interface Props {
    features: FeatureListItem[]
    isSaving: boolean
}

export const FeaturesDisplay: React.FC<Props> = (props) => {
    return <FeaturesList {...props} />
}
