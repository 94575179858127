import { Form, Switch, Tooltip } from 'antd'
import { FormInstance, Rule } from 'antd/lib/form'
import currencyJs from 'currency.js'
import React from 'react'
import { Input } from '../../components/form'
import { testIds } from '../../constants'
import { SupportedCurrencyCodes } from '../../store/currency/currency.reducer'
import { CurrencyEnum } from '../../store/typings/currency.enum'
import { FormPriceOptions } from './EditProductOptionForm'
import styles from './PriceOptionsForm.module.scss'
import { FormItem } from './ProductOptionFields'

type PriceOptionFieldsProps = {
    currency: CurrencyEnum.Code
    form: FormInstance
    isNewPriceOption: boolean
}

export const PriceOptionFields: React.FC<PriceOptionFieldsProps> = ({
    currency,
    form,
    isNewPriceOption,
}) => {
    const isDefaultCurrency = CurrencyEnum.isDefaultCurrency(currency)

    const handlePriceBlur = (field: string, value: string) => {
        form.setFieldsValue({
            priceOptions: {
                [currency]: {
                    price: currencyJs(value, { symbol: '' }).format(),
                },
            },
        })
    }

    // TODO: check if isFree is true, and don't allow non-zero pricing if so
    const validatePrice = (rule: any, value: any) => {
        if (!value) {
            return Promise.resolve()
        }

        const numberCheck = value.replace(/,/g, '').replace(/\./g, '')

        if (isNaN(numberCheck)) {
            return Promise.reject('Enter a valid number')
        }
        if (currencyJs(value).toJSON() < 0) {
            return Promise.reject('Cannot be negative')
        }
        return Promise.resolve()
    }

    const configurePriceRules = () => {
        const defaultRules = [
            {
                required: true,
                message: 'Price is required',
            },
            { validator: validatePrice },
        ]

        const otherRules: Rule[] = [{ validator: validatePrice }]

        if (!isNewPriceOption) {
            otherRules.push({
                required: true,
                message: 'Price is required once established',
            })
        }

        const isActive = form.getFieldValue([
            'priceOptions',
            currency,
            'active',
        ])
        if (isActive) {
            otherRules.push({
                required: true,
                message: 'Price is required when active',
            })
        }

        return {
            default: defaultRules,
            other: otherRules,
        }
    }

    return (
        <div className={styles.priceOptionsWrapper}>
            <div className={styles.activeWrapper}>
                <FormItem
                    name={['priceOptions', currency, 'active']}
                    label={<div />}
                >
                    {isDefaultCurrency ? (
                        <Tooltip
                            title={`${currency} must be active`}
                            placement="topRight"
                        >
                            <Switch checked disabled />
                        </Tooltip>
                    ) : (
                        <Switch
                            defaultChecked={
                                !!form.getFieldValue([
                                    'priceOptions',
                                    currency,
                                    'active',
                                ])
                            }
                            onChange={(value) => {
                                if (value === false) {
                                    setTimeout(form.validateFields, 1)
                                }
                            }}
                        />
                    )}
                </FormItem>
            </div>

            <div className={styles.priceWrapper}>
                <Form.Item noStyle shouldUpdate>
                    {() => {
                        const rules = configurePriceRules()

                        return (
                            <FormItem
                                name={['priceOptions', currency, 'price']}
                                label="Price"
                                rules={
                                    isDefaultCurrency
                                        ? rules.default
                                        : rules.other
                                }
                                validateTrigger={['onChange', 'onBlur']}
                                validateFirst
                            >
                                <Input
                                    suffix={currency}
                                    onBlur={(event: any) =>
                                        handlePriceBlur(
                                            `priceOptions.${currency}.price`,
                                            event.target.value
                                        )
                                    }
                                    allowClear={false}
                                    data-testid={
                                        isDefaultCurrency
                                            ? testIds.priceDefault
                                            : testIds.priceOther
                                    }
                                />
                            </FormItem>
                        )
                    }}
                </Form.Item>
            </div>
            <div className={styles.convertsToWrapper}>
                <FormItem
                    name={['priceOptions', currency, 'convertsToPriceOptionId']}
                    label="Converts to price option ID"
                >
                    <Input allowClear={false} />
                </FormItem>
            </div>
        </div>
    )
}

type PriceOptionFormProps = {
    currencies: SupportedCurrencyCodes
    existingPriceIds: string[]
    form: FormInstance
    initial?: FormPriceOptions
}

export const PriceOptionForm: React.FC<PriceOptionFormProps> = ({
    currencies,
    existingPriceIds,
    initial,
    ...rest
}) => (
    <>
        {currencies.map((currency) => {
            const id = initial?.[currency]?.id
            const existingPriceOption = existingPriceIds.some(
                (priceId) => priceId === id
            )

            return (
                <PriceOptionFields
                    key={currency}
                    currency={currency}
                    isNewPriceOption={!id || !existingPriceOption}
                    {...rest}
                />
            )
        })}
    </>
)
