import { createReducer } from 'typesafe-actions'
import { actions } from '../root-action'

export enum FormName {
    ContentLibraries = 'contentLibraries',
    ProductCreate = 'productCreate',
    ProductEdit = 'productEdit',
    ProductOptionCreate = 'productOptionCreate',
    ProductOptionEdit = 'productOptionEdit',
    ProductOptionView = 'productOptionView',
    Features = 'features',
}

export type FormsDefaultState = {
    [key in FormName]: {
        isOpen: boolean
        id?: string | null
    }
}

export const setVisible = (
    state: FormsDefaultState,
    form: keyof FormsDefaultState
) => ({
    ...state,
    [form]: true,
})

export const setHidden = (
    state: FormsDefaultState,
    form: keyof FormsDefaultState
) => ({
    ...state,
    [form]: false,
})

export const defaultState: FormsDefaultState = {
    contentLibraries: {
        isOpen: false,
    },
    productCreate: {
        isOpen: false,
    },
    productEdit: {
        isOpen: false,
        id: null,
    },
    productOptionCreate: {
        isOpen: false,
    },
    productOptionEdit: {
        isOpen: false,
        id: null,
    },
    productOptionView: {
        isOpen: false,
        id: null,
    },
    features: {
        isOpen: false,
    },
}

export const formsReducer = createReducer(defaultState)
    .handleAction(actions.forms.open, (state, action) => ({
        ...state,
        [action.payload]: {
            isOpen: true,
        },
    }))
    .handleAction(actions.forms.openWithMeta, (state, action) => ({
        ...state,
        [action.payload]: {
            isOpen: true,
            id: action.meta.id,
        },
    }))
    .handleAction(actions.forms.close, (state, action) => ({
        ...state,
        [action.payload]: {
            isOpen: false,
        },
    }))
