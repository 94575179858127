import {
    EyeOutlined,
    LeftOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import fileDownload from 'js-file-download'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import config from '../../../config'
import { testIds } from '../../../constants'
import apiService from '../../../services/api.service'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import { ProductOptionsByType } from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { Product } from '../../../store/typings/product.interface'
import { ProductEditForm } from '../../forms/product/ProductEditForm'
import { Status } from '../../status/Status'
import { Actions } from '../components/Actions'
import { ProductContent } from './ProductContent'
import styles from './ProductHeader.module.scss'
import { ProductNavbar } from './ProductNavbar'

export interface WithProductOptions extends Product.AllFields {
    productOptions: ProductOption.AllFields[]
}

type OwnProps = {
    optionIds: ProductOptionsByType
    product: Product.AllFields
    onClone: (id: string) => void
}

export const ProductHeaderComponent: React.FC<OwnProps & PropsFromRedux> = ({
    closeForm,
    deleteDraft,
    edit,
    onClone,
    optionIds,
    product,
    sync,
    updateStatus,
}) => {
    const [isDraftVisible, setIsDraftVisible] = useState(product.meta.isDraft)

    const dispatch = useDispatch()
    const isFormOpen = useSelector((state: RootState) =>
        selectFormOpen(state, FormName.ProductEdit)
    )

    const handleClone = () => {
        const confirmContent = (
            <div>
                <p>
                    You are about to clone <b>{product.name}</b> with associated
                    product options.
                </p>
                <div>The following items will NOT be cloned:</div>
                <ul>
                    <li> Inactive and Draft product options</li>
                    <li> Localized product options</li>
                    <li> Attached content libraries</li>
                </ul>
            </div>
        )

        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, clone!',
            title: 'Clone product?',
            className: 'confirm-product-clone',
            content: confirmContent,
            width: 474,
            async onOk() {
                try {
                    const cloned = await apiService.cloneProduct(product.id)
                    dispatch(
                        actions.products.clone.success({
                            data: cloned,
                            message: 'Product successfully cloned!',
                        })
                    )
                    onClone(cloned.id)
                } catch (err: any) {
                    dispatch(actions.products.clone.failure(err))
                }
            },
        })
    }

    const confirmExportProduct = () => {
        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, export',
            title: 'Export product options?',
            content: (
                <p>
                    Drafts for both <b>new</b> and <b>existing</b> product
                    options will <b>not</b> be exported
                </p>
            ),
            async onOk() {
                try {
                    const data = await apiService.exportProductOptions(
                        product.id
                    )
                    const envName = config.isStaging
                        ? 'Staging_'
                        : config.isProduction
                        ? 'Production_'
                        : 'Development_'

                    fileDownload(
                        data,
                        `product_options_${envName}${product.sku}.csv`
                    )
                    dispatch(
                        actions.products.exportProductOptions.success({
                            data,
                            message:
                                'Product options were successfully exported!',
                        })
                    )
                } catch (err: any) {
                    dispatch(actions.products.exportProductOptions.failure(err))
                }
            },
        })
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        return () => {
            closeForm()
        }
    }, [])

    const visibleData =
        product.draft && isDraftVisible ? product.draft : product

    const allOptionIds = [...optionIds.default, ...optionIds.localized]

    const actionConfig = {
        clone: {
            onClick: handleClone,
        },
        delete: {
            apiRequest: apiService.deleteProductDraft,
            onSuccess: deleteDraft,
        },
        edit: {
            onClick: edit,
        },
        export: {
            onClick: confirmExportProduct,
            canExport: allOptionIds.length > 0,
        },
        share: {
            url: apiService.getProductUrl(product.id),
        },
        status: {
            apiRequest:
                product.status === CommonEnum.Status.Active
                    ? apiService.deactivateProduct
                    : apiService.activateProduct,
            onSuccess: updateStatus,
        },
        sync: {
            apiRequest: visibleData.meta.isNew
                ? apiService.syncNewProductToZuora
                : apiService.syncExistingProductToZuora,
            onSuccess: (synced: Product.AllFields) => {
                sync(synced)
                setIsDraftVisible(false)
            },
        },
    }

    return (
        <div data-testid={testIds.productHeader}>
            <Helmet>
                <title>{product.name} - Product Catalog | Products</title>
            </Helmet>
            <div className={styles.headerWrap}>
                <div className={styles.header}>
                    <Row className={styles.back}>
                        <Col flex="auto">
                            <Link to={`/products/`} title="Products">
                                <LeftOutlined /> Back to products
                            </Link>
                        </Col>
                    </Row>

                    <Row align="middle">
                        <Col>
                            <Status
                                text={visibleData.meta.state}
                                data-testid={testIds.productStatus}
                            />
                        </Col>
                        <Col flex="auto">
                            {product.draft ? (
                                <LinkButton
                                    className={styles.toggle}
                                    icon={<EyeOutlined />}
                                    onClick={() => {
                                        setIsDraftVisible(!isDraftVisible)
                                    }}
                                >
                                    View{' '}
                                    {isDraftVisible ? product.status : 'Draft'}
                                </LinkButton>
                            ) : null}
                        </Col>
                        <Col>
                            <Actions
                                config={actionConfig}
                                data={product}
                                permissions={{
                                    action: 'manage',
                                    subject: 'Product',
                                }}
                                visibleData={visibleData}
                            />
                        </Col>
                    </Row>
                    <ProductContent product={visibleData} />

                    <ProductEditForm
                        initialValues={visibleData}
                        onSubmit={() => {
                            setIsDraftVisible(true)
                        }}
                        visible={isFormOpen}
                    />

                    <ProductNavbar optionData={optionIds} product={product} />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteDraft: (product: Product.AllFields) => {
        dispatch(actions.products.deleteDraft.success({ data: product }))
    },

    edit: () => dispatch(actions.forms.open(FormName.ProductEdit)),

    closeForm: () => dispatch(actions.forms.close(FormName.ProductEdit)),

    sync: (product: Product.AllFields) =>
        dispatch(actions.products.sync.success({ data: product })),

    updateStatus: (product: Product.AllFields | WithProductOptions) => {
        dispatch(actions.products.updateStatus.success({ data: product }))

        const options = (product as WithProductOptions).productOptions

        if (options) {
            options.forEach((option) => {
                dispatch(actions.productOptions.updateStatus.success(option))
            })
        }
    },
})

const reduxConnector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>

export const ProductHeader = reduxConnector(ProductHeaderComponent)
