import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { CurrencyEnum } from '../../store/typings/currency.enum'
import styles from './CurrencyFilter.module.scss'

export const CurrencyFilter: React.FC<SelectProps<any>> = (props) => {
    const options = [
        ...Object.values(CurrencyEnum.Code).map((currency: string) => {
            return { label: currency, value: currency }
        }),
    ]

    return (
        <Select
            allowClear
            className={styles.currency}
            filterOption={(inputValue, option) =>
                (typeof option?.label === 'string' &&
                    option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())) ??
                true
            }
            mode="multiple"
            options={options}
            placeholder="All currencies"
            showArrow
            showSearch
            size="large"
            {...props}
        />
    )
}
