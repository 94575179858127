import { map } from 'lodash'
import { keyBy, map as mapFp, sortBy } from 'lodash/fp'
import pMap from 'p-map'
import type { PoCxnInfoDto } from '../../services/api/dto/connected-Products.dto'
import type { ProductLink } from '../../store/typings/product-link.interface'
import { BestGuessParams } from './../../services/api.interface'
import api from './../../services/api.service'
import type {
    CompletedStatus,
    Completion,
    CxnCompletions,
    PConnection,
    PoCompletion,
    ProductOptionConnection,
    SimpleInfo,
} from './product-options/cxn-po.interface'

const { getPoConnections, getConnectedProducts, getProductsGuesslinks } = api

type Detail = ProductLink.RelationDetail
type PoCxn = ProductOptionConnection
type CxnInfo = [PConnection[], CxnCompletions]

// -------------------------------- Memoized Helpers -------------------------------- //
const sortByLinkType = sortBy<PConnection>(['linkType'])
const mapByToProduct = keyBy<Completion>('toProductId')

// -------------------------------- Exported -------------------------------- //
export const loadConnections = async (fromP: SimpleInfo): Promise<CxnInfo> => {
    const pLinks = await getConnectedProducts(fromP.id)
    // ? loop through each of the products and get all of the product option links
    const complete = await pMap(pLinks, async (p) => ({
        toProductId: p.toProductDetail.id,
        poCompletions: map(await getPoConnections(dto(p)), toPoCompletion),
    }))
    // ? Return tuple of completions(what is done) and all of the product options
    return [sortByLinkType(map(pLinks, clean(fromP))), mapByToProduct(complete)]
}

export const getCxnGuesses = (pCxn: PConnection) =>
    getProductsGuesslinks(guessDto(pCxn))
export const isComplete = (poCxn: PoCxn) => isItComplete(poCxn)

export const updatePoCxns: UpdatePoCxns = (poCxns, from, linkState, toPo?) => {
    if (linkState === 'complete' && !toPo) {
        throw new Error('Something went wrong: 1057')
    }

    const isIt = (fm: string) => (p: PoCxn) => fm === p.fromProductOption.id
    const updateAll = mapFp<PoCxn, PoCxn>((po) =>
        isIt(from)(po) ? { ...po, toProductOption: toPo, linkState } : po
    )
    return updateAll(poCxns)
}

export const getAllPos = async (productId: string) => {
    return map(await api.getProductProductOptions(productId), toSimple)
}

export const pCxnToDto = (pCxn: PConnection): PoCxnInfoDto => ({
    fromProduct: pCxn.fromProduct.id,
    toProduct: pCxn.toProduct.id,
    type: pCxn.linkType,
})

// -------------------------------- Helpers -------------------------------- //

function dto({ fromProduct, toProduct, type }: Detail): PoCxnInfoDto {
    return { fromProduct, toProduct, type }
}

// curried fn
function clean(fromProduct: SimpleInfo) {
    return ({ toProductDetail: dtl, type }: Detail): PConnection => ({
        fromProduct,
        toProduct: { id: dtl.id, name: dtl.name },
        linkType: type,
    })
}

function toPoCompletion(po: PoCxn): PoCompletion {
    return {
        id: po.fromProductOption.id,
        isComplete: isItComplete(po),
        status: po.linkState,
    }
}

// should work with any data that has a name and id: products, product opt, etc
function toSimple(v: { id: string; name: string }): SimpleInfo {
    return { id: v.id, name: v.name }
}

function isItComplete(po: ProductOptionConnection) {
    if (po.linkState === 'incomplete') return false
    if (po.linkState === 'complete') return true
    if (po.linkState === 'exclude') return true

    throw new Error(`unknown link state for ${po.fromProductOption.name}`)
}

function guessDto(p: PConnection): BestGuessParams {
    return {
        fromProduct: p.fromProduct.id,
        toProduct: p.toProduct.id,
        type: p.linkType,
    }
}

// -- short types for 1 liners -- //
type UpdatePoCxns = (
    poCxns: PoCxn[],
    fromId: string,
    linkState: CompletedStatus,
    toPo?: SimpleInfo
) => PoCxn[]
