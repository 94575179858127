import { Tag, Tooltip } from 'antd'
import React from 'react'
import styles from './BooleanTag.module.scss'

type Props = {
    label: string
    name: string
    tooltip?: string
}

export const BooleanTag = ({ label, name, tooltip }: Props) => (
    <Tooltip title={tooltip} placement="top" mouseEnterDelay={0.5}>
        <Tag className={styles[name]}>{label}</Tag>
    </Tooltip>
)
