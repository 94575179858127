import { CommonEnum } from './common.enum'
import { Common } from './common.interface'
import { ProductOption } from './product-option.interface'
import { ProductEnum } from './product.enum'

export namespace Product {
    export interface FormFields {
        category: ProductEnum.Category
        customerType: ProductEnum.CustomerType
        level: number
        libraryScope: ProductEnum.LibraryScope
        name: string
        sku: string
        type: ProductEnum.Type
    }

    export interface CreateRequest {
        category: ProductEnum.Category
        contentLibraries: ContentLibrary[]
        customerFacingDescription: string
        customerType: ProductEnum.CustomerType
        groups: ProductEnum.Group[]
        level: number
        libraryScope: ProductEnum.LibraryScope
        name: string
        sku: string
        type: ProductEnum.Type
    }

    export interface UpdateRequest extends CreateRequest {
        id: string
    }

    export interface AllFields extends UpdateRequest {
        category: ProductEnum.Category
        contentLibraries: ContentLibrary[]
        createdTimestamp: string
        customerFacingDescription: string
        customerType: ProductEnum.CustomerType
        draft?: Draft | null
        features: Feature[]
        groups: ProductEnum.Group[]
        id: string
        isUserAssignable: boolean
        level: number
        libraryScope: ProductEnum.LibraryScope
        meta: Common.Meta
        modifiedTimestamp: string
        name: string
        preserveProvisionedTerm: boolean
        sku: string
        status: CommonEnum.Status
        syncedTimestamp: string
        type: ProductEnum.Type
    }

    export interface AllFieldsWithChildren extends AllFields {
        productOptions: ProductOption.AllFields[]
    }

    export interface Draft extends Omit<AllFields, 'meta'> {
        meta: Common.DraftMeta
    }

    export interface FieldOptions {
        category: ProductEnum.Category[]
        customerType: ProductEnum.CustomerType[]
        groups: ProductEnum.Group[]
        libraryScope: ProductEnum.LibraryScope[]
        type: ProductEnum.Type[]
    }

    export interface ContentLibrary {
        assignedTimestamp: string
        description: string
        id: string
        modifiedTimestamp: string
        title: string
    }

    export interface ContentLibraryRequest {
        id: string
        productId: string
    }

    export interface Feature {
        assignedTimestamp: string | null
        id: string
    }

    export interface FeaturesRequest {
        id: string
        features: {
            id: string
            assigned: boolean
        }[]
    }

    export function isProduct(
        entity: AllFields | ProductOption.AllFields
    ): entity is AllFields {
        return (entity as AllFields).sku !== undefined
    }
}
