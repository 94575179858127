import classNames from 'classnames'
import React from 'react'
import { ButtonLoader } from './ButtonLoader'
import styles from './Buttons.module.scss'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement> & {
        loading?: boolean
        icon?: React.ReactNode
        size?: 'normal' | 'small'
    }

export const BaseButton: React.FC<ButtonProps> = ({
    children,
    className,
    disabled,
    href,
    icon,
    loading,
    size = 'normal',
    ...rest
}) => {
    const props: ButtonProps = {
        className: classNames(styles.base, styles[size], className),
        disabled: loading || disabled,
        type: 'button',
        href,
        ...rest,
    }

    const visibleIcon = loading ? <ButtonLoader /> : icon
    const childrenWithIcon = (
        <>
            {visibleIcon}
            {visibleIcon && children ? (
                <span className={styles.spacer} />
            ) : null}
            <span>{children}</span>
        </>
    )

    return React.createElement(href ? 'a' : 'button', props, childrenWithIcon)
}

export const PrimaryButton: React.FC<ButtonProps> = ({
    className,
    ...props
}) => (
    <BaseButton className={classNames(styles.primary, className)} {...props} />
)

export const SecondaryButton: React.FC<any> = ({ className, ...props }) => (
    <BaseButton
        className={classNames(styles.secondary, className)}
        {...props}
    />
)

export const LinkButton: React.FC<ButtonProps> = ({
    children,
    className,
    icon,
    ...props
}) => (
    <BaseButton
        size="small"
        className={classNames(
            styles.link,
            className,
            icon ? null : styles.linkNoIcon
        )}
        icon={icon}
        {...props}
    >
        {children}
    </BaseButton>
)

export const SubmitButton: React.FC<ButtonProps> = ({ children, ...props }) => (
    <PrimaryButton type="submit" {...props}>
        {children ?? 'Submit'}
    </PrimaryButton>
)

export const CancelButton: React.FC<any> = (props) => (
    <SecondaryButton className={styles.cancel} {...props}>
        Cancel
    </SecondaryButton>
)
