import { RootState } from 'typesafe-actions'
import { Filters } from './filters.reducer'

export const selectAllFilters = ({ filters }: RootState) => filters

export const selectProductFilters = ({ filters }: RootState) =>
    filters[Filters.Product]

export const selectProductOptionFilters = ({ filters }: RootState) =>
    filters[Filters.ProductOption]

export const selectLocalizedProductOptionFilters = ({ filters }: RootState) =>
    filters[Filters.LocalizedProductOption]

export const selectLocalizationFilters = ({ filters }: RootState) =>
    filters[Filters.Localization]
