import { Col } from 'antd'
import { isBoolean, isNil } from 'lodash'
import React from 'react'
import { Copy } from '../../copy/Copy'
import { Label } from '../components/Label'
import { Value } from '../components/Value'
import { BooleanTag } from './BooleanTag'
import styles from './ProductOptionContent.module.scss'
import { SfValueButton } from './SfValueButton'

interface Props {
    label: any
    value: any
    name: string
    render?: (val: any) => any
    tooltip?: string
}

const columnSizes = {
    xs: 24,
    sm: 8,
    md: 6,
    xl: 4,
    className: styles.column,
}

type CopyProps = {
    label: string
    name: string
    value: string
}

const CopyLabel = ({ label, name, value }: CopyProps) => (
    <Label>
        {label}
        {name.includes('Id') || name.includes('Sku') ? (
            <Copy clipboard={value} />
        ) : null}
    </Label>
)

export const ProductOptionField = (props: Props) => {
    const { label, value, name, render, tooltip } = props

    if (isNil(value)) {
        return null
    }

    if (name === 'sfdcProductId' && value) {
        // ? This will make the CPQ Product ID into a link. QOL for SF team.
        // ? It only enter this condition when a sfdcProductId exists
        return (
            <Col {...columnSizes}>
                <CopyLabel label={label} name={name} value={value} />
                <SfValueButton sfId={value} />
            </Col>
        )
    }

    if (isBoolean(value)) {
        return value ? (
            <BooleanTag {...{ name, label }} tooltip={tooltip} />
        ) : null
    }

    return (
        <Col {...columnSizes}>
            <CopyLabel label={label} name={name} value={value} />
            {render ? render(value) : <Value>{value}</Value>}
        </Col>
    )
}
