import { Tag } from 'antd'
import React from 'react'
import { CommonEnum } from '../../store/typings/common.enum'
import styles from './Status.module.scss'

type Props = {
    text: CommonEnum.State
}

export const Status: React.FC<Props> = ({ text, ...rest }) => {
    const badgeLabel = () => {
        switch (text) {
            case CommonEnum.State.DraftNew:
            case CommonEnum.State.DraftExisting:
                return 'draft'
            default:
                return text
        }
    }

    const badgeStyle = () => {
        // Convert catalog status (value) to CSS class name
        const statusClass = text && text.replace(/[\W_]+/g, '-').toLowerCase()
        return styles[statusClass]
    }

    return (
        <Tag className={badgeStyle()} {...rest}>
            {badgeLabel()}
        </Tag>
    )
}
