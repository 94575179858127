import { Col, Row } from 'antd'
import { sum } from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    PrimaryButton,
    SecondaryButton,
} from '../../../components/buttons/Buttons'
import apiService from '../../../services/api.service'
import { selectProductOptionsApiMeta } from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { PriceOption } from '../../../store/typings/price-option.interface'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { Feedback } from './Feedback'

interface Props {
    data: ProductOption.ImportResponse[]
}

export const SubmitImport: React.FC<Props> = ({ data }) => {
    const dispatch = useDispatch()
    const { isSaving } = useSelector(selectProductOptionsApiMeta)
    const [processing, setProcessing] = useState(false)
    const dataTotals = data.reduce(
        (accum, d) => ({
            ...accum,
            [d.outcome]: accum[d.outcome] + 1,
        }),
        { create: 0, update: 0, none: 0, error: 0 }
    )
    const [totals, setTotals] = useState({
        totalRecords: sum(Object.values(dataTotals)),
        totalQueued: dataTotals.create + dataTotals.update,
        totalSkippedInvalid: dataTotals.error,
        totalSkippedUnchanged: dataTotals.none,
    })

    // TODO: In the future, clean only specific queue
    const cleanJobs = () => {
        setProcessing(false)
        return apiService.cleanAllJobs()
    }

    const getFreeTrial = ({
        freeTrial,
        freeTrialPriceOptions,
    }: ProductOption.ImportResponse) => {
        // freeTrialPriceOptions and freeTrial will be mutually exclusive under current implements
        // freeTrialPriceOptions will only apply to updates, and freeTrial to creates
        if (freeTrialPriceOptions?.length) {
            const po = freeTrialPriceOptions[0].productOption
            po.priceOptions = freeTrialPriceOptions.map(
                (p): PriceOption.AllFields => {
                    return {
                        convertsToPriceOptionId: p.convertsToPriceOptionId,
                        currency: p.currency,
                        id: p.id,
                        price: p.price,
                        ratePlanCountryId: p.ratePlanCountryId,
                        status: p.status,
                        taxCode: p.taxCode,
                    }
                }
            )
            return po
        }
        return freeTrial
    }

    const submitImport = () => {
        setProcessing(true)
        dispatch(
            actions.productOptions.submitImport.request({
                items: data
                    .filter((d) => ['create', 'update'].includes(d.outcome))
                    .map((d) => ({
                        diffs: {
                            productOption: d.productOption.diffs,
                            priceOptions: Object.values(d.priceOptions).flatMap(
                                (pri) => pri?.diffs || []
                            ),
                        },
                        entity: d.productOption.entity,
                        freeTrial: getFreeTrial(d),
                        outcome: d.outcome,
                    })),
            })
        )
    }

    return (
        <div>
            <Row gutter={[12, 24]} className={'row-gap-24'}>
                <Col>
                    <PrimaryButton
                        onClick={submitImport}
                        loading={isSaving}
                        disabled={processing}
                    >
                        Import
                    </PrimaryButton>
                </Col>
                <Col>
                    <SecondaryButton onClick={cleanJobs}>
                        Clean Queue
                    </SecondaryButton>
                </Col>
            </Row>
            <Row gutter={[0, 24]} className={'row-gap-24'}>
                <Col>
                    <Feedback totals={totals} />
                </Col>
            </Row>
        </div>
    )
}
