import { Drawer as AntDrawer, DrawerProps } from 'antd'
import React from 'react'
import styles from './Drawer.component.module.scss'

// Drawer only provides a "style" prop for footer (no "class"), hence inline styles
const footerStyle = { padding: '16px 24px' }

interface Props extends DrawerProps {
    titleText: string
}

export const Drawer: React.FC<Props> = ({ titleText, children, ...props }) => {
    return (
        <AntDrawer
            title={<div className={styles.drawerTitle}>{titleText}</div>}
            destroyOnClose={true}
            footerStyle={footerStyle}
            width={'60%'}
            {...props}
        >
            {children}
        </AntDrawer>
    )
}
