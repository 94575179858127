import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const { Title } = Typography
export const CxnProductPageHeader = () => {
    return (
        <>
            <Helmet>
                <title>Connected Products</title>
            </Helmet>
            <Link to={`/tools`} title="Admin tools">
                <LeftOutlined /> Back to tools
            </Link>
            <br />
            <Title>Connected Products</Title>
        </>
    )
}
