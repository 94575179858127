import { PlusOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import { Can } from '../../Authentication/permissions/Can'
import { PrimaryButton } from '../../components/buttons/Buttons'
import { defaultState } from '../../store/alerts/alerts.reducer'
import { selectAlerts } from '../../store/alerts/alerts.selectors'
import { FormName } from '../../store/forms/forms.reducer'
import { selectFormOpen } from '../../store/forms/forms.selectors'
import { selectProductOptionsById } from '../../store/product-options/product-options.selectors'
import {
    selectNewAndExistingProducts,
    selectProductsApiMeta,
} from '../../store/products/products.selectors'
import { actions } from '../../store/root-action'
import { ProductCreateForm } from '../forms/product/ProductCreateForm'
import { hasData } from '../hoc/hasData'
import { ProductFilterInputs } from './components/ProductFilterInputs'
import { ProductsTable } from './components/ProductsTable'
import styles from './Products.module.scss'

type Props = ConnectedProps<typeof reduxConnector>

type State = {
    isCreating: boolean
}

export class ProductsComponent extends React.Component<Props, State> {
    state: State = {
        isCreating: false,
    }

    openCreateForm = () => {
        this.setState({
            isCreating: true,
        })
    }

    closeCreateForm = () => {
        this.setState({
            isCreating: false,
        })
        this.props.clearError()
    }

    componentWillUnmount() {
        const { notifications } = this.props
        if (notifications) {
            notification.destroy()
            this.props.clearAlerts(defaultState)
        }
    }

    render() {
        const {
            apiMeta: { isFetching },
            products,
            productOptionsById,
        } = this.props

        return (
            <>
                <Helmet>
                    <title>Products - Product Catalog</title>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.buttonContainer}>
                        <Can I="create" a="Product">
                            <PrimaryButton
                                onClick={this.props.openForm}
                                icon={<PlusOutlined />}
                            >
                                Create new product
                            </PrimaryButton>
                        </Can>
                    </div>
                    <ProductFilterInputs />
                    <div className={styles.productsContainer}>
                        <ProductsTable
                            loading={isFetching}
                            products={products}
                            productOptionsById={productOptionsById}
                        />
                    </div>
                </div>
                <ProductCreateForm visible={this.props.isProductFormOpen} />
            </>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    apiMeta: selectProductsApiMeta(state),
    productOptionsById: selectProductOptionsById(state),
    products: selectNewAndExistingProducts(state),
    notifications: selectAlerts(state),
    isProductFormOpen: selectFormOpen(state, FormName.ProductCreate),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearError: () => dispatch(actions.products.clearError()),
    clearAlerts: (defaultState: any) =>
        dispatch(actions.alerts.clear(defaultState)),
    openForm: () => dispatch(actions.forms.open(FormName.ProductCreate)),
})

const reduxConnector = connect(mapStateToProps, mapDispatchToProps)

export const Products = compose(hasData, reduxConnector)(ProductsComponent)
