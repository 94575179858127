import dayjs from 'dayjs'
import { ProductOption } from './typings/product-option.interface'
import { Product } from './typings/product.interface'

export const mapPayloadById = <T>(
    items: any,
    transformFn?: (data: any) => any,
    altProperty?: keyof T
) => {
    const byId: any = {}

    for (const i of items) {
        const property = altProperty ?? 'id'
        byId[i[property]] = {
            ...requestSuccessMeta(),
            payload: (transformFn && transformFn(i)) || i,
        }
    }

    return byId
}

export const sortDesc = (a: any, b: any) => {
    if (a < b) {
        return 1
    }
    if (a > b) {
        return -1
    }

    return 0
}

export const formatTimestamp = (timestamp: string) =>
    dayjs(timestamp).format('MMM DD, YYYY hh:mm a')

export const now = () => dayjs().toISOString()

export const requestDoneMeta = {
    isFetching: false,
    isSaving: false,
}

export const requestSuccessMeta = () => ({
    ...requestDoneMeta,
    receivedAt: now(),
    error: null,
})

export const requestErrorMeta = (error: Error | null) => ({
    ...requestDoneMeta,
    error,
})

export const setSaveInProgress = (state: any, id: string) => ({
    ...state,
    [id]: {
        ...state[id],
        error: null,
        isSaving: true,
    },
})

export const setFetchInProgress = (state: any, id: string) => ({
    ...state,
    [id]: {
        ...state[id],
        error: null,
        isFetching: true,
        isSaving: false,
    },
})

export const setRequestError = (
    state: any,
    id: string,
    error: Error | null
) => ({
    ...state,
    [id]: {
        ...state[id],
        ...requestErrorMeta(error),
    },
})

export const setPayload = (state: any, payload: any, altProperty?: string) => ({
    ...state,
    [payload[altProperty ?? 'id']]: {
        ...requestSuccessMeta(),
        payload,
    },
})

export const mergePayload = (state: any, id: string, payload: any) => ({
    ...state,
    [id]: {
        ...requestSuccessMeta(),
        payload: {
            ...state[id].payload,
            ...payload,
        },
    },
})

export const isNew = (item: Product.AllFields | ProductOption.AllFields) =>
    item.meta.isNew

export const isDraft = (item: Product.AllFields | ProductOption.AllFields) =>
    item.meta.isDraft || item.draft?.id
