import {
    Col,
    Descriptions,
    Divider,
    Progress,
    Result,
    Row,
    Tooltip,
} from 'antd'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import apiService from '../../../services/api.service'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductEnum } from '../../../store/typings/product.enum'
import { useAuditData } from '../../hooks/useAuditData'
import { Detail } from './Detail'

const ignoreByName = ['Microsoft Azure (Limited Library)']

export const AuditMissingContentLibraries: React.FC = () => {
    const { products } = useAuditData()
    const audit = useMemo(() => {
        const activeProductsWithLibraryScope = products.filter(
            ({ libraryScope, name, status }) =>
                libraryScope !== ProductEnum.LibraryScope.None &&
                status !== CommonEnum.Status.Inactive &&
                !ignoreByName.includes(name)
        )
        const productsWithMissingLibraries =
            activeProductsWithLibraryScope.filter(
                (product) => product.contentLibraries.length === 0
            )

        return {
            percent: Math.round(
                (productsWithMissingLibraries.length /
                    activeProductsWithLibraryScope.length) *
                    100
            ),
            activeProductsWithLibraryScope,
            productsWithMissingLibraries,
        }
    }, [products])

    const detail =
        audit.percent > 0 ? (
            <ol>
                {audit.productsWithMissingLibraries.map(({ id, name }) => (
                    <li key={id}>
                        <Link
                            to={apiService.getRelativeUrl(
                                apiService.getProductContentLibrariesUrl(id)
                            )}
                        >
                            {name}
                        </Link>
                    </li>
                ))}
            </ol>
        ) : (
            <Result status="success" title="No affected products" />
        )

    return (
        <>
            <Divider orientation="center">Missing Content Libraries</Divider>

            <Row gutter={[12, 24]} className={'row-gap-24'}>
                <Col flex={1}>
                    <Descriptions title="Totals" bordered={true}>
                        <Descriptions.Item
                            label={
                                <Tooltip
                                    title={`Includes Products where library scope != ${ProductEnum.LibraryScope.None} and status != ${CommonEnum.Status.Inactive}`}
                                >
                                    <span>
                                        Active products with Library Scope
                                    </span>
                                </Tooltip>
                            }
                        >
                            {audit.activeProductsWithLibraryScope.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total without Content Libraries">
                            {audit.productsWithMissingLibraries.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="% Affected">
                            <Progress
                                format={(percent) => `${percent}%`}
                                percent={audit.percent}
                                status="normal"
                                strokeColor={{
                                    '0%': '#d21c09',
                                    '100%': '#ffd6d6',
                                }}
                                trailColor="transparent"
                                type="circle"
                                width={50}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Detail detail={detail} />
        </>
    )
}
