import { Col, Row } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { formNames } from '../../../constants'
import { FormName } from '../../../store/forms/forms.reducer'
import {
    selectProductOption,
    selectProductOptionFieldOptions,
} from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { ProductOptionEnum } from '../../../store/typings/product-option.enum'
import { ProductOption } from '../../../store/typings/product-option.interface'
import { PriceOptions } from './PriceOptions'
import { ProductOptionField } from './ProductOptionField'

type Props = {
    option: ProductOption.AllFields
}

export type Labels<T> = {
    [P in keyof T]?: string
}

export type BooleanTags<T> = {
    [P in keyof T]?: [ProductOptionEnum.Tags, string]
}

// ! Order of the keys below determine sequence in the UI
export const productOptionLabelMapping: Labels<ProductOption.AllFields> = {
    invoiceName: 'Name on invoice',
    chargeType: 'Charge type',
    billingPeriod: 'Billing period',
    chargeModel: 'Charge model',
    uom: 'Unit of measure',
    customerSource: 'Customer source',
    sfdcProductId: 'CPQ product ID',
    legacyProductSku: 'Legacy product SKU',
    marketingId: 'Marketing ID',
    testId: 'Marketing test ID',
    countryCode: 'Country Code',
}
export const productOptionBooleans: BooleanTags<ProductOption.AllFields> = {
    digitalCheckout: [
        ProductOptionEnum.Tags.Checkout,
        'Can be purchased online without assistance from sales',
    ],
    digitalExpand: [
        ProductOptionEnum.Tags.Expand,
        'Can be expanded online without assistance from sales',
    ],
    digitalRenew: [
        ProductOptionEnum.Tags.Renew,
        'Can be renewed online without assistance from sales',
    ],
    useForOfferCodeRedemption: [
        ProductOptionEnum.Tags.Redeemable,
        'Can be redeemed through a redemption link',
    ],
    free: [ProductOptionEnum.Tags.Free, 'This is a free product option'],
    freeTrial: [ProductOptionEnum.Tags.FreeTrial, 'This is a free trial'],
    taxable: [ProductOptionEnum.Tags.NotTaxable, 'Tax is not collected'],
    royaltyBearing: [
        ProductOptionEnum.Tags.Royalty,
        'Royalties will be paid on all video content attached to this product option',
    ],
    taxCode: [ProductOptionEnum.Tags.TaxCode, 'Tax code for applicable taxes'],
    isPluralsightOne: [
        ProductOptionEnum.Tags.PSOne,
        'Used for Pluralsight One',
    ],
}

export const ProductOptionContent: React.FC<Props> = ({ option: po }) => {
    const dispatch = useDispatch()
    const closeViewForm = useCallback(() => {
        dispatch(actions.forms.close(FormName.ProductOptionView))
    }, [dispatch])

    const fieldOptions = useSelector((state: RootState) =>
        selectProductOptionFieldOptions(state)
    )
    const defaultProductOption = useSelector((state: RootState) => {
        if (po.defaultRatePlanCountryId) {
            const productOption = selectProductOption(
                state,
                po.defaultRatePlanCountryId
            )
            return productOption?.payload
        }
    })
    const getKeyValue =
        <T extends Record<any, any>, U extends keyof T>(obj: T) =>
        (k: U) =>
            obj[k]

    return (
        <>
            <Row
                gutter={[{ xs: 24, sm: 24, md: 12, lg: 24, xl: 24 }, 8]}
                className={'row-gap-8'}
            >
                {Object.entries(productOptionLabelMapping).map(
                    ([key, label]) => {
                        let value = getKeyValue(po)(key as any)

                        if (key === formNames.countryCode) {
                            const country: ProductOption.CountryOption[] =
                                fieldOptions.countries.filter(
                                    (country) => country.code === value
                                )
                            value = country[0]?.displayName
                        }

                        return (
                            <ProductOptionField
                                key={key}
                                label={label}
                                name={key}
                                value={value}
                            />
                        )
                    }
                )}
                <ProductOptionField
                    name={'defaultRatePlanCountryId'}
                    value={defaultProductOption?.name}
                    label={'Associated product option'}
                    render={(value) => (
                        <Link
                            to={`/products/${po.productCatalogId}#${po.defaultRatePlanCountryId}`}
                            onClick={closeViewForm}
                        >
                            {value}
                        </Link>
                    )}
                />
            </Row>
            <Row
                gutter={[{ xs: 24, sm: 24, md: 12, lg: 24, xl: 24 }, 8]}
                className={'row-gap-8'}
            >
                <Col>
                    {Object.entries(productOptionBooleans).map(
                        ([key, label]) => {
                            let value = getKeyValue(po)(key as any)

                            // We invert "taxable" boolean here to display as "not taxable"
                            if (key === 'taxable') {
                                value = !value
                            }

                            return label ? (
                                <ProductOptionField
                                    key={key}
                                    label={label[0]}
                                    name={key}
                                    tooltip={label[1]}
                                    value={value}
                                />
                            ) : null
                        }
                    )}
                </Col>
            </Row>
            <PriceOptions priceOptions={po.priceOptions} />
        </>
    )
}
