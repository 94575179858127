import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import { selectProductOptionsApiMeta } from '../../store/product-options/product-options.selectors'
import { selectProductsApiMeta } from '../../store/products/products.selectors'
import { actions } from '../../store/root-action'
import { Loader } from '../loader/Loader'
import { FetchError } from '../router/FetchError'

export const hasData = (WrappedComponent: any) => {
    // Using HOCs with typescript creates problems when rendering components/passing props to a component that is wrapped in an HOC.
    // E.g., When passing productId to ProductDetails component in RootRouter, trying to set Props here will throw typescript error
    // Marking the props here as any until this can be resolved, or until we look into hooks
    class HasData extends React.Component<any> {
        componentDidMount() {
            const {
                isMissingData,
                getFields,
                getProducts,
                getProductOptions,
                getFeatures,
            } = this.props

            if (isMissingData) {
                getProducts()
                getProductOptions()
                getFields()
                getFeatures()
            }
        }

        render() {
            const { fetchError, isFetching, isMissingData, ...rest } =
                this.props

            if (fetchError) {
                return <FetchError />
            }

            if (isFetching) {
                return <Loader />
            }

            if (isMissingData) {
                return null
            }

            return <WrappedComponent {...rest} />
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(HasData)
}

const mapStateToProps = (state: RootState) => {
    const optionsMeta = selectProductOptionsApiMeta(state)
    const productsMeta = selectProductsApiMeta(state)

    return {
        isFetching: optionsMeta.isFetching || productsMeta.isFetching,
        isMissingData:
            optionsMeta.receivedAt === null || productsMeta.receivedAt === null,
        fetchError: optionsMeta.error.onFetch || productsMeta.error.onFetch,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getFields: () => {
        dispatch(actions.productOptions.getFields.request())
        dispatch(actions.products.getFields.request())
    },
    getProductOptions: () => dispatch(actions.productOptions.get.request()),
    getProducts: () => dispatch(actions.products.get.request()),
    getFeatures: () => dispatch(actions.features.get.request()),
})
