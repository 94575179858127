import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import React from 'react'
import { LinkButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { strings } from '../../../constants'
import { ProductOption } from './ProductOption'
import styles from './ProductOptionDrawer.module.scss'

interface Props {
    id: string
    onClose: () => void
    next: (id: string, moveForward: boolean) => void
}

export const ProductOptionDrawer: React.FC<Props> = ({ id, onClose, next }) => {
    return (
        <Drawer
            visible={true}
            onClose={onClose}
            width={'60%'}
            className={styles.productOptionDrawer}
            footer={
                <HorizontalList>
                    <LinkButton
                        icon={<CaretUpOutlined />}
                        onClick={() => next(id, false)}
                    >
                        View previous
                    </LinkButton>
                    <LinkButton
                        icon={<CaretDownOutlined />}
                        onClick={() => next(id, true)}
                    >
                        View next
                    </LinkButton>
                </HorizontalList>
            }
            title={
                <div className={styles.drawerTitle}>
                    {strings.viewPoDetails}
                </div>
            }
        >
            <div id="poDrawer">
                <ProductOption key={id} optionId={id} isDrawer={true} />
            </div>
        </Drawer>
    )
}
