import { call, delay, put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { actions } from '../root-action'

export function* submitDvsBackfillSaga(
    action: ReturnType<typeof actions.adhoc.runDvsBackfill.request>
) {
    try {
        const response: void = yield call(
            api.backfillDvsDatabaseSync,
            action.payload
        )
        yield delay(2000)
        yield put(
            actions.adhoc.runDvsBackfill.success({
                data: response,
                message: `The adhoc job for "${action.payload.target}" is now running. Your work here is done!`,
            })
        )
    } catch (err) {
        yield put(actions.adhoc.runDvsBackfill.failure(err))
    }
}

export function* adhocWatcher() {
    yield takeEvery(
        getType(actions.adhoc.runDvsBackfill.request),
        submitDvsBackfillSaga
    )
}
