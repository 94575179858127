import { ThunderboltTwoTone } from '@ant-design/icons'
import { Select } from 'antd'
import { Dictionary, keyBy, map } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { cxnOptions } from '../../connected-product.constants'
import { SimpleInfo } from '../cxn-po.interface'
import { SelectMap, SetSelectMap } from './UnassignedCxnTable'

const PLACEHOLDER_TEXT = 'No best guess found'
const tagStyle = { marginLeft: '8px' }

const defaultSelectProps = {
    style: { width: 450 }, // TODO: Remove hard coded items
    showArrow: true,
    showSearch: true,
    allowClear: true,
    defaultActiveFirstOption: false,
    placeholder: PLACEHOLDER_TEXT,
}

type Props = {
    toPos: SimpleInfo[]
    bestGuess?: string
    fromPo: SimpleInfo
    setSelectMap: SetSelectMap
    selectMap: SelectMap
}

const bestGuessLabel = (id: string, bestId?: string) =>
    id === bestId ? <ThunderboltTwoTone style={tagStyle} /> : null

export const BestGuessSelect = (props: Props) => {
    const { bestGuess } = props
    const setSelect = currySetSelectMap(props.setSelectMap)

    // -------------------------------- STATE ITEMS -------------------------------- //
    const [selectedId, setSelectedId] = useState(bestGuess)
    const toPoMap = useMemo(() => keyBy(props.toPos, 'id'), [props.toPos])
    useEffect(() => setSelectedId(bestGuess), [bestGuess])
    useEffect(() => {
        setSelect(props.selectMap, props.fromPo.id, selectedId)
    }, [props.selectMap, props.fromPo.id, setSelect, selectedId])

    // -------------- Event Handlers -------------- //

    const onChange = (name: string) => {
        const id = !name ? undefined : getIdFromName(toPoMap, name)
        return setSelectedId(id)
    }

    // -------------- Component Methods -------------- //
    const allSelectOptions = () =>
        map(props.toPos, ({ id, name }) => (
            <Select.Option key={id} value={name}>
                {name}
                {bestGuessLabel(id, bestGuess)}
            </Select.Option>
        ))

    return (
        <Select
            {...defaultSelectProps}
            onChange={onChange}
            value={getSelectValue(selectedId, toPoMap)}
            filterOption={(str, opt) => {
                if (
                    !cxnOptions.filterBestGuessInSelect &&
                    bestGuess &&
                    bestGuess === opt?.key
                ) {
                    return true
                }
                return !opt ? true : selecterFiltering(opt.value, str)
            }}
        >
            {allSelectOptions()}
        </Select>
    )
}

// -------------------------------- HELPER LOCAL -------------------------------- //

function currySetSelectMap(disp: SetSelectMap) {
    return (selectMap: SelectMap, fromId: string, newId?: string) => {
        if (!selectMap) {
            return {}
        }
        if (selectMap[fromId] !== newId) {
            disp({ ...selectMap, [fromId]: newId })
        }
    }
}

function getSelectValue(selectedId?: string, poMap?: Dictionary<SimpleInfo>) {
    if (!selectedId || !poMap) {
        return undefined
    }
    return poMap[selectedId]?.name
}

function selecterFiltering(name: string, input: string) {
    return name.toLowerCase().includes(input.toLowerCase())
}

function getIdFromName(items: Dictionary<SimpleInfo>, findName: string) {
    return Object.values(items).filter(({ name }) => findName === name)[0].id
}
