import { createAction, createAsyncAction } from 'typesafe-actions'
import { ApiSuccess } from '../root-action'
import { Common } from '../typings/common.interface'
import { ProductOption } from '../typings/product-option.interface'

type AllFields = ProductOption.AllFields
type CreateRequest = ProductOption.CreateRequest
type UpdateRequest = ProductOption.UpdateRequest
type Draft = ProductOption.Draft
type FieldOptions = ProductOption.FieldOptions
type Layout = ProductOption.Layout

export const get = createAsyncAction(
    'product_option/GET_REQUEST',
    'product_option/GET_SUCCESS',
    'product_option/GET_FAILURE'
)<void, AllFields[], Error>()

export const getFields = createAsyncAction(
    'product_option/GET_FIELDS_REQUEST',
    'product_option/GET_FIELDS_SUCCESS',
    'product_option/GET_FIELDS_FAILURE'
)<void, FieldOptions, Error>()

export const create = createAsyncAction(
    'product_option/CREATE_REQUEST',
    'product_option/CREATE_SUCCESS',
    'product_option/CREATE_FAILURE'
)<CreateRequest, AllFields, Error>()

export const update = createAsyncAction(
    'product_option/UPDATE_REQUEST',
    'product_option/UPDATE_SUCCESS',
    'product_option/UPDATE_FAILURE'
)<UpdateRequest, AllFields, Common.ItemError>()

export const deleteDraft = createAsyncAction(
    'product_option/DELETE_DRAFT_REQUEST',
    'product_option/DELETE_DRAFT_SUCCESS',
    'product_option/DELETE_DRAFT_FAILURE'
)<Draft, Draft, Common.ItemError>()

export const sync = createAsyncAction(
    'product_option/SYNC_REQUEST',
    'product_option/SYNC_SUCCESS',
    'product_option/SYNC_FAILURE'
)<AllFields, AllFields, Common.ItemError>()

export const updateStatus = createAsyncAction(
    'product_option/UPDATE_STATUS_REQUEST',
    'product_option/UPDATE_STATUS_SUCCESS',
    'product_option/UPDATE_STATUS_FAILURE'
)<AllFields, AllFields, Common.ItemError>()

export const clone = createAsyncAction(
    'product_option/CLONE_REQUEST',
    'product_option/CLONE_SUCCESS',
    'product_option/CLONE_FAILURE'
)<string, AllFields, Common.ItemError>()

export const startImport = createAsyncAction(
    'product_option/START_IMPORT_REQUEST',
    'product_option/START_IMPORT_SUCCESS',
    'product_option/START_IMPORT_FAILURE'
)<any, ApiSuccess<ProductOption.ImportResponse[]>, Common.ItemError>()

export const submitImport = createAsyncAction(
    'product_option/SUBMIT_IMPORT_REQUEST',
    'product_option/SUBMIT_IMPORT_SUCCESS',
    'product_option/SUBMIT_IMPORT_FAILURE'
)<{ items: ProductOption.ImportSubmit[] }, ApiSuccess<void>, Common.ItemError>()

export const setImportReviewStep = createAction(
    'product_option/SET_IMPORT_REVIEW_STEP'
)<number>()

export const setView = createAction(
    'product_option/SET_VIEW_NEW_RELIC'
)<Layout>()

export const clearError = createAction('product_option/CLEAR_ERROR')<void>()
export const clearErrorFor = createAction(
    'product_option/CLEAR_ERROR_FOR'
)<string>()
