/* eslint-disable react-hooks/rules-of-hooks */
import Select, { SelectProps } from 'antd/lib/select'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { strings } from '../../constants'
import {
    selectOptionFilterDataByProductId,
    selectProductOptionsById,
} from '../../store/product-options/product-options.selectors'
import styles from './MarketingTestIdFilter.module.scss'

interface RouteParams {
    productId: string
}

export const MarketingTestIdFilter: React.FC<SelectProps<any>> = (props) => {
    const { productId } = useParams<RouteParams>()

    const optionIds = useSelector((state: RootState) =>
        selectOptionFilterDataByProductId(state, productId)
    )
    const optionsById = useSelector((state: RootState) =>
        selectProductOptionsById(state)
    )

    const localizedTests: string[] = []
    optionIds.localized.forEach(({ id }) => {
        const { testId } = optionsById[id].payload

        if (testId) localizedTests.push(testId)
    })

    const uniqueWithLabel = [
        ...Array.from(new Set(localizedTests))
            .sort()
            .map((testId) => {
                return { label: testId, value: testId }
            }),
        { label: 'NO TESTING ID', value: strings.noTestingID },
    ]

    return (
        <Select
            allowClear
            className={styles.marketingTestId}
            mode="multiple"
            options={uniqueWithLabel}
            placeholder="All test IDs"
            showArrow
            showSearch
            size="large"
            {...props}
        />
    )
}
