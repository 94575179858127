import { Space, Table, Typography } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import React, { useState } from 'react'
import { ProductInfo, SimpleInfo } from '../../product-options/cxn-po.interface'
import {
    BaseProductsLinkButtonCol,
    BaseProductsStatusCol,
    ExpandProductCnx,
} from './BaseProductsComponents'
const { Text } = Typography

const dataSort = (a: SimpleInfo, b: SimpleInfo) => (a.name < b.name ? -1 : 1)

// ? -------------------------------- Columns -------------------------------- //

const makeNameColumn = (): ColumnType<ProductInfo | SimpleInfo> => ({
    title: 'Product Name',
    dataIndex: 'name',
    width: 150,
    ellipsis: true,
})

const makeConnectionsColumn = (): ColumnType<ProductInfo | SimpleInfo> => ({
    title: 'Product Connections',
    width: 230,
    align: 'center',
    render: (data) => (
        <Space>
            <Text>{`${data.upgrade} Upgrade`}</Text>
            <Text>{`${data.upsell} Upsell`}</Text>
        </Space>
    ),
})

const statusColumn: ColumnType<ProductInfo | SimpleInfo> = {
    title: 'Configuration Status',
    align: 'center',
    width: 230,
    render: () => <BaseProductsStatusCol />,
}

const configure: ColumnType<ProductInfo | SimpleInfo> = {
    align: 'center',
    width: 230,
    render: (data: SimpleInfo) => <BaseProductsLinkButtonCol id={data.id} />,
}

// ? -------------------------------- COLUMN PROPS -------------------------------- //

const defaultTableProps: TableProps<ProductInfo | SimpleInfo> = {
    showSorterTooltip: false,
    showHeader: true,
    // bordered: true,
    size: 'middle',
    pagination: false,
    rowKey: (record) => record.id,
}

// ? -------------------------------- Component -------------------------------- //
type Props = {
    baseProducts: SimpleInfo[]
}

export const BaseProducts = (props: Props) => {
    const [upsell, setUpsell] = useState<SimpleInfo[]>([])
    const [upgrade, setUpgrade] = useState<SimpleInfo[]>([])
    const [expandRow, SetExpandRow] = useState<string>('')

    const handleTableExpand = async (expanded: boolean, record: SimpleInfo) => {
        if (expanded) {
            SetExpandRow(record.id)
            props.baseProducts.map((e: any) => {
                if (e.id === record.id) {
                    setUpsell(e.upsellProduct)
                    setUpgrade(e.upgradeProduct)
                }
            })
        } else {
            SetExpandRow('')
        }
    }

    return (
        <div>
            <h3>
                <b>Base Products</b>
            </h3>
            <Table
                {...defaultTableProps}
                dataSource={props.baseProducts.sort(dataSort)}
                columns={[
                    makeNameColumn(),
                    makeConnectionsColumn(),
                    //statusColumn,
                    configure,
                ]}
                expandable={{
                    expandedRowRender: () => (
                        <ExpandProductCnx upsell={upsell} upgrade={upgrade} />
                    ),
                    expandRowByClick: false,
                }}
                onExpand={handleTableExpand}
                pagination={false}
                expandedRowKeys={[expandRow]}
            />
        </div>
    )
}
