import React from 'react'
import { testIds } from '../../constants'
import { ProductOption } from '../../store/typings/product-option.interface'
import { Product } from '../../store/typings/product.interface'
import { HorizontalList } from '../horizontal-list/HorizontalList'
import { Timestamp } from '../timestamp/Timestamp'
import styles from './History.module.scss'

interface Props {
    data: Product.AllFields | ProductOption.AllFields
}

export const History: React.FC<Props> = ({ data }) => {
    return (
        <HorizontalList className={styles.history}>
            <Timestamp
                label="Created"
                timestamp={data.createdTimestamp}
            ></Timestamp>
            <Timestamp
                label="Modified"
                timestamp={data.modifiedTimestamp}
                data-testid={testIds.productModified}
            ></Timestamp>
            <Timestamp
                label="Synced"
                timestamp={data.syncedTimestamp}
            ></Timestamp>
        </HorizontalList>
    )
}
