import { createAction, createAsyncAction } from 'typesafe-actions'
import { Common } from '../typings/common.interface'
import { ContentLibrary } from '../typings/content-libraries.interface'

export const get = createAsyncAction(
    'content-libraries/GET_REQUEST',
    'content-libraries/GET_SUCCESS',
    'content-libraries/GET_FAILURE'
)<string, ContentLibrary, Common.ItemError>()

export const clearErrorFor = createAction(
    'content-libraries/CLEAR_ERROR_FOR'
)<string>()
