import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { SecondaryButton } from '../../../components/buttons/Buttons'
import { strings } from '../../../constants'
import { defaultState } from '../../../store/filters/filters.reducer'
import { selectLocalizedProductOptionFilters } from '../../../store/filters/filters.selectors'
import { actions } from '../../../store/root-action'
import { ProductOptionEnum } from '../../../store/typings/product-option.enum'
import { AssociatedOptionFilter } from '../../filters/AssociatedOptionFilter'
import { CountryFilter } from '../../filters/CountryFilter'
import { MarketingTestIdFilter } from '../../filters/MarketingTestIdFilter'
import { NameFilter } from '../../filters/NameFilter'
import { StatusFilter } from '../../filters/StatusFilter'
import { TagFilter } from '../../filters/TagFilter'
import styles from './LocalizedProductOptionsFilterInputs.module.scss'

export const LocalizedProductOptionFilterInputs: React.FC = () => {
    const dispatch = useDispatch()

    const filters = useSelector((state: RootState) =>
        selectLocalizedProductOptionFilters(state)
    )

    // status
    const setStateFilter = (state: ProductOptionEnum.State[]) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({ ...filters, state })
        )
    const onStateFilterChange = useCallback(setStateFilter, [dispatch, filters])

    // name
    const setNameFilter = (name: string) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({ ...filters, name })
        )
    const onNameFilterChange = useCallback(setNameFilter, [dispatch, filters])

    // country
    const setCountryFilter = (country: string[]) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({
                ...filters,
                country,
            })
        )
    const onCountryFilterChange = useCallback(setCountryFilter, [
        dispatch,
        filters,
    ])

    // associated product option
    const setAssociatedOptionFilter = (associatedProductOption: string[]) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({
                ...filters,
                associatedProductOption: associatedProductOption ?? [],
            })
        )
    const onAssociatedOptionFilterChange = useCallback(
        setAssociatedOptionFilter,
        [dispatch, filters]
    )

    // tags
    const setTagFilter = (tags: ProductOptionEnum.Tags[]) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({ ...filters, tags })
        )
    const onTagFilterChange = useCallback(setTagFilter, [dispatch, filters])

    // test ID
    const setMarketingTestIdFilter = (marketingTestId: string[]) =>
        dispatch(
            actions.filters.filterLocalizedProductOption({
                ...filters,
                testId: marketingTestId,
            })
        )
    const onMarketingTestIdFilterChange = useCallback(
        setMarketingTestIdFilter,
        [dispatch, filters]
    )

    // reset
    const setDefaultFilters = () =>
        dispatch(
            actions.filters.filterLocalizedProductOption({
                ...defaultState.localizedProductOption,
            })
        )
    const onFiltersReset = useCallback(setDefaultFilters, [dispatch])

    return (
        <>
            <Row
                className={classNames(styles.container, 'row-gap-18')}
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
            >
                <Col flex="1">
                    <label>{strings.search}</label>
                    <NameFilter
                        onChange={(e: any) =>
                            onNameFilterChange(e.target.value)
                        }
                        onSearch={onNameFilterChange}
                        placeholder={strings.filterNamePlaceholder}
                        value={filters.name}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.filterStatusLabel}</label>
                    <StatusFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onStateFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as ProductOptionEnum.State[]
                            )
                        }
                        value={filters.state}
                    />
                </Col>
            </Row>
            <Row
                className={classNames(styles.container, 'row-gap-18')}
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
            >
                <Col flex="1">
                    <label>{strings.associatedProductOption}</label>
                    <AssociatedOptionFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onAssociatedOptionFilterChange(
                                options.map((option: any) => option.value)
                            )
                        }
                        value={filters.associatedProductOption}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.filterCountryLabel}</label>
                    <CountryFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onCountryFilterChange(
                                options.map((option: any) => option.value)
                            )
                        }
                        value={filters.country}
                    />
                </Col>
            </Row>
            <Row
                className={classNames(styles.container, 'row-gap-18')}
                gutter={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }, 18]}
            >
                <Col flex="1">
                    <label>{strings.marketingTestIDFilter}</label>
                    <MarketingTestIdFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onMarketingTestIdFilterChange(
                                options.map((option: any) => option.value)
                            )
                        }
                        value={filters.testId}
                    />
                </Col>
                <Col flex="1">
                    <label>{strings.filterTagLabel}</label>
                    <TagFilter
                        onChange={(
                            value: string,
                            options: any | Array<any> // ? OptionsType | OptionData | OptionGroupData
                        ) =>
                            onTagFilterChange(
                                options.map(
                                    (option: any) => option.value
                                ) as ProductOptionEnum.Tags[]
                            )
                        }
                        value={filters.tags}
                    />
                </Col>
                <Col flex="0">
                    <SecondaryButton
                        icon={<CloseCircleOutlined />}
                        onClick={onFiltersReset}
                    >
                        {strings.resetFiltersBtn}
                    </SecondaryButton>
                </Col>
            </Row>
        </>
    )
}
