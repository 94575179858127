type RequestStates = 'idle' | 'progress' | 'error'

export enum OktaGroups {
    Admin = 'Product Catalog Admin',
    Beta = 'Product Catalog Beta',
    Devs = 'Product Catalog Devs',
    Everyone = 'Everyone',
}

// -------------------------------- TYPES -------------------------------- //
export type UserInfo = {
    email: string
    firstName: string
    groups: OktaGroups[]
    lastName: string
    name: string
    timezone: string
}

export type ChefAuth = {
    tokenError: any
    requestState: RequestStates
    isAuthenticated: boolean
}

export type AuthCtx = {
    setToken: () => Promise<void>
    clearTokenError: () => any
    user: UserInfo
    chefAuth: ChefAuth
}
