import { mapValues } from 'lodash'
import omit from 'object.omit'
import { createSelector } from 'reselect'
import { RootState } from 'typesafe-actions'
import { Common } from '../typings/common.interface'
import { Product } from '../typings/product.interface'

// New products will receive the draft data + fields managed in db
const newOrExistingPayload = ({
    payload,
    ...apiMeta
}: Common.ApiItem<Product.AllFields>) => ({
    ...apiMeta,
    payload: payload.draft?.meta.isNew
        ? {
              ...payload.draft,
              contentLibraries: payload.contentLibraries,
              features: payload.features,
          }
        : payload,
})

/* --------- Standard selectors --------- */

export const selectCategoryOptions = ({ products }: RootState) =>
    products.fieldOptions.category

export const selectCustomerTypeOptions = ({ products }: RootState) =>
    products.fieldOptions.customerType

export const selectGroupOptions = ({ products }: RootState) =>
    products.fieldOptions.groups

export const selectProductTypeOptions = ({ products }: RootState) =>
    products.fieldOptions.type

export const selectLibraryScopeOptions = ({ products }: RootState) =>
    products.fieldOptions.libraryScope

/* --------- Memoized selectors --------- */

export const selectProductsApiMeta = createSelector(
    ({ products }: RootState) => products,
    (products) => omit(products, 'byId')
)

// Selecting either draft or existing product happens here,
// so this selector should be reused when possible to avoid redundancy
export const selectProductsById = createSelector(
    ({ products }: RootState) => products.byId,
    (byId) => mapValues(byId, newOrExistingPayload)
)

export const selectProduct = createSelector(
    selectProductsById,
    (state: RootState, id: string) => id,
    (byId, id) => byId[id]?.payload
)

export const selectProductWithMeta = createSelector(
    selectProductsById,
    (state: RootState, id: string) => id,
    (byId, id) => byId[id]
)

export const selectProductSkus = createSelector(
    selectProductsById,
    (productsById): { [sku: string]: boolean } => {
        const skus: any = {}

        Object.values<Common.ApiItem<Product.AllFields>>(productsById)
            .map((p) => p.payload)
            .forEach((product) => {
                skus[product.sku] = true
            })

        return skus
    }
)

export const selectNewAndExistingProducts = createSelector(
    selectProductsById,
    (productsById) =>
        Object.values<Common.ApiItem<Product.AllFields>>(productsById).map(
            (p) => p.payload
        )
)
