import { DrawerProps } from 'antd/lib/drawer'
import React from 'react'
import { CancelButton, SubmitButton } from '../buttons/Buttons'
import { Drawer } from '../drawer/Drawer'

interface Props extends DrawerProps {
    titleText: string
    formName: string
    submitText: string
    onClose: () => void
    disableSave?: boolean
}

export const FormDrawer: React.FC<Props> = ({
    onClose,
    children,
    formName,
    submitText,
    disableSave,
    ...props
}) => (
    <Drawer
        footer={
            <div data-testid={`${formName}Footer`}>
                <SubmitButton form={formName} disabled={disableSave}>
                    {submitText}
                </SubmitButton>
                <CancelButton onClick={onClose} />
            </div>
        }
        onClose={onClose}
        {...props}
    >
        {children}
    </Drawer>
)
