import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { ProductEnum } from '../../store/typings/product.enum'
import styles from './CustomerType.module.scss'

export const CustomerTypeFilter: React.FC<SelectProps<any>> = (props) => {
    const options = [
        ...Object.values(ProductEnum.CustomerType).map(
            (customerType: string) => {
                return { label: customerType, value: customerType }
            }
        ),
    ]

    return (
        <Select
            allowClear
            className={styles.customerType}
            mode="multiple"
            options={options}
            placeholder="All customer types"
            showArrow
            size="large"
            {...props}
        />
    )
}
