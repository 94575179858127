import { Col, Row, Typography } from 'antd'
import fileDownload from 'js-file-download'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '../../../components/buttons/Buttons'
import config from '../../../config'
import apiService from '../../../services/api.service'
import { actions } from '../../../store/root-action'
import { AdminTools } from '../../../store/typings/admin-tools.interface'
import { BackToTools } from '../BackToTools'
import styles from './ExportProductOptions.module.scss'

const { Title, Paragraph } = Typography

type Props = AdminTools.Tool

export const ExportProductOptions: React.FC<Props> = ({
    description,
    title,
}) => {
    const dispatch = useDispatch()
    const exportAllProductOptions = async () => {
        try {
            const data = await apiService.exportAllProductOptions()
            const envName = config.isStaging
                ? 'Staging_'
                : config.isProduction
                ? 'Production_'
                : 'Development_'

            fileDownload(data, `product_options_${envName}.csv`)
            dispatch(
                actions.products.exportProductOptions.success({
                    data,
                    message: 'Product options were successfully exported!',
                })
            )
        } catch (err: any) {
            dispatch(actions.products.exportAllProductOptions.failure(err))
        }
    }

    return (
        <>
            <Helmet>
                <title>Export Product Options - Product Catalog</title>
            </Helmet>
            <div className={styles.container}>
                <BackToTools />
                <Title>{title}</Title>
                <Paragraph>{description}</Paragraph>

                <Row gutter={[12, 24]} className={'row-gap-24'} align="middle">
                    <Col>
                        <PrimaryButton onClick={exportAllProductOptions}>
                            Export
                        </PrimaryButton>
                    </Col>
                </Row>
            </div>
        </>
    )
}
