import { List } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import React from 'react'
import { ProductOptionFilterFields } from '../../../store/product-options/product-options.selectors'
import { ProductOption } from './ProductOption'
import styles from './ProductOptionList.module.scss'

type Props = {
    options: ProductOptionFilterFields[]
    totalOptions: number
}

const displayResults = (total: number, [start, end]: [number, number]) => (
    <div>{`${start} - ${end} of ${total}`} </div>
)

const PAGE_SIZE = 10

export const ProductOptionsList: React.FC<Props> = ({
    options,
    totalOptions,
}) => {
    const pagination: PaginationConfig = {
        pageSize: PAGE_SIZE,
        position: 'bottom',
        showLessItems: true,
        showSizeChanger: false,
        showTotal: displayResults,
        size: 'default',
        total: options.length,
    }

    return (
        <List
            className={styles.list}
            header={<div />}
            dataSource={options}
            renderItem={({ id }) => <ProductOption key={id} optionId={id} />}
            pagination={totalOptions > PAGE_SIZE ? pagination : false}
        />
    )
}
