import { AppstoreOutlined, DatabaseOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import { EmptyState } from '../../../components/empty-state/EmptyState'
import { strings } from '../../../constants'
import { selectLocalizedProductOptionFilters } from '../../../store/filters/filters.selectors'
import {
    ProductOptionFilterFields,
    selectProductOptionView,
} from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import styles from './LocalizedProductOptions.module.scss'
import { LocalizedProductOptionFilterInputs } from './LocalizedProductOptionsFilterInputs'
import { ProductOptionsList } from './ProductOptionList'
import { View } from './ProductOptions'
import { ProductOptionsTable } from './ProductOptionsTable'
import { filterProductOptions } from './utils'

type OwnProps = {
    optionData: ProductOptionFilterFields[]
}

export const LocalizedProductOptions: React.FC<OwnProps> = ({ optionData }) => {
    const dispatch = useDispatch()

    const currentView: any = useSelector((state: RootState) =>
        selectProductOptionView(state)
    )

    const selectView = (clickedView: View) => {
        if (clickedView === View.LIST && clickedView !== currentView.layout) {
            dispatch(
                actions.productOptions.setView({
                    layout: View.LIST,
                })
            )
        } else if (
            clickedView === View.CARD &&
            clickedView !== currentView.layout
        ) {
            dispatch(
                actions.productOptions.setView({
                    layout: View.CARD,
                })
            )
        }
    }

    const filters: any = useSelector((state: RootState) =>
        selectLocalizedProductOptionFilters(state)
    )

    const filteredOptions = useMemo(
        () => filterProductOptions(optionData, filters),
        [optionData, filters]
    )

    const filteredResults = () =>
        filteredOptions.length !== optionData.length
            ? strings.filterResults + ': ' + filteredOptions.length
            : strings.filterResultsAll

    return (
        <div className={styles.container}>
            <LocalizedProductOptionFilterInputs />
            <div className={styles.productOptions}>
                {filteredOptions.length > 0 ? (
                    <>
                        <div className={styles.results}>
                            {filteredResults()}
                            <LinkButton
                                className={classNames(
                                    styles.viewToggle,
                                    currentView.layout === View.CARD
                                        ? styles.active
                                        : undefined
                                )}
                                icon={<AppstoreOutlined />}
                                onClick={() => selectView(View.CARD)}
                            />
                            <LinkButton
                                className={classNames(
                                    styles.viewToggle,
                                    currentView.layout === View.LIST
                                        ? styles.active
                                        : undefined
                                )}
                                icon={<DatabaseOutlined />}
                                onClick={() => selectView(View.LIST)}
                            />
                        </div>
                        {currentView.layout === View.CARD ? (
                            <ProductOptionsList
                                options={filteredOptions}
                                totalOptions={optionData.length}
                            />
                        ) : (
                            <ProductOptionsTable
                                productOptions={filteredOptions}
                            />
                        )}
                    </>
                ) : (
                    <EmptyState />
                )}
            </div>
        </div>
    )
}
