import { getSocketDomain } from '../config'
import { socketCodes } from '../constants'

const { NORMAL_CLOSURE, ABNORMAL_CLOSURE } = socketCodes

export type WebsocketEvents =
    | 'queue.active'
    | 'queue.completed'
    | 'queue.error'
    | 'queue.failed'
    | 'queue.stalled'

export type WebSocketMessage<T> = {
    event: WebsocketEvents
    payload: T
}

type SubHandlers = { [k in WebsocketEvents]: ((data: any) => void)[] }

class SocketService {
    private socket: WebSocket | null

    private subscribers: SubHandlers = {
        'queue.active': [],
        'queue.completed': [],
        'queue.error': [],
        'queue.failed': [],
        'queue.stalled': [],
    }

    private connectionCounter = 0
    private interval: any

    constructor() {
        this.socket = null

        this.messageHandler = this.messageHandler.bind(this)
    }

    init() {
        this.connectionCounter += 1
        if (!this.socket) {
            this.socket = new WebSocket(getSocketDomain())

            this.socket.onclose = (e) => {
                if (e.code === ABNORMAL_CLOSURE) {
                    this.connectionCounter -= 1
                    this.socket = null
                    clearInterval(this.interval)
                    this.interval = setInterval(() => {
                        this.init()
                    }, 5000)
                }
            }

            this.socket.onopen = () => {
                clearInterval(this.interval)
                this.interval = null
            }

            this.socket.onerror = (e) => {
                console.error('Error on websocket ', e)
            }

            this.socket.onmessage = (e) => {
                const message: WebSocketMessage<any> = JSON.parse(e.data)
                if (this.subscribers[message.event]) {
                    this.messageHandler(message)
                }
            }
        }

        return this
    }

    subscribe(key: WebsocketEvents, handler: (data: any) => any) {
        this.subscribers[key].push(handler)
        return this
    }

    private messageHandler({ event, payload }: WebSocketMessage<any>) {
        this.subscribers[event].forEach((v) => v(payload))
    }

    disconnect() {
        if (this.socket) {
            this.connectionCounter -= 1

            if (this.connectionCounter === 0) {
                this.socket.close(NORMAL_CLOSURE)
                this.socket = null
            }
        }
    }
}

export const socketService = new SocketService()
