import { Table } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import React from 'react'
import { SimpleInfo } from '../../product-options/cxn-po.interface'
import styles from '../CxnProductPageHeader.module.scss'

export type AddOnProductsMappingRes = {
    fromProductName: string
}

const titleColumn: ColumnType<SimpleInfo> = {
    title: 'Product Name',
    dataIndex: 'name',
}

const defaultTableProps: TableProps<SimpleInfo> = {
    showHeader: false,
    bordered: false,
    pagination: false,
    size: 'middle',
    rowKey: (record) => record.id,
}

type Props = {
    addOnProducts: SimpleInfo[]
}

export const AddOnProducts = (props: Props) => {
    const addOnProducts = props.addOnProducts

    return (
        <div className={styles.addOnProducts}>
            <h3>
                <b>Add-on Products</b>
            </h3>
            <Table
                {...defaultTableProps}
                dataSource={addOnProducts}
                columns={[titleColumn]}
                pagination={false}
            />
        </div>
    )
}
