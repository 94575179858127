import { omit } from 'lodash'
import { combineReducers } from 'redux'
import { ActionType, createReducer, StateType } from 'typesafe-actions'
import { actions } from '../root-action'
import { Common } from '../typings/common.interface'
import { Product } from '../typings/product.interface'
import {
    isNew,
    mapPayloadById,
    mergePayload,
    now,
    setPayload,
    setRequestError,
    setSaveInProgress,
} from '../utils'

export type ProductsById = Common.ItemsById<Product.AllFields>

export interface ProductsDefaultState {
    byId: ProductsById
    error: Common.ApiErrors
    fieldOptions: Product.FieldOptions
    isFetching: boolean
    isSaving: boolean
    receivedAt: Common.ReceivedAt
}

export const defaultState: ProductsDefaultState = {
    byId: {},
    error: {
        onFetch: null,
        onSave: null,
    },
    fieldOptions: {
        category: [],
        customerType: [],
        groups: [],
        libraryScope: [],
        type: [],
    },
    isFetching: false,
    isSaving: false,
    receivedAt: null,
}

export const byIdReducer = createReducer(defaultState.byId)
    .handleAction(
        [
            actions.products.update.request,
            actions.products.deleteDraft.request,
            actions.products.toggleFeatures.request,
        ],
        (state, action) => setSaveInProgress(state, action.payload.id)
    )
    .handleAction(actions.products.get.success, (state, action) =>
        mapPayloadById(action.payload)
    )
    .handleAction(
        [actions.products.sync.success, actions.products.updateStatus.success],
        (state, action) => setPayload(state, action.payload.data)
    )
    .handleAction(
        [
            actions.products.create.success,
            actions.products.update.success,
            actions.products.assignLibrary.success,
            actions.products.unassignLibrary.success,
            actions.products.toggleFeatures.success,
        ],
        (state, action) => setPayload(state, action.payload.data)
    )
    .handleAction(actions.products.clone.success, (state, action) => {
        return setPayload(state, omit(action.payload.data, ['productOptions']))
    })
    .handleAction(actions.products.deleteDraft.success, (state, action) => {
        const { payload } = action

        if (isNew(payload.data)) {
            return omit(state, payload.data.id)
        }

        return mergePayload(state, payload.data.id, {
            draft: null,
        })
    })
    .handleAction(
        [
            actions.products.update.failure,
            actions.products.assignLibrary.failure,
            actions.products.deleteDraft.failure,
            actions.products.toggleFeatures.failure,
        ],
        (state, action) =>
            setRequestError(state, action.payload.id, action.payload.error)
    )
    .handleAction(actions.products.clearErrorFor, (state, action) =>
        setRequestError(state, action.payload, null)
    )

export const fieldOptionsReducer = createReducer(
    defaultState.fieldOptions
).handleAction(actions.products.getFields.success, (state, action) => ({
    ...state,
    ...action.payload,
}))

export const isFetchingReducer = createReducer(defaultState.isFetching)
    .handleAction(actions.products.get.request, () => true)
    .handleAction(
        [actions.products.get.success, actions.products.get.failure],
        () => false
    )

export const isSavingReducer = createReducer(defaultState.isSaving)
    .handleAction(actions.products.create.request, () => true)
    .handleAction(
        [actions.products.create.success, actions.products.create.failure],
        () => false
    )

export const errorReducer = createReducer(defaultState.error)
    .handleAction(
        [actions.products.get.request, actions.products.clearError],
        () => ({ onFetch: null, onSave: null })
    )
    .handleAction(actions.products.get.failure, (state, action) => ({
        ...state,
        onFetch: action.payload,
    }))
    .handleAction(actions.products.create.failure, (state, action) => ({
        ...state,
        onSave: action.payload,
    }))

export const receivedAtReducer = createReducer(
    defaultState.receivedAt
).handleAction(actions.products.get.success, now)

export const productsReducer = combineReducers({
    byId: byIdReducer,
    error: errorReducer,
    fieldOptions: fieldOptionsReducer,
    isFetching: isFetchingReducer,
    isSaving: isSavingReducer,
    receivedAt: receivedAtReducer,
})

// Redux Types
export type ProductAction = ActionType<typeof actions.products>
export type ProductState = StateType<typeof productsReducer>
