export const taxCodes: string[] = [
    'ASP - hosted software (SaaS)',
    'Cloud Services - SaaS',
    'DC010500',
    'Digital Audio Visual Works',
    'Flow On-Prem Install and Implementation',
    'Flow On-Prem Support',
    'General Business Consulting',
    'Implementation Services Saas',
    'Online Training',
    'Prewritten Software Delivered Electronically',
    'SC070101',
    'SP140000',
    'SW050501',
    'SW053005',
    'ON030000', // Nontaxable
]
