import { MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Can } from '../../../Authentication/permissions/Can'
import { LinkButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { strings } from '../../../constants'
import apiService from '../../../services/api.service'
import { actions } from '../../../store/root-action'
import { Product } from '../../../store/typings/product.interface'
import { Copy } from '../../copy/Copy'
import styles from './ContentLibraryDisplay.module.scss'

interface Props {
    library: Product.ContentLibrary
    product: Product.AllFields
}

export const ContentLibraryActions: React.FC<Props> = ({
    library,
    product,
}) => {
    const dispatch = useDispatch()

    const confirmUnassign = useCallback(() => {
        Modal.confirm({
            cancelText: 'Close',
            icon: <QuestionCircleOutlined />,
            okText: 'Yes, remove',
            title: 'Remove library?',
            content: (
                <p>
                    Are you sure you want to remove library{' '}
                    <b>{library.title}</b> from <b>{product.name}</b>?
                </p>
            ),
            async onOk() {
                try {
                    const data = await apiService.unassignLibrary({
                        id: library.id,
                        productId: product.id,
                    })

                    dispatch(
                        actions.products.unassignLibrary.success({
                            data,
                            message: 'Library successfully removed!',
                        })
                    )
                } catch (error) {
                    dispatch(actions.products.unassignLibrary.failure(error))
                }
            },
        })
    }, [library, product, dispatch])

    return (
        <HorizontalList>
            <Copy
                clipboard={library.id}
                title="Copy ID?"
                label="Copy ID"
                className={styles.action}
            />
            <Can I="manage" a="ContentLibrary">
                <LinkButton
                    className={styles.remove}
                    onClick={confirmUnassign}
                    icon={<MinusCircleOutlined />}
                >
                    {strings.removeLibraryBtn}
                </LinkButton>
            </Can>
        </HorizontalList>
    )
}
