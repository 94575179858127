import Form from 'antd/lib/form'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Optional } from 'utility-types'
import { FormDrawer } from '../../../components/form/FormDrawer'
import { testIds } from '../../../constants'
import { FormName } from '../../../store/forms/forms.reducer'
import { actions } from '../../../store/root-action'
import { Product } from '../../../store/typings/product.interface'
import {
    CategoryField,
    CustomerFacingDescription,
    CustomerTypeField,
    GroupsField,
    IsUserAssignableField,
    LevelField,
    LibraryScopeField,
    NameField,
    PreserveProvisionedField,
    ProductTypeField,
    SkuField,
} from '../ProductFormFields'
import styles from './ProductForm.module.scss'

export interface ProductFormProps {
    initialValues?: Optional<Product.AllFields>
    onSubmit?: () => void
    visible: boolean
}

type Props = ProductFormProps & {
    name: FormName
    submitAction: (data: Product.AllFields) => void
    submitText: string
    title: string
}

export const ProductForm: React.FC<Props> = ({
    initialValues,
    name,
    onSubmit,
    submitAction,
    submitText,
    title,
    visible,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const closeForm = useCallback(() => {
        dispatch(actions.forms.close(name))
    }, [name, dispatch])

    const handleFormSubmit = useCallback(
        (fields: any) => {
            const action = submitAction({
                ...fields,
                id: initialValues?.meta?.parentId || initialValues?.id,
                customerFacingDescription:
                    fields.customerFacingDescription || '',
            })

            dispatch(action)
            onSubmit?.()
            closeForm()
        },
        [closeForm, dispatch, initialValues, onSubmit, submitAction]
    )

    const formFields = [
        NameField,
        (props: any) => <SkuField initialSku={initialValues?.sku} {...props} />,
        CategoryField,
        CustomerTypeField,
        GroupsField,
        ProductTypeField,
        LibraryScopeField,
        LevelField,
        PreserveProvisionedField,
        IsUserAssignableField,
        CustomerFacingDescription,
    ]

    return (
        <FormDrawer
            titleText={title}
            visible={visible}
            onClose={closeForm}
            formName={name}
            submitText={submitText}
            width={800}
        >
            <div data-testid={testIds.productForm}>
                <Form
                    className={styles.productForm}
                    form={form}
                    hideRequiredMark
                    layout="vertical"
                    name={name}
                    onFinish={handleFormSubmit}
                    initialValues={initialValues}
                    preserve={false}
                >
                    <div>
                        {formFields.map((Field, index) => (
                            <Field key={index} form={form} />
                        ))}
                    </div>
                </Form>
            </div>
        </FormDrawer>
    )
}
