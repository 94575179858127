import omit from 'object.omit'
import { createReducer, PayloadAction } from 'typesafe-actions'
import { v4 as uuidv4 } from 'uuid'
import { actions } from '../root-action'
import { AlertEnum } from '../typings/alert.enum'
import { Alert } from '../typings/alert.interface'

export interface AlertsById {
    [id: string]: Alert.Success | Alert.Fail | Alert.Info
}

export const defaultState: AlertsById = {}

const addAlert = (state: AlertsById, alert: any, type: AlertEnum.Type) => {
    const id = uuidv4()
    return {
        ...state,
        [id]: { id, type, ...alert },
    }
}

const logBrowserError = (
    action: PayloadAction<'alert/ADD_FAIL', Alert.Base>
) => {
    const errorDetail = action.payload.details?.reduce(
        (detail: string, { message }: Alert.Base) => detail + message,
        ''
    )
    window.NREUM?.noticeError(new Error(action.payload.message), {
        errorDetail,
    })
}

// Reducers
export const alertsReducer = createReducer(defaultState)
    .handleAction(actions.alerts.addFail, (state, action) => {
        logBrowserError(action)
        return addAlert(state, action.payload, AlertEnum.Type.Fail)
    })
    .handleAction(actions.alerts.addSuccess, (state, action) =>
        addAlert(state, action.payload, AlertEnum.Type.Success)
    )
    .handleAction(actions.alerts.remove, (state, action) =>
        omit(state, action.payload)
    )
    .handleAction(actions.alerts.clear, (state, action) => action.payload)
    .handleAction(actions.alerts.addInfo, (state, action) =>
        addAlert(state, action.payload, AlertEnum.Type.Info)
    )
