import React from 'react'
import { Footer } from '../app/Footer'
import { Header } from '../app/Header'

export const withHeaderAndFooter = (WrappedComponent: any) => {
    const WithHeaderFooter = (props: any) => {
        return (
            <div
                style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <Header />
                    <WrappedComponent {...props} />
                </div>
                <Footer />
            </div>
        )
    }

    return WithHeaderFooter
}
