import { createAction, createAsyncAction } from 'typesafe-actions'
import { ApiSuccess } from '../root-action'
import { Common } from '../typings/common.interface'
import { Product } from '../typings/product.interface'

type AllFields = Product.AllFields
type CreateRequest = Product.CreateRequest
type UpdateRequest = Product.UpdateRequest
type FieldOptions = Product.FieldOptions

type AllFieldSuccess = ApiSuccess<AllFields>

export const get = createAsyncAction(
    'product/GET_REQUEST',
    'product/GET_SUCCESS',
    'product/GET_FAILURE'
)<void, AllFields[], Error>()

export const getFields = createAsyncAction(
    'product/GET_FIELDS_REQUEST',
    'product/GET_FIELDS_SUCCESS',
    'product/GET_FIELDS_FAILURE'
)<void, FieldOptions, Error>()

export const create = createAsyncAction(
    'product/CREATE_REQUEST',
    'product/CREATE_SUCCESS',
    'product/CREATE_FAILURE'
)<CreateRequest, AllFieldSuccess, Error>()

export const update = createAsyncAction(
    'product/UPDATE_REQUEST',
    'product/UPDATE_SUCCESS',
    'product/UPDATE_FAILURE'
)<UpdateRequest, AllFieldSuccess, Common.ItemError>()

export const deleteDraft = createAsyncAction(
    'product/DELETE_DRAFT_REQUEST',
    'product/DELETE_DRAFT_SUCCESS',
    'product/DELETE_DRAFT_FAILURE'
)<AllFields, AllFieldSuccess, Common.ItemError>()

export const sync = createAsyncAction(
    'product/SYNC_REQUEST',
    'product/SYNC_SUCCESS',
    'product/SYNC_FAILURE'
)<AllFields, AllFieldSuccess, Common.ItemError>()

export const updateStatus = createAsyncAction(
    'product/UPDATE_STATUS_REQUEST',
    'product/UPDATE_STATUS_SUCCESS',
    'product/UPDATE_STATUS_FAILURE'
)<AllFields, AllFieldSuccess, Common.ItemError>()

export const assignLibrary = createAsyncAction(
    'product/ASSIGN_LIBRARY_REQUEST',
    'product/ASSIGN_LIBRARY_SUCCESS',
    'product/ASSIGN_LIBRARY_FAILURE'
)<Product.ContentLibraryRequest, AllFieldSuccess, Common.ItemError>()

export const unassignLibrary = createAsyncAction(
    'product/UNASSIGN_LIBRARY_REQUEST',
    'product/UNASSIGN_LIBRARY_SUCCESS',
    'product/UNASSIGN_LIBRARY_FAILURE'
)<Product.ContentLibraryRequest, AllFieldSuccess, Common.ItemError>()

export const toggleFeatures = createAsyncAction(
    'product/TOGGLE_FEATURES_REQUEST',
    'product/TOGGLE_FEATURES_SUCCESS',
    'product/TOGGLE_FEATURES_FAILURE'
)<Product.FeaturesRequest, AllFieldSuccess, Common.ItemError>()

export const clone = createAsyncAction(
    'product/CLONE_REQUEST',
    'product/CLONE_SUCCESS',
    'product/CLONE_FAILURE'
)<string, ApiSuccess<Product.AllFieldsWithChildren>, Common.ItemError>()

export const exportProductOptions = createAsyncAction(
    'product/EXPORT_PRODUCT_OPTIONS_REQUEST',
    'product/EXPORT_PRODUCT_OPTIONS_SUCCESS',
    'product/EXPORT_PRODUCT_OPTIONS_FAILURE'
)<string, ApiSuccess, Common.ItemError>()

export const exportAllProductOptions = createAsyncAction(
    'product/EXPORT_ALL_PRODUCT_OPTIONS_REQUEST',
    'product/EXPORT_ALL_PRODUCT_OPTIONS_SUCCESS',
    'product/EXPORT_ALL_PRODUCT_OPTIONS_FAILURE'
)<string, ApiSuccess, Common.ItemError>()

export const clearError = createAction('product/CLEAR_ERROR')<void>()
export const clearErrorFor = createAction('product/CLEAR_ERROR_FOR')<string>()
