import { isNull } from 'lodash'
import { strings } from '../../../constants'
import {
    LocalizedProductOptionFilters,
    ProductOptionFilters,
} from '../../../store/filters/filters.reducer'
import { ProductOptionFilterFields } from '../../../store/product-options/product-options.selectors'
import { productOptionBooleans } from './ProductOptionContent'

// Create a tag to field mapping for use with filters
const productOptionTagMap: any = Object.entries(productOptionBooleans).reduce(
    (obj, [field, [tag]]: any) => ({
        ...obj,
        [tag]: field,
    }),
    {}
)

function filterDefault(filters: any, item: any, key: string) {
    return filters[key].includes(item[key])
}

function filterByName(filters: any, item: any, key: string) {
    return (
        item[key].toLowerCase().includes(filters[key].toLowerCase()) ||
        item['id'].includes(filters[key]) // For the devs <3
    )
}

function filterByState(filters: any, item: any, key: any) {
    return (
        filters[key].includes(item['draftState']) ||
        filters[key].includes(item['state'])
    )
}

function filterByTestId(filters: any, item: any, key: string) {
    if (filters[key].includes(strings.noTestingID) && isNull(item[key])) {
        return true
    }
    return filterDefault(filters, item, key)
}

function filterByBooleans(filters: any, item: any, key: any) {
    return Object.keys(productOptionTagMap)
        .filter((tag) => filters[key].includes(tag))
        .map((tag) => productOptionTagMap[tag])
        .every((field: any) => {
            let value = item[field]
            if (field === 'taxable') {
                value = !value
            }
            return value
        })
}

const customFilter = new Map<string, any>()
    .set('name', filterByName)
    .set('state', filterByState)
    .set('testId', filterByTestId)
    .set('tags', filterByBooleans)

type FilterAll = ProductOptionFilters | LocalizedProductOptionFilters

const filterByType = (data: any[], filters: any) =>
    data.filter((item) =>
        Object.keys(filters).every((key) => {
            if (!filters[key]?.length) {
                return true // passing an empty filter means that filter is ignored.
            }

            const filterCustom = customFilter.get(key)

            return filterCustom
                ? filterCustom(filters, item, key)
                : filterDefault(filters, item, key)
        })
    )

export const filterProductOptions = (
    data: ProductOptionFilterFields[],
    filters: FilterAll
) => filterByType(data, filters)
