import React from 'react'
import styles from './Environment.module.scss'

type Props = { label: string }

export class Environment extends React.Component<Props> {
    render() {
        const { label } = this.props

        return label ? (
            <div className={styles.environment}>
                <span>{label}</span>
            </div>
        ) : null
    }
}
