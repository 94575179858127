import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ability, BaseRule } from '../../Authentication/permissions/ability'

interface Props extends RouteProps {
    rule?: BaseRule
}

export const AbilityRoute: React.FC<Props> = ({ rule, ...rest }) => {
    if (rule) {
        return ability.can(rule.action, rule.subject) ? (
            <Route {...rest} />
        ) : (
            <Redirect to="/products" />
        )
    }
    return <Route {...rest} />
}
