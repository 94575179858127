import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { CommonEnum } from '../../store/typings/common.enum'
import styles from './StatusFilter.module.scss'

interface OwnProps {
    options?: { label: string; value: string }[]
}

type Props = OwnProps & SelectProps<any>

export const StatusFilter: React.FC<Props> = (props) => {
    let options = props.options || []

    if (!options.length) {
        options = [
            ...Object.values(CommonEnum.State).map((state: string) => {
                return { label: state, value: state }
            }),
        ]
    }

    return (
        <Select
            className={styles.status}
            mode="multiple"
            options={options}
            placeholder="All status"
            showArrow
            size="large"
            {...props}
        />
    )
}
