import { CopyOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { CSSProperties, ReactNode } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { LinkButton } from '../../components/buttons/Buttons'
import styles from './Copy.module.scss'

type Props = {
    className?: string
    clipboard: string | null
    icon?: ReactNode
    label?: string
    style?: CSSProperties
    title?: string
}

export class Copy extends React.Component<Props> {
    render() {
        const { clipboard, title, label, icon, className } = this.props

        if (!clipboard) return null

        return (
            <CopyToClipboard text={clipboard}>
                <Tooltip
                    overlayClassName={styles.tooltip}
                    placement="left"
                    title="Copied to clipboard"
                    trigger="click"
                >
                    <LinkButton
                        className={classNames(styles.copy, className)}
                        data-component="copy"
                        icon={icon || <CopyOutlined />}
                        title={title || 'Copy?'}
                    >
                        {label}
                    </LinkButton>
                </Tooltip>
            </CopyToClipboard>
        )
    }
}
