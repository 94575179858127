import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton } from '../../../components/buttons/Buttons'
import { HorizontalList } from '../../../components/horizontal-list/HorizontalList'
import { selectReviewImportData } from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'

interface NavButtonProps {
    currentStep: number
    navStep: number
    disabled?: boolean
}

const NavButton: React.FC<NavButtonProps> = ({
    children,
    currentStep,
    navStep,
    disabled,
}) => {
    const dispatch = useDispatch()
    return (
        <LinkButton
            style={{
                fontSize: 14,
                ...(currentStep === navStep ? {} : { color: 'grey' }),
            }}
            onClick={() =>
                dispatch(actions.productOptions.setImportReviewStep(navStep))
            }
            disabled={disabled}
        >
            {children}
        </LinkButton>
    )
}

interface Props {
    currentStep: number
}

export const ReviewNavbar: React.FC<Props> = (props) => {
    const { data } = useSelector(selectReviewImportData)
    const disabled = data.length === 0

    return (
        <HorizontalList style={{ marginTop: 24 }}>
            <NavButton navStep={1} {...props}>
                Step 1 - Upload CSV
            </NavButton>
            <NavButton navStep={2} {...props} disabled={disabled}>
                Step 2 - Review Product Options
            </NavButton>
            <NavButton navStep={3} {...props} disabled={disabled}>
                Step 3 - Submit for processing
            </NavButton>
        </HorizontalList>
    )
}
