import React from 'react'
import { strings } from '../../../constants'
import { FormName } from '../../../store/forms/forms.reducer'
import { actions } from '../../../store/root-action'
import { ProductForm, ProductFormProps } from './ProductForm'

export const ProductEditForm: React.FC<ProductFormProps> = ({
    initialValues,
    ...props
}) => (
    <ProductForm
        initialValues={initialValues}
        name={FormName.ProductEdit}
        submitAction={actions.products.update.request}
        submitText={strings.editSubmitBtn}
        title={strings.editProductFormTitle}
        {...props}
    />
)
