import { Result } from 'antd'
import React from 'react'
import { ReactComponent as ChefDog } from '../assets/images/chef-dog.svg'
import { PrimaryButton } from '../components/buttons/Buttons'
import {
    OKTA_USER_NOT_ASSIGNED_MESSAGE,
    OKTA_USER_REQUEST_LINK,
} from './authentication.constants'
import styles from './AuthError.module.scss'

/**
 * Render when a user is part of org, but is not assigned PC
 */
export const OktaUserNotAssigned: React.FC = () => (
    <Result
        className={styles.errorContainer}
        icon={<ChefDog />}
        title={
            <div className={styles.noAccessTitle}>
                <span>Welcome to the Product Catalog!</span>
            </div>
        }
        subTitle={
            <div className={styles.noAccessSubtitle}>
                <span>{OKTA_USER_NOT_ASSIGNED_MESSAGE}</span>
            </div>
        }
        extra={
            <PrimaryButton
                className={styles.noAccessBtn}
                href={OKTA_USER_REQUEST_LINK}
            >
                Request access
            </PrimaryButton>
        }
    />
)

/**
 * Logical Handler
 */
export const OktaErrorHandler: React.FC<{ error: Error }> = ({ error }) => {
    if (error.message === "Cannot read property '0' of null") {
        // this is a weird error, but needs more exploring... seems to not have anyting wrong
        return <div />
    }

    if (error.message === 'User is not assigned to the client application.') {
        return <OktaUserNotAssigned />
    }

    return <div>Access Denied</div>
}
