import { EyeOutlined, GlobalOutlined } from '@ant-design/icons'
import { Card, Col, Row } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { LinkButton } from '../../../components/buttons/Buttons'
import { History } from '../../../components/history/History'
import { testIds } from '../../../constants'
import apiService from '../../../services/api.service'
import { defaultState } from '../../../store/filters/filters.reducer'
import {
    selectLocalizedProductOptionFilters,
    selectProductOptionFilters,
} from '../../../store/filters/filters.selectors'
import { FormName } from '../../../store/forms/forms.reducer'
import { selectFormOpen } from '../../../store/forms/forms.selectors'
import { selectOptionWithLocalizedCount } from '../../../store/product-options/product-options.selectors'
import { actions } from '../../../store/root-action'
import { CommonEnum } from '../../../store/typings/common.enum'
import { ProductOption as ProductOptionInterface } from '../../../store/typings/product-option.interface'
import { EditProductOptionForm } from '../../forms/EditProductOptionForm'
import { Highlight } from '../../highlight/Highlight'
import { Status } from '../../status/Status'
import { Actions } from '../components/Actions'
import styles from './ProductOption.module.scss'
import { ProductOptionContent } from './ProductOptionContent'

type AllFields = ProductOptionInterface.AllFields
type Draft = ProductOptionInterface.Draft

type Props = {
    optionId: string
    isDrawer?: boolean
}

export const ProductOption: React.FC<Props> = React.memo(
    ({ optionId, isDrawer = false }) => {
        const dispatch = useDispatch()

        const {
            productOption: { payload: option },
            localizedCount,
        } = useSelector((state: RootState) =>
            selectOptionWithLocalizedCount(state, optionId)
        )

        const isEditFormOpen = useSelector((state: RootState) =>
            selectFormOpen(state, FormName.ProductOptionEdit, optionId)
        )

        // TODO: selectAllFilters ?
        const productOptionFilters = useSelector((state: RootState) =>
            selectProductOptionFilters(state)
        )
        const localizedProductOptionFilters = useSelector((state: RootState) =>
            selectLocalizedProductOptionFilters(state)
        )

        const openEditForm = (id: string) =>
            dispatch(
                actions.forms.openWithMeta(FormName.ProductOptionEdit, { id })
            )

        const sync = (synced: AllFields) =>
            dispatch(actions.productOptions.sync.success(synced))

        const updateStatus = (productOption: AllFields) =>
            dispatch(actions.productOptions.updateStatus.success(productOption))

        const setLocalizedFilters = (name: string, id: string) => {
            closeViewForm()
            return dispatch(
                actions.filters.filterLocalizedProductOption({
                    ...defaultState.localizedProductOption,
                    associatedProductOption: [id],
                    name,
                })
            )
        }
        const closeViewForm = useCallback(() => {
            dispatch(actions.forms.close(FormName.ProductOptionView))
        }, [dispatch])

        const deleteDraft = (deleted: Draft) => {
            closeViewForm()
            return dispatch(actions.productOptions.deleteDraft.success(deleted))
        }

        const [isDraftVisible, setIsDraftVisible] = useState(
            option.meta.isDraft
        )
        const visibleData =
            option.draft && isDraftVisible ? option.draft : option

        if (!option) {
            return null
        }

        const actionConfig = {
            clone: {
                onClick: () => {
                    closeViewForm()
                    return dispatch(
                        actions.productOptions.clone.request(optionId)
                    )
                },
            },
            delete: {
                apiRequest: apiService.deleteProductOptionDraft,
                onSuccess: deleteDraft,
            },
            edit: {
                onClick: () => openEditForm(optionId),
            },
            share: {
                url: apiService.getProductOptionUrl(
                    option.productCatalogId,
                    optionId
                ),
            },
            status: {
                apiRequest:
                    option.status === CommonEnum.Status.Active
                        ? apiService.deactivateProductOption
                        : apiService.activateProductOption,
                onSuccess: updateStatus,
            },
            sync: {
                apiRequest: option.meta.isNew
                    ? apiService.syncNewProductOptionToZuora
                    : apiService.syncExistingProductOptionToZuora,
                onSuccess: (synced: AllFields) => {
                    setIsDraftVisible(false)
                    sync(synced)
                },
            },
        }

        if (isEditFormOpen) {
            return (
                <EditProductOptionForm
                    currentProductOption={visibleData}
                    key={optionId}
                    onSubmit={() => {
                        setIsDraftVisible(true)
                    }}
                    optionId={optionId}
                    productId={option.productCatalogId}
                />
            )
        }

        return (
            <Card
                data-testid={optionId}
                id={optionId}
                className={classNames(
                    isDrawer ? styles.card : styles.cardWithBorders,
                    visibleData.meta.state
                )}
                type="inner"
                title={
                    <>
                        <Row align="middle">
                            <Col>
                                <Status
                                    text={visibleData.meta.state}
                                    data-testid={testIds.productOptionStatus}
                                />
                            </Col>
                            <Col>
                                {option.draft ? (
                                    <LinkButton
                                        className={styles.toggle}
                                        icon={<EyeOutlined />}
                                        onClick={() => {
                                            setIsDraftVisible(!isDraftVisible)
                                        }}
                                    >
                                        View{' '}
                                        {isDraftVisible
                                            ? option.status
                                            : 'Draft'}
                                    </LinkButton>
                                ) : null}
                            </Col>
                            <Col className={styles.grow}>
                                {localizedCount ? (
                                    <Link
                                        to={apiService.getRelativeUrl(
                                            apiService.getLocalizedProductOptionUrl(
                                                visibleData.productCatalogId,
                                                visibleData.id
                                            )
                                        )}
                                        onClick={() =>
                                            setLocalizedFilters(
                                                visibleData.name,
                                                visibleData.id
                                            )
                                        }
                                    >
                                        <span>
                                            <GlobalOutlined
                                                className={styles.localizedIcon}
                                            />
                                            <span
                                                className={styles.localizedtext}
                                            >
                                                {`${localizedCount} Localized`}
                                            </span>
                                        </span>
                                    </Link>
                                ) : null}
                            </Col>
                            <Col>
                                <Actions
                                    permissions={{
                                        action: 'manage',
                                        subject: 'ProductOption',
                                    }}
                                    data={option}
                                    visibleData={visibleData}
                                    config={actionConfig}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className={styles.name}>
                                    <Highlight
                                        searchWords={[
                                            visibleData.countryCode
                                                ? localizedProductOptionFilters.name
                                                : productOptionFilters.name,
                                        ]}
                                        textToHighlight={visibleData.name}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <History data={visibleData} />
                    </>
                }
            >
                <ProductOptionContent option={visibleData} />
            </Card>
        )
    }
)
