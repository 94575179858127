import omit from 'object.omit'
import { uniq } from 'ramda'
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import api from '../../services/api.service'
import { selectAllFilters } from '../filters/filters.selectors'
import { FormName } from '../forms/forms.reducer'
import { actions } from '../root-action'
import { ProductOptionEnum } from '../typings/product-option.enum'
import { ProductOption } from '../typings/product-option.interface'
import { selectProductOption } from './product-options.selectors'

export function* getProductOptionsSaga() {
    try {
        const productOptions: ProductOption.AllFields[] = yield call(
            api.getProductOptions
        )
        yield put(actions.productOptions.get.success(productOptions))
    } catch (error) {
        yield put(actions.productOptions.get.failure(error))
    }
}

export function* createProductOptionSaga(
    action: ReturnType<typeof actions.productOptions.create.request>
) {
    try {
        const newProductOption: ProductOption.AllFields = yield call(
            api.createProductOptionWithDraft,
            action.payload
        )

        yield put(actions.productOptions.create.success(newProductOption))
        yield put(actions.forms.close(FormName.ProductOptionCreate))
    } catch (error) {
        yield put(actions.productOptions.create.failure(error))
    }
}

export function* updateProductOptionSaga(
    action: ReturnType<typeof actions.productOptions.update.request>
) {
    try {
        const newProductOption: ProductOption.AllFields = yield call(
            api.updateProductOptionDraft,
            action.payload
        )

        yield put(actions.productOptions.update.success(newProductOption))
        yield put(actions.forms.close(FormName.ProductOptionEdit))
    } catch (error) {
        yield put(
            actions.productOptions.update.failure({
                id: action.payload.id,
                error,
            })
        )
    }
}

export function* cloneProductOptionSaga(
    action: ReturnType<typeof actions.productOptions.clone.request>
) {
    try {
        const { payload: blueprint } = selectProductOption(
            yield select(),
            action.payload
        )

        const cloned: ProductOption.AllFields = yield call(
            api.createProductOptionWithDraft,
            {
                ...omit(blueprint, [
                    'id',
                    'createdTimestamp',
                    'modifiedTimestamp',
                    'draft',
                    'meta',
                    'status',
                    'syncedTimestamp',
                ]),
                name: `${blueprint.name} [CLONE]`,
                invoiceName: `${blueprint.invoiceName} [CLONE]`,
                priceOptions: blueprint.priceOptions.map((p: any) =>
                    omit(p, ['id', 'ratePlanCountryId', 'convertsTo'])
                ),
            } as ProductOption.CreateRequest
        )

        yield put(actions.productOptions.create.success(cloned))

        yield* addNewDraftsToFilters(cloned)

        yield put(
            actions.forms.openWithMeta(FormName.ProductOptionEdit, {
                id: cloned.id,
            })
        )
        yield put(actions.forms.close(FormName.ProductOptionEdit))
    } catch (error) {
        yield put(actions.productOptions.create.failure(error))
    }
}

export function* addNewDraftsToFilters(cloned: ProductOption.AllFields) {
    const currentFilters = selectAllFilters(yield select())

    // TODO: figure out types for this (sagas...)
    const withNewDrafts = (current: any) => ({
        ...current,
        state:
            current.state.length > 0
                ? uniq([...current.state, ProductOptionEnum.State.DraftNew])
                : [],
    })

    if (cloned.countryCode) {
        yield put(
            actions.filters.filterLocalizedProductOption(
                withNewDrafts(currentFilters.localizedProductOption)
            )
        )
    } else {
        yield put(
            actions.filters.filterProductOption(
                withNewDrafts(currentFilters.productOption)
            )
        )
    }
}

export function* getProductOptionFieldsSaga() {
    try {
        const fields: any[] = yield all([
            call<any>(api.getProductOptionFields),
            call<any>(api.getCountryOptions),
        ])

        const [options, countries] = fields

        yield put(
            actions.productOptions.getFields.success({
                ...options,
                countries,
            })
        )
    } catch (error) {
        yield put(actions.productOptions.getFields.failure(error))
    }
}

export function* startImportSaga(action: any) {
    try {
        const response: ProductOption.ImportResponse[] = yield call(
            api.startProductOptionImport,
            action.payload
        )
        yield put(
            actions.productOptions.startImport.success({
                data: response,
                message: 'Product Options are ready for review!',
            })
        )
        yield put(actions.productOptions.setImportReviewStep(2))
    } catch (error) {
        yield put(actions.productOptions.startImport.failure(error))
    }
}

export function* submitImportSaga(action: any) {
    try {
        const response: void = yield call(
            api.submitProductOptionImport,
            action.payload
        )
        yield delay(2000)
        yield put(
            actions.productOptions.submitImport.success({
                data: response,
                message: 'Product options were successfully submitted!',
            })
        )
    } catch (error) {
        yield put(actions.productOptions.submitImport.failure(error))
    }
}

export function* setViewSaga(action: any) {
    yield call(api.setProductOptionView, action.payload)
}

export function* productOptionsWatcher() {
    yield takeEvery(
        getType(actions.productOptions.get.request),
        getProductOptionsSaga
    )

    yield takeEvery(
        getType(actions.productOptions.create.request),
        createProductOptionSaga
    )

    yield takeEvery(
        getType(actions.productOptions.update.request),
        updateProductOptionSaga
    )

    yield takeEvery(
        getType(actions.productOptions.clone.request),
        cloneProductOptionSaga
    )

    yield takeEvery(
        getType(actions.productOptions.getFields.request),
        getProductOptionFieldsSaga
    )

    yield takeEvery(
        getType(actions.productOptions.startImport.request),
        startImportSaga
    )

    yield takeEvery(
        getType(actions.productOptions.submitImport.request),
        submitImportSaga
    )

    yield takeEvery(getType(actions.productOptions.setView), setViewSaga)
}
