import { Space, Table } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'
import { fromPairs, isUndefined, map } from 'lodash'
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { LinkGuess } from '../../../../services/api.interface'
import { CommonEnum } from '../../../../store/typings/common.enum'
import { getCxnGuesses } from '../../connected-products.service'
import { UpgradeIcon, UpsellIcon } from '../../CxtComponents'
import {
    PConnection,
    ProductOptionConnection,
    SimpleInfo,
} from '../cxn-po.interface'
import { poCxnWidths, UP_LABEL_BREAKS } from '../tables/cxn-table.constants'
import {
    ConfirmClick,
    ConfirmConnectionButton,
    ExcludeClick,
    ExcludeConnectionButton,
} from '../tables/SharedTableComponents'
import { BestGuessSelect } from './SelectBestGuess'
import { UnassignedTableData } from './unassigned-table.interface'
// TODO: Rename to incomplete

const { UPGRADE_LABEL, SELECT_NAME } = poCxnWidths

type PoCxn = ProductOptionConnection

export type SelectMap = { [id: string]: string | undefined }
export type SetSelectMap = Dispatch<SetStateAction<SelectMap>>

// -------------------------------- COLUMNS -------------------------------- //

/**
 * Column - Name of left side product option
 */
const makeNameColumn = (count: number): ColumnType<UnassignedTableData> => ({
    key: 0,
    ellipsis: true,
    dataIndex: ['fromPo', 'name'],
    title: `INCOMPLETE (${count})`,
})

/**
 * Column - This column is the label that states if it is an upgrade or upsell
 * This column will be same all the way down
 */
const upgradeUpsellColumn: ColumnType<UnassignedTableData> = {
    key: 1,
    align: 'center',
    width: UPGRADE_LABEL,
    responsive: UP_LABEL_BREAKS,
    render: (data: UnassignedTableData) => {
        const isUpgrade = data.linkType === 'upgrade'
        const icon = isUpgrade ? <UpgradeIcon /> : <UpsellIcon />
        const labelText = isUpgrade ? 'Upgrade' : 'Upsell'

        return (
            <>
                {icon}
                {` ${labelText} to`}
            </>
        )
    },
}

/**
 * Column - This is the select with all of the possible mappings
 * @param items - Mapping of all items in the "Ant Select"
 * @param bestGuessTable - Mapping of all best guesses
 */
const selectColumn = (
    setSelectMap: SetSelectMap,
    selectMap: SelectMap
): ColumnType<UnassignedTableData> => ({
    key: 3,
    width: SELECT_NAME,
    render: (data: UnassignedTableData) => (
        <BestGuessSelect
            fromPo={data.fromPo}
            toPos={data.remainingToPos}
            bestGuess={data.bestGuess}
            setSelectMap={setSelectMap}
            selectMap={selectMap}
        />
    ),
})

/**
 * Column - These are the buttons
 */
const makeButtonCol = (
    setExclude: ExcludeClick,
    setComplete: ConfirmClick,
    selectMap: SelectMap
): ColumnType<UnassignedTableData> => ({
    key: 4,
    render: (data: UnassignedTableData) => (
        <Space>
            <ConfirmConnectionButton
                disabled={checkSelect(selectMap, data.fromPo.id)}
                fromId={data.fromPo.id}
                setComplete={setComplete}
                toId={selectMap[data.fromPo.id]}
            />
            |
            <ExcludeConnectionButton
                poId={data.fromPo.id}
                updater={setExclude}
                type={'incomplete'}
            />
        </Space>
    ),
})

// -------------------------------- TABLE Component -------------------------------- //

// TODO: Combine these across tables?
const defaultTableProps: TableProps<UnassignedTableData> = {
    showHeader: true,
    bordered: false,
    pagination: false,
    size: 'small',
    rowKey: (record) => record.fromPo.id,
}

type Props = {
    poCxns: PoCxn[]
    linkType: CommonEnum.Link
    remainingToPos: SimpleInfo[]
    currentProduct: PConnection
    clickExclude: ExcludeClick
    clickConfirm: ConfirmClick
}

export const UnassignedConnectionsTable = (props: Props) => {
    const { currentProduct, poCxns, linkType, remainingToPos } = props

    const [bestGuess, setBestGuess] = useState<LinkGuess[]>([])
    const [selectionMap, setselectionMap] = useState<SelectMap>(
        defaultSelectMap(props.poCxns)
    )

    const bestGuessMap = useMemo(() => bestGuessMapper(bestGuess), [bestGuess])

    useEffect(() => {
        getCxnGuesses(currentProduct).then(setBestGuess)
    }, [currentProduct])

    if (!poCxns.length) {
        return null
    }

    return (
        <Table
            {...defaultTableProps}
            columns={[
                makeNameColumn(poCxns.length),
                upgradeUpsellColumn,
                selectColumn(setselectionMap, selectionMap),
                makeButtonCol(
                    props.clickExclude,
                    props.clickConfirm,
                    selectionMap
                ),
            ]}
            dataSource={poCxns.map((poCxn) => ({
                linkType,
                remainingToPos,
                fromPo: poCxn.fromProductOption,
                bestGuess: bestGuessMap[poCxn.fromProductOption.id],
            }))}
        />
    )
}

function defaultSelectMap(cxns: PoCxn[]) {
    const toFrom: [string, string | undefined][] = map(cxns, (cxn) => [
        cxn.fromProductOption.id,
        cxn.toProductOption?.id,
    ])

    return fromPairs(toFrom)
}

function bestGuessMapper(guesses: LinkGuess[]) {
    const toFrom: [string, string][] = map(guesses, (g) => [
        g.fromProductOption,
        g.toProductOption,
    ])

    return fromPairs(toFrom)
}

function checkSelect(select: SelectMap, id: string) {
    return !select ? true : isUndefined(select[id])
}
