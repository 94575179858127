import { PureAbility } from '@casl/ability'
import { OktaGroups, UserInfo } from '../authentication.interface'

type Actions = 'manage' | 'read' | 'view'
type Subjects =
    | 'all'
    | 'ContentLibrary'
    | 'Currency'
    | 'Feature'
    | 'Product'
    | 'ProductOption'
    | 'PriceOption'
    | 'Tool'
    | 'ConnectedProducts'

export type BaseRule = { action: Actions; subject: Subjects }

const createRule = (action: Actions, subject: Subjects): BaseRule => ({
    action,
    subject,
})

export const rules = {
    manageAll: createRule('manage', 'all'),
    manageContentLibrary: createRule('manage', 'ContentLibrary'),
    manageConnectedProducts: createRule('manage', 'ConnectedProducts'),
    manageCurrency: createRule('manage', 'Currency'),
    manageFeature: createRule('manage', 'Feature'),
    manageProduct: createRule('manage', 'Product'),
    manageProductOption: createRule('manage', 'ProductOption'),
    readAll: createRule('read', 'all'),
    viewTool: createRule('view', 'Tool'),
}

const rulesByGroup: { [key: string]: BaseRule[] } = {
    [OktaGroups.Admin]: [rules.manageAll, rules.manageConnectedProducts],
    [OktaGroups.Devs]: [rules.readAll, rules.viewTool],
    [OktaGroups.Everyone]: [rules.readAll],
}

const betaRules: BaseRule[] = []

const appendBetaRules = (rules: BaseRule[]) =>
    rules.concat(betaRules.map((b) => ({ ...b, inverted: true })))

/**
 * Permission object for creating abilities
 */
export const ability = new PureAbility([])

/**
 * Called once after login to set what a user can do
 * @param user
 */
export const configureAbility = (user: UserInfo) => {
    let userRules = user.groups.reduce<BaseRule[]>(
        (rules, group) => rules.concat(rulesByGroup[group] || []),
        []
    )

    const shouldApplyBetaRules =
        betaRules.length > 0 && !user.groups.includes(OktaGroups.Beta)

    if (shouldApplyBetaRules) {
        userRules = appendBetaRules(userRules)
    }

    ability.update(userRules)
}
