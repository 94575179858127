import {
    DownOutlined,
    GlobalOutlined,
    LogoutOutlined,
    ReconciliationOutlined,
    SettingOutlined,
} from '@ant-design/icons'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { Can } from '../../Authentication/permissions/Can'
import config from '../../config'
import { selectNewAndExistingProducts } from '../../store/products/products.selectors'
import styles from './Navbar.module.scss'

type MenuInfo = {
    key: React.Key
    keyPath: React.Key[]
    item: React.ReactInstance
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
}

const NavDivider: React.FC = () => <div className={styles.divider}></div>

const DevMenu: React.FC = () => {
    const { oktaAuth } = useOktaAuth()

    const handleMenuClick = async (e: MenuInfo) => {
        if (e.key === 'logout') {
            return await oktaAuth.signOut()
            // TODO: Add backend route for delete token
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    )

    if (!config.isDevelopment) {
        return null
    }

    return (
        <div className={styles.dev}>
            <Dropdown trigger={['click']} overlay={menu}>
                <Button>
                    Dev Tools <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    )
}

export const Navbar: React.FC = () => {
    const allProducts = useSelector((state: RootState) => {
        return selectNewAndExistingProducts(state)
    })

    return (
        <div className={styles.containerWrap}>
            <div className={styles.container}>
                <div className={styles.containerNested}>
                    <NavLink
                        to="/products"
                        className={styles.link}
                        activeClassName={styles.active}
                    >
                        Products
                        {allProducts.length > 0
                            ? ` (${allProducts.length})`
                            : null}
                    </NavLink>
                    <NavDivider />
                    <NavLink
                        to="/change-log"
                        className={styles.link}
                        activeClassName={styles.active}
                    >
                        <ReconciliationOutlined /> Change log
                    </NavLink>
                    <NavDivider />
                    <Can I="view" a="Tool">
                        <NavLink
                            to="/tools"
                            className={styles.link}
                            activeClassName={styles.active}
                        >
                            <SettingOutlined /> Admin tools
                        </NavLink>
                        <NavDivider />
                    </Can>
                    <NavLink
                        to="/localization"
                        className={styles.link}
                        activeClassName={styles.active}
                    >
                        <GlobalOutlined /> Localization
                    </NavLink>
                </div>
                {config.isDevelopment ? <DevMenu /> : null}
            </div>
        </div>
    )
}
