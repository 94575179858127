import { Result } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ImgNotFound from '../../assets/images/not_found.png'
import styles from './NotFound.module.scss'

export const NotFound: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Not Found (Error 404) - Product Catalog</title>
            </Helmet>

            <Result
                title="The page you are looking for could not be found."
                extra={
                    <>
                        <Link className={styles.link} to={'/products'}>
                            Return to Products page
                        </Link>
                    </>
                }
                icon={
                    <img
                        src={ImgNotFound}
                        alt="Not Found"
                        className={styles.image}
                    />
                }
                className={styles.notFound}
            />
        </>
    )
}
