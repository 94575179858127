import { combineReducers } from 'redux'
import {
    adhocReducer,
    defaultState as adhocDefaultState,
} from './adhoc/adhoc.reducer'
import {
    alertsReducer,
    defaultState as alertDefaultState,
} from './alerts/alerts.reducer'
import {
    connectedProductsReducer,
    defaultState as connectedProductsDefaultState,
} from './connected-products/cxnProducts.reducer'
import {
    contentLibrariesReducer,
    defaultState as contentLibrariesDefaultState,
} from './content-libraries/content-libraries.reducer'
import {
    currencyReducer,
    defaultState as currencyDefaultState,
} from './currency/currency.reducer'
import {
    defaultState as featuresDefaultState,
    featuresReducer,
} from './features/features.reducer'
import {
    defaultState as filtersDefaultState,
    filtersReducer,
} from './filters/filters.reducer'
import {
    defaultState as formsDefaultState,
    formsReducer,
} from './forms/forms.reducer'
import {
    defaultState as productOptionsDefaultState,
    productOptionsReducer,
} from './product-options/product-options.reducer'
import {
    defaultState as productsDefaultState,
    productsReducer,
} from './products/products.reducer'

export const defaultState = {
    adhoc: adhocDefaultState,
    alerts: alertDefaultState,
    contentLibraries: contentLibrariesDefaultState,
    currency: currencyDefaultState,
    features: featuresDefaultState,
    filters: filtersDefaultState,
    forms: formsDefaultState,
    productOptions: productOptionsDefaultState,
    products: productsDefaultState,
    connectedProducts: connectedProductsDefaultState,
}

const rootReducer = combineReducers({
    adhoc: adhocReducer,
    alerts: alertsReducer,
    contentLibraries: contentLibrariesReducer,
    currency: currencyReducer,
    features: featuresReducer,
    filters: filtersReducer,
    forms: formsReducer,
    productOptions: productOptionsReducer,
    products: productsReducer,
    connectedProducts: connectedProductsReducer,
})

export default rootReducer
