import { ExportOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '../../components/buttons/Buttons'
import apiService from '../../services/api.service'
import { actions } from '../../store/root-action'
import { changelogDto } from './change-log-service'
import { AntRange, Presets } from './ChangeLog'

interface AuditButtonProps {
    btnTitle: string
    auditRange: AntRange
    preset: Presets
}

const actionSuccess = (filename: string) =>
    actions.alerts.addSuccess({
        message: `${filename} downloaded successfully!`,
    })

export const DownloadButton: React.FC<AuditButtonProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const { auditRange, preset } = props
    const [startDate, endDate] = auditRange

    const noRecordsFound = 'No records found for the given date range.'

    const downloadFileName = (
        preset: Presets,
        startDate: any,
        endDate: any
    ) => {
        const filenames = {
            audit: 'change-log',
        }
        const fileNameBase = filenames['audit']
        const fileStart = dayjs(startDate).format('YYYY-MM-DD')
        const fileEnd = dayjs(endDate).format('YYYY-MM-DD')
        return `${fileNameBase} ${fileStart} to ${fileEnd}.csv`
    }

    return (
        <PrimaryButton
            loading={isLoading}
            disabled={!(startDate || endDate)}
            icon={<ExportOutlined />}
            onClick={async () => {
                setIsLoading(true)
                try {
                    const dto = changelogDto(auditRange, preset)
                    const data = await apiService.downloadChangeLog(dto)
                    const fileName = downloadFileName(
                        preset,
                        startDate,
                        endDate
                    )

                    if (data.size === 0) {
                        dispatch(
                            actions.alerts.addInfo({
                                details: [
                                    {
                                        message: noRecordsFound,
                                    },
                                ],
                                message: `Uh oh! No records found.`,
                            })
                        )
                    } else {
                        fileDownload(data, fileName)
                        dispatch(actionSuccess(fileName))
                    }
                } catch (err) {
                    dispatch(
                        actions.alerts.addFail(JSON.parse(await err.text()))
                    )
                } finally {
                    setIsLoading(false)
                }
            }}
        >
            {props.btnTitle}
        </PrimaryButton>
    )
}
