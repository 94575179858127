import { Result } from 'antd'
import { ResultProps } from 'antd/lib/result'
import React from 'react'
import { Helmet } from 'react-helmet'
import { RouteProps } from 'react-router-dom'
import styles from './GeneralError.module.scss'

type Props = RouteProps &
    ResultProps & {
        headerText?: any
    }

export const GeneralError: React.FC<Props> = ({ headerText, extra, title }) => {
    return (
        <>
            <Helmet>
                <title>{headerText || 'Error - Product Catalog'}</title>
            </Helmet>

            <Result
                title={title || 'Something went wrong. Please try again later'}
                status="warning"
                extra={extra || null}
                className={styles.error}
            />
        </>
    )
}
