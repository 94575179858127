import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'
import { ProductEnum } from '../../store/typings/product.enum'
import styles from './TypeFilter.module.scss'

export const TypeFilter: React.FC<SelectProps<any>> = (props) => {
    const options = [
        ...Object.values(ProductEnum.Type).map((type: string) => {
            return { label: type, value: type }
        }),
    ]

    return (
        <Select
            allowClear
            className={styles.type}
            mode="multiple"
            options={options}
            placeholder="All product types"
            showArrow
            size="large"
            {...props}
        />
    )
}
