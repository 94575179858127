import { toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security } from '@okta/okta-react'
import React from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { ProvideAuth } from './authentication.context'
import { CheckOkta } from './CheckOkta'
import { CheckToken } from './CheckToken'
import { OktaErrorHandler } from './ErrorOkta'
import { oktaAuth } from './okta-config'

export const AuthenticationCheck: React.FC = ({ children }) => {
    const history = useHistory()
    const location = useLocation()

    if (location && location.pathname === '/') {
        return <Redirect to="/products" />
    }

    const restoreOriginal = async (_oktaAuth: any, originalUri: string) => {
        const rel = toRelativeUrl(originalUri, window.location.origin)
        history.replace(rel.replace('/product-catalog', ''))
    }

    const makeLoginCallback = (props: any) => (
        <LoginCallback {...props} errorComponent={OktaErrorHandler} />
    )

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginal}>
            <Route exact path="/login/callback" render={makeLoginCallback} />
            <ProvideAuth>
                <CheckOkta>
                    <CheckToken>{children}</CheckToken>
                </CheckOkta>
            </ProvideAuth>
        </Security>
    )
}
